export class CampusSupervisorAssignment {
    CampusSupervisorAssignmentId: number;
    ProfileID: number;
    FirstName: string;
    LastName: string;
    AssignedOn: Date;
    EndDate: Date;
    LastUpdatedOn: Date;
    LastUpdatedBy: string;
    CreatedBy: string;
    CreatedOn: Date;
    IsDeleted: boolean;
    Index: number;
    CampusName: string;
    EmailAddress: string;
    CampusSupervisorId: number;
    CampusSupervisorTypeId: number;
  }