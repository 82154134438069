import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { Observable, EMPTY } from 'rxjs';
import { State } from '../shared/models/state.model';
import { retry, catchError, shareReplay } from 'rxjs/operators';
import { CampusAddress } from '../shared/models/campus.model';
import { ActiveGrade } from '../shared/models/grade.model';
import { IContactService, Contact, ContactType } from '../shared/models/contact.model';
import { CampusDTO } from '../shared/models/campusDTO.model';

@Injectable({
  providedIn: 'root'
})
export class CampusService implements IContactService {

    headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    constructor(private httpClient: HttpClient,
        private configService: ConfigService) { }

    public getStatesFromActiveCampus(): Observable<State[]> {
        return this.httpClient.get<State[]>(`${this.configService.apiUrl}/v1.0/Campus/Active/States`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public getCampusActiveByState(stateId: number): Observable<CampusAddress[]> {
        return this.httpClient.get<CampusAddress[]>(`${this.configService.apiUrl}/v1.0/Campus/Active/State/${stateId}`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public createCampus(campus: CampusAddress) {
        return this.httpClient.post(`${this.configService.apiUrl}/v1.0/Campus/`, campus).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public updateCampus(campus: CampusAddress) {
        return this.httpClient.patch(`${this.configService.apiUrl}/v1.0/Campus/`, campus).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public existCampusNameByDistrict(campusName: string, districtId: number): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.configService.apiUrl}/v1.0/Campus/Active/Name/${campusName}/${districtId}`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public existsStateCampusLicenseId(stateLicenseId: string, stateId: number): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.configService.apiUrl}/v1.0/Campus/Active/License/${stateLicenseId}/${stateId}`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public getCampusGradesActive(): Observable<ActiveGrade[]> {
        return this.httpClient.get<ActiveGrade[]>(`${this.configService.apiUrl}/v1.0/Campus/Grade/Active`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public getCampusesForDistrict(districtId: number): Observable<CampusDTO[]> {
        return this.httpClient.get<CampusDTO[]>(`${this.configService.apiUrl}/v1.0/district/${districtId}/campus/`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public getContacts(campusId: number, contactTypeId?: number): Observable<Contact[]> {

        const contactTypeQueryParam = contactTypeId ? `?contactTypeId=${contactTypeId}` : '';

        return this.httpClient.get<Contact[]>(`${this.configService.apiUrl}/v1.0/Campus/Contacts/${campusId}${contactTypeQueryParam}`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public getContact(campusContactId: number) {
        return this.httpClient.get<Contact>(`${this.configService.apiUrl}/v1.0/Campus/contact/id/${campusContactId}`).pipe(
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public updateContact(contact: Contact): Observable<Contact> {
        return this.httpClient.put<Contact>(`${this.configService.apiUrl}/v1.0/Campus/contact`, contact).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }
    public delupdContact(contact: Contact): Observable<Contact> {
        return this.httpClient.put<Contact>(`${this.configService.apiUrl}/v1.0/Campus/contact/delupd`, contact).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }
    public deleteContact(contactID: number): Observable<boolean> {
        return this.httpClient.delete<boolean>(`${this.configService.apiUrl}/v1.0/Campus/contact/${contactID}`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }
    public addContact(contact: Contact): Observable<Contact[]> {
        return this.httpClient.post<Contact[]>(`${this.configService.apiUrl}/v1.0/Campus/contact`, contact).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }
    public deladdContact(contact: Contact): Observable<Contact[]> {
        return this.httpClient.post<Contact[]>(`${this.configService.apiUrl}/v1.0/Campus/contact/deladd`, contact).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }
    public getContactTypes(): Observable<ContactType[]> {
        return this.httpClient.get<ContactType[]>(`${this.configService.apiUrl}/v1.0/Campus/contact/type`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }
}
