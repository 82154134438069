export class CertArea {
        id: number;
        areaName: string;
        certName: string;
        gradeIdbegin: number;
        gradeIdend?: any;
        stateId: number;
        highlyQualified: boolean;
        effectiveBegin: Date;
        effectiveEnd: Date;
        sortOrder?: any;
        createUser?: any;
        createDate: Date;
        modUser?: any;
        modDate: Date;
        salesforceId: string;
        programId?: any;
}

export class CertAreaProvider {
    Id: number;
    Description: string;
    EffectiveBegin?: any;
    EffectiveEnd?: any;
    SortOrder?: any;
    SalesforceId?: any;
    ProviderId?: any;
}

export class CertAreaDTO{
    certAreaId: number;
    name: string;
}


