import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalRedirectComponent } from '@azure/msal-angular';
import { IPublicClientApplication } from '@azure/msal-browser';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AgGridModule } from 'ag-grid-angular';
import { DataTablesModule } from 'angular-datatables';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InternSearchComponent } from './intern/intern-search/intern-search.component';
import { MaterialModule } from './material/material.module';
import { AuthInterceptorService } from './services/auth.interceptor.service';
import { AuthService } from './services/auth.service';
import { ConfigService } from './services/config.service';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { SharedModule } from './shared/shared.module';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

export function loadConfiguration(configService: ConfigService) {
  return () => configService.loadConfigs();
}

export function loadMsalConfiguration(configService: ConfigService): IPublicClientApplication {
  return configService.getMsalConfiguration();
}

export function loadMsalGuardConfiguration(configService: ConfigService): MsalGuardConfiguration {
  return configService.getMsalGuardConfiguration();
}

export function loadMsalInterceptorConfiguration(configService: ConfigService): MsalInterceptorConfiguration {
  return configService.getMsalInterceptorConfiguration();
}

@NgModule({
    declarations: [
        AppComponent,
        FooterComponent,
        HeaderComponent,
        InternSearchComponent,
        MsalRedirectComponent
    ],
    imports: [
        AgGridModule,
        AngularEditorModule,
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        DataTablesModule,
        FlexLayoutModule,
        FormsModule,
        HttpClientModule,
        MaterialModule,
        NgxExtendedPdfViewerModule,
        ReactiveFormsModule,
        SharedModule
    ],
    providers: [
        { provide: APP_INITIALIZER, useFactory: loadConfiguration, deps: [ConfigService], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        { provide: MSAL_INSTANCE, useFactory: loadMsalConfiguration, deps: [ConfigService] },
        { provide: MSAL_GUARD_CONFIG, useFactory: loadMsalGuardConfiguration, deps: [ConfigService] },
        { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: loadMsalInterceptorConfiguration, deps: [ConfigService] },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, deps: [AuthService], multi: true },
        DatePipe, { provide: LocationStrategy, useClass: HashLocationStrategy },
        ConfigService,
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        provideEnvironmentNgxMask(maskConfig),
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
