import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ReasonsService } from '../../../services/reasons.service';
import { ReasonTypeEnum } from '../../enums/reason-type.enum';
import { Reason } from '../../models/reason.model';
import { MatRadioChange } from '@angular/material/radio';

@Component({
    selector: 'app-reasons-dialog',
    templateUrl: './reasons-dialog.component.html',
    styleUrls: ['./reasons-dialog.component.scss']
})
export class ReasonsDialogComponent {

    ReasonTypeEnum = ReasonTypeEnum;
    selectedReason: Reason = undefined;
    invalidReason: boolean = false;

    reasons: Reason[];
    @Output() reasonChanged: EventEmitter<Reason> = new EventEmitter();
    constructor(private dialogRef: MatDialogRef<ReasonsDialogComponent>, private reasonsService: ReasonsService) { }
    ngOnInit(): void {
        let dialogType = this.dialogRef._containerInstance._config.data.reasonsType;
        let reasonType: ReasonTypeEnum;

        if (dialogType === "Drop") {
            reasonType = ReasonTypeEnum.Dropped;
        }
        else if (dialogType === "Discharge") {
            reasonType = ReasonTypeEnum.Discharged;
        }
        else if (dialogType === "Disqualify") {
            reasonType = ReasonTypeEnum.Disqualified;
        }
        else {
            // throw error
            return;
        } 

        this.reasonsService.getCandidateStatusReasons(reasonType)
            .pipe()
            .subscribe({
                next: (reasons: Reason[]) => {

                    this.reasons = reasons;
                },
                error: (errorObject) => console.log(errorObject),
            });  
    }

    onRadioChange(eventObject: MatRadioChange) {
        if (!!eventObject.value) {
            this.selectedReason = eventObject.value;
            this.invalidReason = false;
        }

    }

    onSaveClicked() {
        if (!this.invalidReasonSelected()) {
            this.dialogRef.close(this.selectedReason);
        }
    }
    
    invalidReasonSelected(): boolean {
        this.invalidReason = !(!!this.selectedReason);
        return this.invalidReason;
    }

    onCancelClicked() {
        this.dialogRef.close(null);
    }
}