import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { MentorService } from 'src/app/services/mentor.service';
import { DocumentType } from 'src/app/shared/enums/document-type';
import { MentorDocument } from 'src/app/shared/models/mentor-document';
import * as FileSaver from 'file-saver';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs';

interface PendingUpload {
  file: File;
  uploading: boolean;
}

export interface PendingUploadsStatus {
  documentType: DocumentType;
  uploadsPending: boolean;
}

@Component({
  selector: 'app-mentor-document',
  templateUrl: './mentor-document.component.html',
  styleUrls: ['./mentor-document.component.scss']
})
export class MentorDocumentComponent implements OnInit {
  @Input() set documentType(value: DocumentType){
    this._documentType = value;
    this.documentTypeLabel = this.documentTypeLabels[value];
  }
  @Input() set campusContactId(value: number){
    this._campusContactId = value;
    if (this._campusContactId > 0 && this.documentsPendingUpload.length > 0) {
      this.uploadPendingDocuments();
    }
  }
  @Output() documentsAvailableChange = new EventEmitter<boolean>();
  @Output() pendingUploadsComplete = new EventEmitter<DocumentType>();
  @Output() pendingUploadsUpdated = new EventEmitter<PendingUploadsStatus>();
  @ViewChild('fileUpload', { static: false })
  fileInputControl: ElementRef<HTMLInputElement>;

  documentTypeLabel: string;
  private _documentType: DocumentType;
  private _campusContactId: number;
  documents: MentorDocument[] = [];
  documentsPendingUpload = [] as PendingUpload[];
  documentsAvailableState: boolean;
  uploading = false;
  loadingDocuments = false;
  documentTypeLabels = {
    [DocumentType.TeachingCertificate]: "Teaching Certificate Documents",
    [DocumentType.ProofOfAccomplishment]: "Proof Of Accomplishment Documents",
    [DocumentType.DistrictTraining]: "District Training Documents"
  };

  constructor(
    private authService: AuthService, 
    private mentorService: MentorService,
    private snackBar: MatSnackBar
    ) { }

  ngOnInit(): void {

    let allDocuments$ = of([] as MentorDocument[]);

    if (this._campusContactId) {
      allDocuments$ = this.mentorService.getAllDocuments(this._campusContactId, this._documentType);
    }

    this.loadingDocuments = true;

    allDocuments$.toPromise()
      .then(documents => {
        this.documents = documents;
      })
      .catch(() => {
        this.openSnackBar(`Unable to load ${this.documentTypeLabels[this._documentType] || 'documents'} `, 'Error');
      })
      .finally(() => {
        this.loadingDocuments = false;
        this.checkDocumentsAvailable()
      });
  }

  onFileSelected(event: any): void {

    const file = event.target.files[0];
    if (this._campusContactId > 0){
      this.uploadFile(file);
    }
    else {
      this.documentsPendingUpload.push({ file, uploading: false });
      this.fileInputControl.nativeElement.value = null;
      this.checkDocumentsAvailable();
      if (this.documentsPendingUpload.length === 1) {
        this.pendingUploadsUpdated.next({ documentType: this._documentType, uploadsPending: true });
      }
      this.openSnackBar(`${file.name} will upload after saving the contact.`, 'Okay');
    }
  }


  uploadFilePartial(file: File) {
    const formData = new FormData();
    formData.append("CreateUser", this.authService.user.id);
    formData.append("CampusContactID", <string><unknown>this._campusContactId);
    formData.append("DocumentTypeID", <string><unknown>this._documentType);
    formData.append("file", file, file.name);
    this.uploading = true;
    return this.mentorService.uploadDocument(formData).toPromise().then((mentorDocument) => {
      this.documents.push(mentorDocument);
    });
  }

  async uploadPendingDocuments() {
    if (this.uploading) {
      return;
    }
    this.uploading = true;

    const pendingUpload = this.documentsPendingUpload;
    let failedUploads = [];
    for (const doc of pendingUpload) {
      doc.uploading = true;

      try {
        await this.uploadFilePartial(doc.file);
      }
      catch {
        this.openSnackBar(`Your document could not be uploaded. file:${doc.file.name}`, null);
        failedUploads.push(doc.file.name);
      }

      this.documentsPendingUpload = this.documentsPendingUpload.filter(d => d !== doc);

      
    };

    if (failedUploads.length === 0) {
      this.pendingUploadsComplete.next(this._documentType);
    } else {
      this.openSnackBar(`Some documents could not be uploaded. ${failedUploads.join(',')}`, null);
    }
  }

  uploadFile(file: File) {
    this.uploadFilePartial(file)
      .then(() => {
        this.uploading = false;
        this.checkDocumentsAvailable();
        this.openSnackBar('Your document was uploaded.', null);
      })
      .catch(error => {
        this.uploading = false;
        this.openSnackBar(`Your document could not be uploaded. file:${file.name}`, null);
        console.error(error);
      }).finally(() => {
        this.fileInputControl.nativeElement.value = null;
      });
  }


  onDownloadFileClicked(mentorDocument: MentorDocument){
    this.mentorService.downloadDocument(mentorDocument.id).toPromise()
    .then(fileContent => {
      FileSaver.saveAs(fileContent, mentorDocument.filename);	
    })
    .catch(error =>{
      this.openSnackBar('The file could not be downloaded.', null);
      console.error(error);        
    });;
  }

  downloadPendingUpload(doc: PendingUpload) {
    FileSaver.saveAs(doc.file, doc.file.name);
  }

  checkDocumentsAvailable(): void {
    const currentDocumentAvailableState = this.documents.length > 0 || this.documentsPendingUpload.length > 0;
    if (this.documentsAvailableState !== currentDocumentAvailableState){
      this.documentsAvailableChange.next(currentDocumentAvailableState);
      this.documentsAvailableState = currentDocumentAvailableState;
    }
  }

  private openSnackBar(data: string, status: string) {
    this.snackBar.open(data, status, {
        duration: 5 * 1000,
    });
  }

  onDeleteButtonClicked(doc: MentorDocument & { isDeleting: boolean }) {
    doc.isDeleting = true;

    this.mentorService.deleteDocument(doc.id).toPromise()
      .then(() => {
        this.documents = this.documents.filter(currDoc => currDoc !== doc)
        this.checkDocumentsAvailable();
        this.openSnackBar(`${doc.filename || 'File'} successfully deleted`, 'Okay');
      })
      .catch(() => {
        this.openSnackBar(`Unable to delete ${doc.filename || 'file'} `, 'Error');
      })
      .finally(() => doc.isDeleting = false);
  }

  removePendingUpload(doc: PendingUpload) {
    this.openSnackBar(`${doc.file.name || 'File'} successfully deleted`, 'Okay');
    this.documentsPendingUpload = this.documentsPendingUpload.filter(d => d !== doc);

    if (this.documentsPendingUpload.length === 0) {
      this.checkDocumentsAvailable();
      this.pendingUploadsUpdated.next({ documentType: this._documentType, uploadsPending: false });
    }

  }
}
