import { Component, Input, inject, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { RlDialogComponent } from "../rl-dialog/rl-dialog.component";
import { DialogService } from "../../../services/dialog.service";

@Component({
  selector: "app-rl-option",
  templateUrl: "./rl-option.component.html",
  styleUrls: ["./rl-option.component.scss"],
})
export class RlOptionComponent {
  private dialogService = inject(DialogService);

  @ViewChild(RlDialogComponent, { static: false }) rlDlg;

  @Input() profile: any;

  constructor(private matgDialog: MatDialog) {}

  isFormRL_DownloadDisabled:boolean = false; 

  transferProfile(profile: any) {
    this.dialogService.shareProfile(profile);
  }

  ngOnInit(): void {    

    this.isFormRL_DownloadDisabled = (this.profile?.recurringBillingSetupDate == null ? true: false);
    
    this.transferProfile(this.profile);
  }

  openDialog(): void {
    this.matgDialog.open(RlDialogComponent, {
      width: "350px",
    });
  }
}
