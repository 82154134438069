import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, retry, shareReplay } from 'rxjs/operators';
import { CertAreaDTO } from 'src/app/shared/models/certArea.model';
import { ContactInfo } from 'src/app/shared/models/contact-info.model';
import { ConfigService } from '../services/config.service';
import { Education } from '../shared/models/education.model';
import { Intern } from '../shared/models/intern.model';
import { Reason } from '../shared/models/reason.model';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class InternService {
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  apiUrl = '';

  constructor(private httpClient: HttpClient,
    private configSvc: ConfigService) {
    this.apiUrl = this.configSvc.getApiUrl();
  }

  public setStatus(id: number, statusId: number, modUser: string, disqualificationReasonId?: number, reason: Reason = null): Observable<any> {
    const setStatus = new SetStatus();
    setStatus.modUser = modUser;
    setStatus.statusId = statusId;
    setStatus.disqualificationReasonId = disqualificationReasonId;
    setStatus.reasonId = reason?.id
    return this.httpClient.put(`${this.apiUrl}/setStatus/${id}`, JSON.stringify(setStatus));
  }

  public getList() {
    return this.httpClient.get<Intern[]>(`${this.apiUrl}/intern`).pipe(
      retry(3),
      catchError(error => {
        console.log(error);

        return EMPTY;
      }),
      shareReplay()
    );
  }

  public getGeneralInfo(profileId: any, internID: string = null) {
    let url = `${this.apiUrl}/generalinfo/${profileId}`;
    if (!!internID){
      url = `${url}?internID=${internID}`;
    }
    return this.httpClient.get<Intern>(url).pipe(
      retry(3),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public GetGeneralInfoByCandidateID(id: any) {
    return this.httpClient.get<Intern>(`${this.apiUrl}/GeneralInfo_ByCandidateID/${id}`).pipe(
      retry(3),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public post(intern: Intern) {
    return this.httpClient.post<Intern>(this.apiUrl + "/generalinfo/", intern, { headers: this.headers });
  }

  public put(intern: Intern, id: string) {
    return this.httpClient.put<Intern>(this.apiUrl + "/generalinfo/" + id, intern, { headers: this.headers });
  }

  public search(searchTerm: string) {
    return this.httpClient.get<Intern[]>(`${this.apiUrl}/v1.0/profile?q=${searchTerm}`).pipe(
      retry(3),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public UpdateCandidateStatus(profileId: number, status) {
    return this.httpClient.post<any>(`${this.apiUrl}/v1.0/profile/UpdateStatus/${profileId}/${status}`, profileId, status).pipe(
      retry(0),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public getHighestDegreeConferred(candidateId: number) {
    return this.httpClient.get<Education>(`${this.apiUrl}/candidate/GetHighestDegreeConferred/${candidateId}`).pipe(
      retry(3),
      catchError(error => {
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public getContactInfo(profileId: number) {
    return this.httpClient.get<ContactInfo>(`${this.apiUrl}/intern/contact/${profileId}`);
  }

  public getCertAreas(profileId: number) {
    return this.httpClient.get<CertAreaDTO[]>(`${this.apiUrl}/certification/areas/${profileId}`);
  }

  public primaryEmailAlreadyExists(emailAddress: string) {
    return this.httpClient.get<boolean>(`${this.configSvc.apiUrl}/intern/emails?email=${emailAddress}`).pipe(
      retry(3),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public searchCandidates(searchTerm: string) {
    return this.httpClient.get<any[]>(`${this.apiUrl}/candidate-search?q=${searchTerm}`).pipe(
      retry(3),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public searchCandidatesForSponsorship(searchTerm: string, sponsorshipId: number) {
    return this.httpClient.get<any[]>(`${this.apiUrl}/v1.0/sponsorship-candidates/sponsorship/${sponsorshipId}/?q=${searchTerm}`).pipe(
      retry(3),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }
}

class SetStatus {
  modUser = '';
  statusId = -1;
  disqualificationReasonId?: number;
  reasonId: number;
}

class SetStatuses {
  modUser = '';
  statusId = -1;
  financialStatusId = -1;
  sendUpdateTo2CP = false;
}
