export class CampusSupervisor {
  id: number;
  entityID: number
  firstName: string;
  lastName: string;
  title: string;
  emailAddress: string;
  phoneNumber: string;
  displayName: string;
}
