export class Role {
    AuthorizationRoleID: number;
    AuthorizationRoleName: string;
}

export enum RoleValue {
    Advisor = 1,
    Advisor_Manager = 2,
    Curriculum_Support = 3,
    Field_Supervisor = 4,
    Intern = 5,
    Business_Developement = 6,
    Administrator = 7,
    Impersonator = 8,
    CC_Advisor = 9,
    CC_Manager = 10,
    CS_Advisor = 11,
    CS_Advisor_Manager = 12,
    FS_Impersonator = 13,
    Coach = 14,
    Intern_Impersonator = 15,
    Coach_Impersonator = 16,
    Finance_Admin = 17,
    Transcript_Processor = 18,
    Notification_Creator = 19,
    Notification_Approver = 20,
    Promotion_Manager = 21,
    Sponsorship_Admin = 22
}