import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/auth.service';
import { CampusSupervisorService } from 'src/app/services/campus-supervisor.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { CampusContactType } from 'src/app/shared/enums/campus-contact-type.enum';
import { CampusSupervisorAssignment } from 'src/app/shared/models/campus-supervisor-assignment.model';
import { CampusSupervisor } from 'src/app/shared/models/campus-supervisor.model';
import { Result } from 'src/app/shared/models/result.model';

@Component({
  selector: 'app-campus-supervisor-assignment',
  templateUrl: './campus-supervisor-assignment.component.html',
  styleUrls: ['./campus-supervisor-assignment.component.scss']
})
export class CampusSupervisorAssignmentComponent {
  canAssignCampusSupervisors: boolean = false;
  editMode = false;
  showConfirmDelete = false;
  entityId: number;
  currentCampusSupervisorAssignment: CampusSupervisorAssignment;
  entityToDelete: CampusSupervisorAssignment;
  campusSupervisorAssignmentForm: UntypedFormGroup;
  campusSupervisorID: number = 0;
  campusSupervisorAssignmentList: CampusSupervisorAssignment[] = [];
  campusSupervisorList: CampusSupervisor[] = [];
  isNew: boolean = false;
  showSpinner: boolean;
  campusId: number;

  constructor(
    private dialogRef: MatDialogRef<CampusSupervisorAssignmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private campusSupervisorSvc: CampusSupervisorService,
    private dialog: MatDialog
  ) {
    if (data != null) {
      this.currentCampusSupervisorAssignment = data.entity;
      this.entityId = data.profileId;
      this.campusSupervisorAssignmentList = data.assignments;
      this.isNew = (this.currentCampusSupervisorAssignment == null);
      this.campusId  = data.campusId;
    }
  }

  ngOnInit() {
    this.campusSupervisorAssignmentForm = new UntypedFormGroup({
      assignmentID: new UntypedFormControl(0, null),
      assignmentCampusSupervisorName: new UntypedFormControl('', null),
      assignedCampusSupervisorID: new UntypedFormControl(0, Validators.required),
      assignmentStartDate: new UntypedFormControl('', (this.isNew ? Validators.required : null)),
      assignmentEndDate: new UntypedFormControl('', (this.isNew ? null : Validators.required))
    });
    if (this.isNew) {
      this.getCampusSupervisors();
    }
    else {
      this.prepForChanges();
    }
  }

  getCampusSupervisors() {
    this.showSpinner = true;
    if (!this.campusId) {
      return;
    }
    this.campusSupervisorSvc.getCampusSupervisorsForCampus(this.campusId).subscribe((result: Result) => {
        this.showSpinner = false;
        this.campusSupervisorList = result.returnObj as CampusSupervisor[];
    },
    (errorObject) => {
      this.showSpinner = false;
      if (errorObject.error && errorObject.error.userMsg) {
        this.openSnackBar(errorObject.error.userMsg, "Ok");
      }
      this.openSnackBar('Error: Campus Supervisors could not be loaded.', "Ok");
    }); 
  }

  cancelEdit() {
    this.campusSupervisorAssignmentForm.reset();
    this.dialogRef.close(false);
  }

  async saveEdit() {
    const formData = new CampusSupervisorAssignment();
    formData.CampusSupervisorId = this.campusSupervisorAssignmentForm.get('assignedCampusSupervisorID').value;
    formData.ProfileID = this.entityId;
    formData.LastUpdatedBy = this.authService.user.displayName;
    formData.CampusSupervisorTypeId = CampusContactType.CampusSupervisor;

    if (this.campusSupervisorAssignmentForm.valid) {
      this.showSpinner = true;

      var [currCampusSupervisorAssignments, needsConfirmation] = this.isUniqueCampusSupervisorViolation();

      if (this.isNew) {
        formData.CampusSupervisorAssignmentId = null;
        formData.AssignedOn = this.campusSupervisorAssignmentForm.get('assignmentStartDate').value;
        formData.EndDate = null;
        formData.CreatedBy = this.authService.user.displayName;

        if (needsConfirmation == true) {
          const canContinue = await this.openOverwriteCampusSupervisorConfirmDialog(
            currCampusSupervisorAssignments as CampusSupervisorAssignment[], needsConfirmation as boolean
          );
          if (!canContinue) {
            this.showSpinner = false;
            this.openSnackBar('New campus supervisor assignment was not saved.', null);
            this.editMode = false;
            return;
          }
          this.deladdCampusSupervisorAssignmentOnServer(formData, currCampusSupervisorAssignments as CampusSupervisorAssignment[]);
        } else {
          this.addCampusSupervisorAssignmentOnServer(formData);
        }
      }
      else {
        formData.CampusSupervisorAssignmentId = this.currentCampusSupervisorAssignment.CampusSupervisorAssignmentId;
        formData.AssignedOn = null;
        formData.EndDate = this.campusSupervisorAssignmentForm.get('assignmentEndDate').value;
        this.updCampusSupervisorAssignmentOnServer(formData);
      }
    }
  }

  updCampusSupervisorAssignmentOnServer(formData: CampusSupervisorAssignment) {
    this.campusSupervisorSvc.updateCampusSupervisorAssignment(formData).toPromise()
      .then((result) => {
        this.showSpinner = false;
        const updatedCampusSupervisorAssignment = result;
        const index = this.campusSupervisorAssignmentList.findIndex(c => c.CampusSupervisorAssignmentId === updatedCampusSupervisorAssignment.CampusSupervisorAssignmentId);
        this.campusSupervisorAssignmentList.splice(index, 1, updatedCampusSupervisorAssignment);
        this.openSnackBar('Campus Supervisor Assignment was updated.', null);
        this.dialogRef.close(result);
      })
      .catch(error => {
        this.showSpinner = false;
        this.openSnackBar('Error: Campus Supervisor Assignment was not updated.', null);
      });
  }

  deladdCampusSupervisorAssignmentOnServer(formData: CampusSupervisorAssignment, currCampusSupervisorAssignments: CampusSupervisorAssignment[]) {
    this.showSpinner = true;
    var needToEnd = currCampusSupervisorAssignments.filter(it => it.EndDate == null);
    needToEnd.forEach((ma, ndx, marr) => {
      ma.EndDate = formData.AssignedOn;
    });
    this.campusSupervisorSvc.updateCampusSupervisorAssignmentList(currCampusSupervisorAssignments).toPromise().then((result) => {
      const itemDeleted = result;
      if (itemDeleted) {
        currCampusSupervisorAssignments.forEach((ma, ndx, marr) => {
          const index = this.campusSupervisorAssignmentList.findIndex(c => c.CampusSupervisorAssignmentId === ma.CampusSupervisorAssignmentId);
          this.campusSupervisorAssignmentList.splice(index, 1);
        });
        this.openSnackBar('The existing Campus Supervisor Assignments were ended.', null);
        this.addCampusSupervisorAssignmentOnServer(formData);
      }
      else {
        this.openSnackBar('Error: The existing Campus Supervisor Assignments were not ended.', null);
      }
    })
      .catch(error => {
        this.showSpinner = false;
        this.openSnackBar('Error: The existing Campus Supervisor Assignments were not ended.', null);
      });

    this.showSpinner = false;
  }

  addCampusSupervisorAssignmentOnServer(formData: CampusSupervisorAssignment) {
    this.campusSupervisorSvc.addCampusSupervisorAssignment(formData).toPromise().then((result) => {
      this.showSpinner = false;
      if (result) {
        this.campusSupervisorAssignmentList.push(formData);
        this.openSnackBar('New campus supervisor assignment was saved.', null);
        this.dialogRef.close(result);
      } else {
        this.openSnackBar('New campus supervisor assignment was not saved.', null);
      }
    })
      .catch(error => {
        this.showSpinner = false;
        this.openSnackBar('Error: New campus supervisor assignment was not saved.', null);
      });
  }

  prepForChanges() {
    if (this.isNew) {
      this.campusSupervisorID = 0;
      this.campusSupervisorAssignmentForm.setValue({
        assignmentID: null,
        assignmentCampusSupervisorName: null,
        assignedCampusSupervisorID: null,
        assignmentStartDate: new Date(),
        assignmentEndDate: null
      });
    } else {
      this.campusSupervisorID = this.currentCampusSupervisorAssignment.CampusSupervisorId;
      this.campusSupervisorAssignmentForm.setValue({
        assignmentID: this.currentCampusSupervisorAssignment.CampusSupervisorAssignmentId,
        assignmentCampusSupervisorName: this.currentCampusSupervisorAssignment.FirstName + ' ' + this.currentCampusSupervisorAssignment.LastName,
        assignedCampusSupervisorID: this.currentCampusSupervisorAssignment.CampusSupervisorId,
        assignmentStartDate: this.currentCampusSupervisorAssignment.AssignedOn,
        assignmentEndDate: new Date()
      });
    }
    this.campusSupervisorAssignmentForm.markAsPristine;
  }

  delete(formData: CampusSupervisorAssignment) {
    this.showSpinner = true;

    this.campusSupervisorSvc.updateCampusSupervisorAssignment(formData).toPromise().then((result) => {
      this.showSpinner = false;
      const itemDeleted = result;
      if (itemDeleted) {
        const itemIndex = this.campusSupervisorAssignmentList.findIndex(c => c.CampusSupervisorAssignmentId === formData.CampusSupervisorAssignmentId);
        this.campusSupervisorAssignmentList.splice(itemIndex, 1);
        this.openSnackBar('The specified Campus Supervisor Assignment was ended.', null);
        this.addCampusSupervisorAssignmentOnServer(formData);
      }
      else {
        this.openSnackBar('Error: The specified Campus Supervisor Assignment was not ended.', null);
      }
    })
      .catch(error => {
        this.showSpinner = false;
        this.openSnackBar('Error: The specified Campus Supervisor Assignment was not ended.', null);
      });
  }

  isUniqueCampusSupervisorViolation() {
    var retval: boolean = false;
    var currCampusSupervisorAssignments: CampusSupervisorAssignment[] = [];
    var CAMPUS_SUPERVISOR_TYPE_ID = 3;

    const formCampusSupervisorId = this.campusSupervisorAssignmentForm.get('assignedCampusSupervisorID').value;
    var currCampusSupervisorList = this.campusSupervisorAssignmentList
      .filter(it => it.CampusSupervisorTypeId == CAMPUS_SUPERVISOR_TYPE_ID && it.EndDate == null)
      .sort((a, b) => ((new Date(a.AssignedOn)).getTime() - (new Date(b.AssignedOn)).getTime()) / 1000);

    if (currCampusSupervisorList.length > 0) {
      retval = true;
      currCampusSupervisorAssignments = currCampusSupervisorList;
    }

    return [currCampusSupervisorAssignments, retval];
  }

  async openOverwriteCampusSupervisorConfirmDialog(currCampusSupervisorAssignments: CampusSupervisorAssignment[], needsConfirmation: boolean): Promise<boolean> {
    var retval = true;
    if (needsConfirmation) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '400px',
        maxWidth: '400px',
        data: {
          title: 'Confirmation:',
          message: `Continuing will end existing campus supervisor assignments on the new assignment's start date, OK?`
        }
      });

      retval = await dialogRef.afterClosed().toPromise().then(result => {
        return Promise.resolve(result);
      });
    } else {
      retval = false;
    }

    return Promise.resolve(retval);
  }

  hasError(ctlName: string, errName: string = "any") {
    var retval: boolean = false;

    var ctl = this.campusSupervisorAssignmentForm.controls[ctlName];
    switch (ctlName) {
      case "assignmentStartDate":
        retval = ctl.hasError(errName);
        break;
      case "any":
        retval = ctl.errors == null;
        break;
    }

    return retval;
  }


  openSnackBar(data: string, status: string, durationSeconds = 3) {
    this.snackBar.open(data, status, {
      duration: durationSeconds * 1000,
    });
  }
}
