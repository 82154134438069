import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormBuilder, FormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { Subscription } from 'rxjs';
import { StaticTableService } from '../../../services/statictable.service';
import { EmploymentVerificationReason } from '../../models/employment-verification-reason.model';

export function reasonNoteValidator(reasonIdKey: string, noteKey: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const reasonIds = control.get(reasonIdKey).value;
    const note = control.get(noteKey).value;

    if (reasonIds.length > 0 && note.length == 0) {
      return { 'noteLength': true };
    }
    else if (reasonIds.length == 0 && note.length > 0) {
      return { 'noteNotNeeded': true };
    }
    return null;
  };
}
export interface MissingVerificationFormValues {
  missingVerificationReasons: number[] | null;
  missingVerificationReasonNote: string | null;
}

@Component({
  selector: 'missing-verification-form',
  templateUrl: './missing-verification-form.component.html',
  styleUrls: ['./missing-verification-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MissingVerificationFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MissingVerificationFormComponent),
      multi: true
    },
  ]
})
export class MissingVerificationFormComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  missingVerification: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  apiUrl: string;
  reasonList: EmploymentVerificationReason[];
  reasonListLoading: boolean;

  get value(): MissingVerificationFormValues {
    return this.missingVerification.value as MissingVerificationFormValues;
  }

  set value(value: MissingVerificationFormValues) {
    this.missingVerification.setValue(value);
    this.onChange(value);
    this.onTouched(value);
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private staticTableSvc: StaticTableService) {

    this.missingVerification = this.formBuilder.group({
      missingVerificationReasons: [''],
      missingVerificationReasonNote: ['']
    }, { validators: reasonNoteValidator('missingVerificationReasons', 'missingVerificationReasonNote') });

    this.subscriptions.push(
      this.missingVerification.valueChanges.subscribe(value => {
        this.onChange(value);
      })
    );

  }

  validate(control: AbstractControl): ValidationErrors {
    return this.missingVerification.valid ? null : { missingVerification: { valid: false } };
  }

  registerOnValidatorChange?(fn: () => void): void {
    //throw new Error('Method not implemented.');
  }

  onChange: any = () => { };
  onTouched: any = () => { };


  // Implement ControlValueAccessor
  writeValue(value: any): void {
    if (value) {
      this.value = value;
    }

    if (value == null) {
      this.missingVerification.reset();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    //throw new Error('Method not implemented.');
  }

  get valid() {
    return this.missingVerification.valid;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit(): void {
    this.loadVerificationReasons();
  }

  loadVerificationReasons() {

    this.reasonListLoading = true;

    this.staticTableSvc.getEmploymentVerificationReasons().subscribe(data => {
      this.reasonList = data;
      this.reasonListLoading = false;
    });

  }

  get missingVerificationReasonsControl() {
    return this.missingVerification.controls.missingVerificationReasons;
  }

  get missingVerificationReasonNoteControl() {
    return this.missingVerification.controls.missingVerificationReasonNote;
  }

  get noteIsMissing() {
    return this.missingVerification.hasError('noteLength');
  }

  get noteIsNotNeeded() {
    return this.missingVerification.hasError('noteNotNeeded');
  }
}
