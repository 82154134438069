import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY } from 'rxjs';
import { catchError, retry, shareReplay } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';
import { CandidateNote, CandidateNoteDTO } from '../shared/models/candidateNote';

@Injectable({
    providedIn: 'root'
})
export class InternNoteService {

    headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    apiUrl = '';

    constructor(private httpClient: HttpClient, private configSvc: ConfigService) {
        this.apiUrl = this.configSvc.getApiUrl();
    }

    public GetInternNotes(InternID: number) {
        return this.httpClient.get<CandidateNoteDTO[]>(`${this.apiUrl}/Notes/${InternID}`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);

                return EMPTY;
            }),
            shareReplay()
        );
    }

    public SetInternNote(bodyFormData: FormData) {
        return this.httpClient.put<CandidateNoteDTO>(`${this.apiUrl}/Note/update/`, bodyFormData).pipe(
            retry(0),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public CreateInternNote(note: CandidateNote) {
        return this.httpClient.post<any>(`${this.apiUrl}/Note/`, note).pipe(
            retry(0),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }
}
