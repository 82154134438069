
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/auth.service';
import { MentorService } from 'src/app/services/mentor.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Mentor } from 'src/app/shared/models/mentor.model';
import { MentorAssignment } from 'src/app/shared/models/mentorAssignments.model';

@Component({
    selector: 'app-mentor-assignment',
    templateUrl: './mentor-assignment.component.html',
    styleUrls: ['./mentor-assignment.component.scss']
})
export class MentorAssignmentComponent implements OnInit {
    canAssignMentors: boolean = false;
    editMode = false;
    showConfirmDelete = false;
    entityId: number;
    currentMentorAssignment: MentorAssignment;
    entityToDelete: MentorAssignment;
    mentorAssignmentForm: UntypedFormGroup;
    mentorID: number = 0;
    mentorAssignmentList: MentorAssignment[] = [];
    mentorList: Mentor[] = [];
    isNew: boolean = false;
    showSpinner: boolean;

    constructor(
        private dialogRef: MatDialogRef<MentorAssignmentComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private snackBar: MatSnackBar,
        private authService: AuthService,
        private mentorSvc: MentorService,
        private dialog: MatDialog
    ) {
        if (data != null) {
            this.currentMentorAssignment = data.entity;
            this.entityId = data.profileId;
            this.mentorAssignmentList = data.assignments;
            this.isNew = (this.currentMentorAssignment == null);
        }
    }

    ngOnInit() {
        this.mentorAssignmentForm = new UntypedFormGroup({
            assignmentID: new UntypedFormControl(0, null),
            assignmentMentorName: new UntypedFormControl('', null),
            assignedMentorID: new UntypedFormControl(0, Validators.required),
            assignmentStartDate: new UntypedFormControl('', (this.isNew ? Validators.required : null)),
            assignmentEndDate: new UntypedFormControl('', (this.isNew ? null : Validators.required))
        });
        if (this.isNew) {
            this.getMentors();
        }
        else {
            this.prepForChanges();
        }
    }

    getMentors() {
        this.showSpinner = true;
        this.mentorSvc.getAllAvailable(this.entityId).toPromise()
            .then((result) => {
                this.mentorList = result;
                this.showSpinner = false;
                return (this.mentorList.length > 0);
            })
    }

    cancelEdit() {
        this.mentorAssignmentForm.reset();
        this.dialogRef.close(false);
    }

    async saveEdit() {
        const formData = new MentorAssignment();
        formData.MentorId = this.mentorAssignmentForm.get('assignedMentorID').value;
        formData.ProfileID = this.entityId;
        formData.LastUpdatedBy = this.authService.user.displayName;
        formData.MentorTypeId = 3;

        if (this.mentorAssignmentForm.valid) {
            this.showSpinner = true;

            var [currMentorAssignments, needsConfirmation] = this.isUniqueMentorViolation();

            if (this.isNew) {
                formData.MentorAssignmentId = null;
                formData.AssignedOn = this.mentorAssignmentForm.get('assignmentStartDate').value;
                formData.EndDate = null;
                formData.CreatedBy = this.authService.user.displayName;

                if (needsConfirmation == true) {
                    const canContinue = await this.openOverwriteMentorConfirmDialog(
                        currMentorAssignments as MentorAssignment[], needsConfirmation as boolean
                    );
                    if (!canContinue) {
                        this.showSpinner = false;
                        this.openSnackBar('New mentor assignment was not saved.', null);
                        this.editMode = false;
                        return;
                    }
                    this.deladdMentorAssignmentOnServer(formData, currMentorAssignments as MentorAssignment[]);
                } else {
                    this.addMentorAssignmentOnServer(formData);
                }
            }
            else {
                formData.MentorAssignmentId = this.currentMentorAssignment.MentorAssignmentId;
                formData.AssignedOn = null;
                formData.EndDate = this.mentorAssignmentForm.get('assignmentEndDate').value;
                this.updMentorAssignmentOnServer(formData);
            }
        }
    }

    updMentorAssignmentOnServer(formData: MentorAssignment) {
        this.mentorSvc.changeAssign(formData).toPromise()
            .then((result) => {
                this.showSpinner = false;
                const updatedMentorAssignment = result;
                const index = this.mentorAssignmentList.findIndex(c => c.MentorAssignmentId === updatedMentorAssignment.MentorAssignmentId);
                this.mentorAssignmentList.splice(index, 1, updatedMentorAssignment);
                this.openSnackBar('Mentor Assignment was updated.', null);
                this.dialogRef.close(result);
            })
            .catch(error => {
                this.showSpinner = false;
                this.openSnackBar('Error: Mentor Assignment was not updated.', null);
            });
    }

    deladdMentorAssignmentOnServer(formData: MentorAssignment, currMentorAssignments: MentorAssignment[]) {
        this.showSpinner = true;
        var needToEnd = currMentorAssignments.filter(it => it.EndDate == null);
        needToEnd.forEach((ma, ndx, marr) => {
            ma.EndDate = formData.AssignedOn;
        });
        this.mentorSvc.changeAssignList(currMentorAssignments).toPromise().then((result) => {
            const itemDeleted = result;
            if (itemDeleted) {
                currMentorAssignments.forEach((ma, ndx, marr) => {
                    const index = this.mentorAssignmentList.findIndex(c => c.MentorAssignmentId === ma.MentorAssignmentId);
                    this.mentorAssignmentList.splice(index, 1);
                });
                this.openSnackBar('The existing Mentor Assignments were ended.', null);
                this.addMentorAssignmentOnServer(formData);
            }
            else {
                this.openSnackBar('Error: The existing Mentor Assignments were not ended.', null);
            }
        })
            .catch(error => {
                this.showSpinner = false;
                this.openSnackBar('Error: The existing Mentor Assignments were not ended.', null);
            });

        this.showSpinner = false;
    }

    addMentorAssignmentOnServer(formData: MentorAssignment) {
        this.mentorSvc.assign(formData).toPromise().then((result) => {
            this.showSpinner = false;
            if (result) {
                this.mentorAssignmentList.push(formData);
                this.openSnackBar('New mentor assignment was saved.', null);
                this.dialogRef.close(result);
            } else {
                this.openSnackBar('New mentor assignment was not saved.', null);
            }
        })
            .catch(error => {
                this.showSpinner = false;
                this.openSnackBar('Error: New mentor assignment was not saved.', null);
            });
        }

    prepForChanges() {
        if (this.isNew) {
            this.mentorID = 0;
            this.mentorAssignmentForm.setValue({
                assignmentID: null,
                assignmentMentorName: null,
                assignedMentorID: null,
                assignmentStartDate: new Date(),
                assignmentEndDate: null
            });
        } else {
            this.mentorID = this.currentMentorAssignment.MentorId;
            this.mentorAssignmentForm.setValue({
                assignmentID: this.currentMentorAssignment.MentorAssignmentId,
                assignmentMentorName: this.currentMentorAssignment.FirstName + ' ' + this.currentMentorAssignment.LastName,
                assignedMentorID: this.currentMentorAssignment.MentorId,
                assignmentStartDate: this.currentMentorAssignment.AssignedOn,
                assignmentEndDate: new Date()
            });
        }
        this.mentorAssignmentForm.markAsPristine;
    }

    delete(formData: MentorAssignment) {
        this.showSpinner = true;

        this.mentorSvc.changeAssign(formData).toPromise().then((result) => {
            this.showSpinner = false;
            const itemDeleted = result;
            if (itemDeleted) {
                const itemIndex = this.mentorAssignmentList.findIndex(c => c.MentorAssignmentId === formData.MentorAssignmentId);
                this.mentorAssignmentList.splice(itemIndex, 1);
                this.openSnackBar('The specified Mentor Assignment was ended.', null);
                this.addMentorAssignmentOnServer(formData);
            }
            else {
                this.openSnackBar('Error: The specified Mentor Assignment was not ended.', null);
            }
        })
            .catch(error => {
                this.showSpinner = false;
                this.openSnackBar('Error: The specified Mentor Assignment was not ended.', null);
            });
    }

    isUniqueMentorViolation() {
        var retval: boolean = false;
        var currMentorAssignments: MentorAssignment[] = [];
        var MENTOR_TYPE_ID = 3;

        const formMentorId = this.mentorAssignmentForm.get('assignedMentorID').value;
        var currMentorList = this.mentorAssignmentList
            .filter(it => it.MentorTypeId == MENTOR_TYPE_ID && it.EndDate == null)
            .sort((a, b) => ((new Date(a.AssignedOn)).getTime() - (new Date(b.AssignedOn)).getTime()) / 1000);

        if (currMentorList.length > 0) {
            retval = true;
            currMentorAssignments = currMentorList;
        }

        return [currMentorAssignments, retval];
    }

    async openOverwriteMentorConfirmDialog(currMentorAssignments: MentorAssignment[], needsConfirmation: boolean): Promise<boolean> {
        var retval = true;
        if (needsConfirmation) {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                width: '400px',
                maxWidth: '400px',
                data: {
                    title: 'Confirmation:',
                    message: `Continuing will end existing mentor assignments on the new assignment's start date, OK?`
                }
            });

            retval = await dialogRef.afterClosed().toPromise().then(result => {
                return Promise.resolve(result);
            });
        } else {
            retval = false;
        }

        return Promise.resolve(retval);
    }

    hasError(ctlName: string, errName: string = "any") {
        var retval: boolean = false;

        var ctl = this.mentorAssignmentForm.controls[ctlName];
        switch (ctlName) {
            case "assignmentStartDate":
                retval = ctl.hasError(errName);
                break;
            case "any":
                retval = ctl.errors == null;
                break;
        }

        return retval;
  }


    openSnackBar(data: string, status: string, durationSeconds = 3) {
        this.snackBar.open(data, status, {
            duration: durationSeconds * 1000,
        });
    }
}
