export class Mentor {
    MentorId: number;
    TypeId: number;
    FirstName: string;
    LastName: string;
    Title: string;
    State: string;
    PrimaryEmail: string;
    MainPhone: string;
    DistrictMentorFee: number;
    DisplayName: string;
    EffectiveBegin: Date;
    EffectiveEnd: Date;
    TEAId: string;
    CertArea: string;
}