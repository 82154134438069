import { AuthService } from 'src/app/services/auth.service';
import { ContractService } from 'src/app/services/contract.service';
import { CertAreaAddCommand } from '../../models/certAreaAddCommand';
import { CertProgram, CertProgramAreaDTO } from '../../models/certProgram.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'cert-area-assignment',
  templateUrl: './cert-area-assignment.component.html',
  styleUrls: ['./cert-area-assignment.component.scss']
})
export class CertAreaAssignmentComponent implements OnInit {


  constructor(private fb: UntypedFormBuilder,
    private contractService: ContractService,
    private snackBar: MatSnackBar,
    private authService: AuthService) { }

  certAreaAssignmentForm: UntypedFormGroup;
  activeCertAreas: CertProgramAreaDTO[] = [new CertProgramAreaDTO()];
  selectedCertArea: CertProgramAreaDTO = null;
  availableCertAreas: CertProgramAreaDTO[] = [];
  TexasStateId: number = 21;
  activeCertProgram: CertProgram = new CertProgram();
  filteredCertAreas: Observable<CertProgramAreaDTO[]>;
  addingCertArea: boolean = false;
  removingCertArea: boolean = false;
  @Input() certProgramId: number;

  get certAreaInputControl() {
    return this.certAreaAssignmentForm.get("certAreaInputControl") as UntypedFormControl;
  }

  ngOnInit() {
    this.certAreaAssignmentForm = this.fb.group({
      certAreaInputControl: ''
    });

    this.loadActiveCertificate();


    this.filteredCertAreas = this.certAreaInputControl.valueChanges
        .pipe(startWith(''), map(value => this._filterCertAreas(value)));
  }


  loadActiveCertificate() {
    this.contractService.GetCertProgram(this.certProgramId).subscribe(data => {
      if (data.message === 'No ActiveCert Program Found') {
        return;
      }
      this.activeCertProgram = data;
      this.loadCertAreas();
    });
  }

  loadCertAreas() {
    this.contractService.GetCertAreas(this.TexasStateId).subscribe(data => {
      this.availableCertAreas = this.getAvailableAreas(data)
    });
  }

  addProgramCertArea() {

    if (this.selectedCertArea == null) {
      return;
    }

    this.addingCertArea = true;

    let addCommand: CertAreaAddCommand = {
      certProgramId: this.certProgramId,
      certAreaId: this.selectedCertArea.id,
      modUser: this.authService.user.displayName
    };

    this.contractService.addCertAreaForCertProgram(addCommand).subscribe(() => {
      this.certAreaInputControl.reset();
      this.loadActiveCertificate();
    },
      () => {
        this.snackBar.open(`Problem adding Certification Area`, 'Okay');
      },
      () => {
        this.addingCertArea = false;
      });
  }

  certAreaSelected(event: MatAutocompleteSelectedEvent) {
    this.selectedCertArea = event.option.value;
  }

  removeCertArea(certProgramArea: CertProgramAreaDTO) {
    this.removingCertArea = true;
    this.contractService.removeCertAreaForCertProgram(certProgramArea.id).subscribe(() => {
      this.loadActiveCertificate();
    },
      () => {
        this.snackBar.open(`Problem removing Certification Area`, 'Okay');
      },
      () => {
        this.removingCertArea = false;
      });
  }

  displayFn(certAreaOption: CertProgramAreaDTO): string {
    return certAreaOption && certAreaOption.areaName ? certAreaOption.areaName : '';
  }

  get disableAddCertArea() {
    return this.selectedCertArea === null || this.addingCertArea;
  }

  get disableRemoveCertArea() {
    return this.removingCertArea;
  }

  private getAvailableAreas(allCertAreas: CertProgramAreaDTO[]) {
    let assignedCertAreaIds: number[] = [];
    this.activeCertProgram.certProgramAreaDTOs.forEach(assignedCertArea => {
      assignedCertAreaIds.push(assignedCertArea.certAreaId);
    });
    return allCertAreas.filter(certArea => assignedCertAreaIds.includes(certArea.id) == false);
  }

  private _filterCertAreas(value: string): CertProgramAreaDTO[] {
    if (value === null) {
      return this.availableCertAreas;
    }

    if (typeof value !== 'string') {
      let obj: CertProgramAreaDTO = value as CertProgramAreaDTO;
      value = obj.areaName;
    }

    const filterValue = this._normalizeValue(value);
    return this.availableCertAreas.filter(certArea => this._normalizeValue(certArea.areaName).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

}
