import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigService } from '../../../services/config.service';
import { DialerService } from '../../../services/dialer.service';
import { DialerDomainData } from '../../models/dialer/dialerDomainData';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-dialer',
  templateUrl: './dialer.component.html',
  styleUrls: ['./dialer.component.scss']
})
export class DialerComponent implements OnInit {
  dialerData: DialerDomainData;
  SUCCESFULL_CONNECTION_STATUS = 'WORKING';

  @Input() phoneNumber;

  constructor(private dialerService: DialerService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private configSvc: ConfigService) {
  }

  ngOnInit(): void {    
  }

  private async loadDialerMetaData() {
    await this.dialerService.getDomainData()
      .then(data => {
      this.dialerData = {
        context: data.context,
        metadata: data.metadata,
        orgId: data.orgId,
        userId: data.userId
        };
      }).catch(error => {
        this.snackBar.open('Dialer service is not available, please log in to your dialer agent service and try again', 'Error', { duration: 5 * 1000 });
      });    
  }

  async callToIntern() {
    //Loading dialer metadata to being able to interact with dialer system
    await this.loadDialerMetaData();

    if (!this.dialerData || !this.dialerData.metadata) {
      this.snackBar.open('Dialer service is not available, please log in to your dialer agent service and try again', 'Error', { duration: 5 * 1000 });
      this.loadDialerMetaData();
      return;
    }
    this.dialerService.isAgentLoginActive(this.dialerData)
      .subscribe(response => {
        if (response == this.SUCCESFULL_CONNECTION_STATUS) {
          //check if this is not production then add a confirmation message
          this.showConfirmationMessageToCall();          
        } else {
          this.snackBar.open(`An error occured calling number ${this.phoneNumber}`, 'Error', {
            duration: 5 * 1000,
          });
          return;
        }
      }, () => {
        this.snackBar.open('Dialer service is not available, please log in to your dialer agent service and try again', 'Error', { duration: 5 * 1000 });
        this.loadDialerMetaData();
      });

  }

  private showConfirmationMessageToCall() {
    if (this.configSvc.environment != 'Production') {
      const dialerDialogConfirmation = this.dialog.open(ConfirmDialogComponent, {
        width: '400px',
        maxWidth: '400px',
        data: {
          title: 'Dialer: Call confirmation',
          message: `Are you sure you want to call phone number: ${this.phoneNumber}?`,
          acceptButtonText: 'Yes',
          declineButtonText: 'No'
        }
      });

      dialerDialogConfirmation.afterClosed().subscribe({
        next: (isConfirmed): void => {
          if (!isConfirmed) {
            return;
          }

          //when all validations are passed call to the intern
          this.performDialCall();
        },
        error: () => {
          this.snackBar.open(`Error calling number: ${this.phoneNumber}`, 'Error', {
            duration: 5 * 1000,
          });
        },
      });
    } else {
      this.performDialCall();
    }
  }

  private performDialCall() {
    this.dialerService.callToIntern(this.dialerData, this.phoneNumber)
      .catch(() => {
        this.snackBar.open(`An error occured calling number ${this.phoneNumber}`, 'Error', {
          duration: 5 * 1000,
        })
      });
  }
}
