import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  private profileSource = new BehaviorSubject<any>(null);
  currentProfile = this.profileSource.asObservable();

  shareProfile(profile: any){
    this.profileSource.next(profile);
  }

  constructor() { }
}
