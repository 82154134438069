import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CoachService } from 'src/app/services/coach.service';
import { MentorContactNoteDTO } from 'src/app/shared/models/mentor-contact-note.model';
import { MentorAssignment } from 'src/app/shared/models/mentorAssignments.model';
import { RoleValue } from 'src/app/shared/models/role.model';
import { MentorContactAddComponent } from '../mentor-contact-add/mentor-contact-add.component';

@Component({
    selector: 'app-mentor-contacts',
    templateUrl: './mentor-contacts.component.html',
    styleUrls: ['./mentor-contacts.component.scss']
})
export class MentorContactsComponent implements OnInit {
    profileId: number;
    currentMentorAssignment: MentorAssignment = null;
    mentorContactsDataSource: any;
    mentorContactsDisplayedColumns: string[] = ['MentorName', 'Note', 'CommunicationMethod', 'ContactDate', 'ContactedBy'];
    mentorContactsFound: boolean = false;
    loadingMentorContacts: boolean = false;
    userRoleAllowedToAddMentor: boolean = false;

    constructor(
        private coachSvc: CoachService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private authService: AuthService) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.profileId = params["profileId"];
            this.getMentorContacts(this.profileId);
            this.getCurrentMentor(this.profileId);
        });

        this.checkIfUserHasRoleToAddContact();
    }

    checkIfUserHasRoleToAddContact() {
        if (this.authService.user) {
            let userRoles = this.authService.user.roles;
            const rolesThatCanAddMentorNotes = [RoleValue.CS_Advisor, RoleValue.CS_Advisor_Manager, RoleValue.CC_Advisor, RoleValue.CC_Manager, RoleValue.Finance_Admin];
            this.userRoleAllowedToAddMentor = this.userIsInRole(userRoles, rolesThatCanAddMentorNotes);
        }
    }

    getCurrentMentor(profileId: number) {
        this.coachSvc.getActiveMentorAssignment(profileId).subscribe((mentorAssignment) => {
            this.currentMentorAssignment = mentorAssignment;
        });
    }

    getMentorContacts(profileId: number) {
        this.loadingMentorContacts = true;
        this.coachSvc.getMentorContacts(profileId).subscribe((mentorContacts) => {
            if (mentorContacts && mentorContacts.length > 0) {
                this.mentorContactsFound = true;
            }

            this.mentorContactsDataSource = new MatTableDataSource<MentorContactNoteDTO>(mentorContacts);
        },
            () => { },
            () => { this.loadingMentorContacts = false; });
    }

    get enableAddContactLogButton() {
        return this.loadingMentorContacts === false
            && this.currentMentorAssignment !== null
            && this.userRoleAllowedToAddMentor;
    }

    displayAddMentorContactForm() {
        let dialogConfig: MatDialogConfig = {
            disableClose: false,
            autoFocus: true,
            width: '40%',
            data: {
                mentorAssignmentID: this.currentMentorAssignment.MentorAssignmentId,
                mentorName: `${this.currentMentorAssignment.FirstName} ${this.currentMentorAssignment.LastName}`
            }
        };

        let dialogRef = this.dialog.open(MentorContactAddComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.openSnackBar('Mentor contact note saved.', 'Okay');
                this.getMentorContacts(this.profileId);
            }
        });
    }

    openSnackBar(data: string, status: string) {
        this.snackBar.open(data, status, {
            duration: 3 * 1500,
        });
    }

    private userIsInRole(userRoles: any, allowedRoleIds: RoleValue[]) {
        const allowedStatusRoles = userRoles.filter(role => allowedRoleIds.includes(role.AuthorizationRoleID));
        return (allowedStatusRoles.length > 0);
    }

}
