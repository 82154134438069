import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';


@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    constructor() {

       
    }

    ngOnInit() {        
        if (environment.Build_Timestamp != '')
        {
            document.getElementById("Build_Timestamp").innerHTML = "Build Time:" + environment.Build_Timestamp;
        }
    }

    get now() {
        return new Date();
    }

    openYoutube(): void {
        window.open('https://www.youtube.com/channel/UCtXEfaq_3jlKUanss6KOdCw', '_blank');
    }

    openLinkedin(): void {
        window.open('https://www.linkedin.com/company/texas-teachers-acp/', '_blank');
    }

    openTwitter(): void {
        window.open('https://twitter.com/TexasTeachers/', '_blank');
    }

    openFacebook(): void {
        window.open('https://www.facebook.com/TexasTeachers/', '_blank');
    }
}
