import { MentorObservationDocument } from './mentor-observation-document';

export class MentorAssignment {
  MentorAssignmentId: number;
  ProfileID: number;
  FirstName: string;
  LastName: string;
  DistrictMentorFee: number;
  MentorId: number;
  MentorTypeId: number;
  AssignedOn: Date;
  EndDate: Date;
  LastUpdatedOn: Date;
  LastUpdatedBy: string;
  CreatedBy: string;
  CreatedOn: Date;
  IsDeleted: boolean;
  Index: number;
  CampusName: string;
  EmailAddress: string;
  MentorFee: number;
}

export class MentorContact {
  AlertComments: string = "";
  AlertName: string = "";
  Assessment: string = "";
  AssessmentId: number;
  Date: string = "";
  DocReceived: string = "";
  FirstName: string = "";
  LastName: string = "";
  Identifier: string = "";
  ObsType: string = "";
  ObsCompleted: boolean = false;
  ObsCompletedDate: Date;
  ObsStatus: string = "";
  ObsFinancialStatus: string = "";
  ScheduledDate: string = "";
  pdfURL: string;
  CertificationType: string;
}

export class MentorObservation {
  MentorObservationId: number;
  MentorAssignmentId: number;
  MentorId: number;
  Title: string;
  FirstName: string;
  LastName: string;
  ProfileID: number;
  ObservationDate: Date;
  SemesterID: number;
  Semester: string;
  ObsFinancialStatusId: number;
  ObsFinancialStatus: string;
  LastUpdatedBy: string;
  LastUpdatedOn: Date;
  CreatedBy: string;
  CreatedOn: Date;
  IsDeleted: boolean;
  MentorObservationDocumentDTO: MentorObservationDocument;
}
