import { Injectable } from '@angular/core';
import { Reason } from '../shared/models/reason.model';
import { ReasonTypeEnum } from '../shared/enums/reason-type.enum';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class ReasonsService {

  apiUrl = '';
  constructor(private httpClient: HttpClient, private configSvc: ConfigService) {
    this.apiUrl = this.configSvc.getApiUrl();
  }

  getCandidateStatusReasons(reasonType: ReasonTypeEnum): Observable<Reason[]> {
    return this.httpClient.get<Reason[]>(`${this.apiUrl}/status-reasons?typeId=${reasonType}`);
  }

}
