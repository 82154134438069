import { Employment } from '../../shared/models/employment.model';
import { Education } from '../../shared/models/education.model';

export class Address {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  stateCode: string;
}

export class PhoneNumber {
  id: number;
  phoneNumber: string;
  extension: string;
  type: number;
  isPrimary: boolean;
  active: boolean;
}

export class Email {
  id: number;
  emailAddress: string;
  isPrimary: boolean;
  extension: string;
  type: number;
  active: boolean;
}

export class Intern {
  financialStatusId: number | null;
  holdEndDate: Date | null;
  holdStartDate: Date | null;
  suffix: string;
  id: number;
  internNumber: string; // TODO: This field is deprecated. Instead, use internId or id.
  objectId: string;
  internId: string;
  lastName: string;
  middleName: string;
  maidenName: string;
  preferredName: string;
  firstName: string;
  gender: string;
  ethnicity: string;
  race: string;
  addresses: Address[];
  phoneNumbers: PhoneNumber[];
  emails: Email[];
  emailAddress: string;
  dob: string;
  admitDate: Date;
  offeredAdmitDate: Date;
  offeredAdmitDateExpired: boolean;
  applicationDate: Date;
  programPath: string;
  programPathId: number;
  paidDate: Date;
  ssn: string;
  referralSources: number;
  isVip: boolean;
  isExcludedFromHcl: boolean;
  admittedThreshold: boolean;
  fileLocation: number;
  referralIntern: string;
  profileId: number;
  college: string;
  campus: string;
  district: string;
  status: string;
  statusId: number;
  state: string;
  stateId: number;
  stateHasConfigurableAgreements: boolean;
  accessedByYouOn: Date;
  city: string;
  educations: Education[];
  employments: Employment[];
  stateLicenseId: string;
  ruleset: string;
  userEmailAddress: string;
  thirdPartyId: number;
  doNotEmail: boolean;
  lateHire: boolean;
  lateHireExpirationDate: Date;
  federalBackgroundCheckDate: Date;
  createUser: string;
  modUser: string;
  interviewDate: Date;
  profileIdentifier: string;
  trainingPathId: number;
  disqualificationReason?: string;
  sponsorshipProfileId?: number;
  sponsorName?: string;
  statusReason?: string;
  hasConferredDateOrContingencyDate?: boolean;
}
