// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-spacer {
  flex: 1;
}

.user {
  font-family: sans-serif;
  font-size: 0.6rem;
  font-weight: 600;
  padding-right: 20px;
  letter-spacing: 0.1rem;
}

.header-row {
  background-image: url('midpage-banner-blue-lines-new.png');
}

.logout {
  width: 120px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImhlYWRlci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLE9BQUE7QUFDSjs7QUFFQTtFQUNJLHVCQUFBO0VBQ0EsaUJBQUE7RUFDQSxnQkFBQTtFQUNBLG1CQUFBO0VBQ0Esc0JBQUE7QUFDSjs7QUFFQTtFQUNJLDBEQUFBO0FBQ0o7O0FBRUE7RUFDSSxZQUFBO0FBQ0oiLCJmaWxlIjoiaGVhZGVyLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmhlYWRlci1zcGFjZXIge1xuICAgIGZsZXg6IDE7XG59XG5cbi51c2VyIHtcbiAgICBmb250LWZhbWlseTogc2Fucy1zZXJpZjtcbiAgICBmb250LXNpemU6IC42cmVtO1xuICAgIGZvbnQtd2VpZ2h0OiA2MDA7XG4gICAgcGFkZGluZy1yaWdodDogMjBweDtcbiAgICBsZXR0ZXItc3BhY2luZzogLjFyZW07XG59XG5cbi5oZWFkZXItcm93IHtcbiAgICBiYWNrZ3JvdW5kLWltYWdlOiB1cmwoJy4uLy4uLy4uLy4uL2Fzc2V0cy9pbWFnZXMvbWlkcGFnZS1iYW5uZXItYmx1ZS1saW5lcy1uZXcucG5nJyk7XG59XG5cbi5sb2dvdXQge1xuICAgIHdpZHRoOiAxMjBweDtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/components/header/header.component.scss"],"names":[],"mappings":"AAAA;EACI,OAAA;AACJ;;AAEA;EACI,uBAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,0DAAA;AACJ;;AAEA;EACI,YAAA;AACJ;AACA,wzBAAwzB","sourcesContent":[".header-spacer {\n    flex: 1;\n}\n\n.user {\n    font-family: sans-serif;\n    font-size: .6rem;\n    font-weight: 600;\n    padding-right: 20px;\n    letter-spacing: .1rem;\n}\n\n.header-row {\n    background-image: url('../../../../assets/images/midpage-banner-blue-lines-new.png');\n}\n\n.logout {\n    width: 120px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
