import { Component, forwardRef, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators, Validator } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CampusService } from 'src/app/services/campus.service';
import { DistrictService } from 'src/app/services/district.service';
import { CampusDTO } from '../../models/campusDTO.model';
import { DistrictAddress } from '../../models/district-address.model';

export interface DistrictCampusValues {
  district: number;
  campus: number;
}

@Component({
  selector: 'district-campus-dropdowns',
  templateUrl: './district-campus-dropdowns.component.html',
  styleUrls: ['./district-campus-dropdowns.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DistrictCampusDropdownsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DistrictCampusDropdownsComponent),
      multi: true
    }
  ]
})
export class DistrictCampusDropdownsComponent implements OnInit, ControlValueAccessor, OnDestroy, OnChanges {
  
  subscriptions: Subscription[] = [];
  districtId: number;
  districtList: DistrictAddress[] = [];
  filteredDistrictOptions: Observable<DistrictAddress[]>;
  filteredCampusOptions: Observable<CampusDTO[]>;
  campusList: CampusDTO[] = [];
  isDistrictLoading: boolean = false;
  isCampusLoading: boolean = false;
  DistrictCampusFormGroup: UntypedFormGroup;
  selectedDistrictId: number = 0;

  get value(): DistrictCampusValues {
    return this.DistrictCampusFormGroup.value;
  }

  set value(value: DistrictCampusValues) {
    this.DistrictCampusFormGroup.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  @Input() stateId: number = 0;
  @Input() submitted: boolean;
  @Input() isDistrictSelected: boolean;
  @Input() districtWidth: string;
  @Input() districtMargin: string;
  @Input() campusWidth: string;
  @Input() campusMargin: string;

  constructor(private districtService: DistrictService,
    private campusService: CampusService, 
    private formBuilder: UntypedFormBuilder) { 
      this.DistrictCampusFormGroup = this.formBuilder.group({
        district: [{value: '', disabled: true}, Validators.required],
        campus: [{value: '', disabled: true}, Validators.required]
    });

    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.DistrictCampusFormGroup.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  ngOnChanges(): void {
    if (this.submitted) {
      const districtControl = this.DistrictCampusFormGroup.get('district');
      districtControl.markAsTouched();
    }

    if (this.isDistrictSelected) {
      const campusControl = this.DistrictCampusFormGroup.get('campus');
      campusControl.markAsTouched();
    }

  }

  ngOnInit(): void {

    if(this.stateId > 0){
      this.loadDistricts();

      this.isDistrictLoading = true;
      this.districtService.getDistrictSearchResult(this.stateId).subscribe(d => {

        this.districtList = d;
        this.campusList = [];

        this.filteredDistrictOptions = this.DistrictCampusFormGroup.get('district').valueChanges.pipe(
          startWith(''),
          map(value => this._filterDistricts(value))
        );

        this.filteredCampusOptions = this.DistrictCampusFormGroup.get('campus').valueChanges.pipe(
          startWith(''),
          map(value => this._filterCampuses(value))
        );
    });
    }
  }

  private displayFnDistrict(district: DistrictAddress) {
    return district ? district.districtName : undefined;
  }

  private displayFnCampus(campus: CampusDTO) {
    return campus ? campus.name : undefined;
  }

  private _filterDistricts(value: string): DistrictAddress[] {
    if (value === '') {
      this.DistrictCampusFormGroup.get('campus').reset();
        return this.districtList.slice(0, 99);
    } 
    else if (value !== null) {
        const filterValue = value.toString().toLowerCase();
        return this.districtList.filter(option =>
          option.districtName.toString().toLowerCase().includes(filterValue)).slice(0, 99);
    }
    return null;
  }

  private _filterCampuses(value: string): CampusDTO[] {
    if(this.campusList.length > 0){      
      if (value === '' || value == null) {
          return this.campusList.slice(0, 99);
      } 
      else if (value !== null) {
          const filterValue = value.toString().toLowerCase();
          return this.campusList.filter(option => 
            option.name.toString().toLowerCase().includes(filterValue)).slice(0, 99); 
      }
    }
    return null;
  }

  public loadDistricts() {
     //start loading spinner
     this.isDistrictLoading = true;

     this.districtService.getDistrictSearchResult(this.stateId).subscribe(data => {
         this.districtList = data;
         this.isDistrictLoading = false;
         this.DistrictCampusFormGroup.get('district').enable();
     });
  }

  public setDistrict(){
    const districtFilter: DistrictAddress = this.DistrictCampusFormGroup.get('district').value;
    var selection = this.districtList.find(d => d.districtName == districtFilter.districtName)
    if(selection != null){
      this.selectedDistrictId = selection.districtId
      this.DistrictCampusFormGroup.get('campus').reset();
      this.getCampuses();
    }
    else {
      this.DistrictCampusFormGroup.get('campus').reset();
      this.DistrictCampusFormGroup.get('campus').disable();
    }
  }

  private getCampuses() {
    this.campusList = [];
    this.isCampusLoading = true;
    this.campusService.getCampusesForDistrict(this.selectedDistrictId).subscribe(data => {
      this.campusList = data
      this.isCampusLoading = false;
      this.DistrictCampusFormGroup.get('campus').enable();
    });
  }
  
  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value): void {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.DistrictCampusFormGroup.reset();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  validate(_: UntypedFormControl) {
    return this.DistrictCampusFormGroup.valid ? null : { districtAndCampus: { valid: false } };
  }
}
