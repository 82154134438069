import { Role } from './role.model';

export class User {
    displayName: string;
    email: string;
    avatar: string;
    roles: Role[];
    givenName: string;
    surname: string;
    id: any;
}
