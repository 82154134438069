import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Mentor } from '../shared/models/mentor.model';
import { retry, catchError, shareReplay } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { MentorAssignment, MentorContact, MentorObservation } from '../shared/models/mentorAssignments.model';
import { ConfigService } from './config.service';
import { MentorDocument } from '../shared/models/mentor-document';
import { MentorObservationDocument } from '../shared/models/mentor-observation-document';

@Injectable({
  providedIn: 'root'
})

export class MentorService {

  headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

  constructor(private httpClient: HttpClient, private configService: ConfigService) { }

  public getAllAvailable(profileId: number) {
    return this.httpClient.get<Mentor[]>(`${this.configService.apiUrl}/v1.0/Mentors/${profileId}`).pipe(
      retry(3),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public getActiveMentor(profileId: number) {
    return this.httpClient.get<Mentor>(`${this.configService.apiUrl}/v1.0/Mentors/Active/${profileId}`).pipe(
      retry(3),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public unassign(mentorAssignmentId) {
    let headersToPass = new HttpHeaders();
    headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');
    let param = new HttpParams();
    param.append("MentorAssignmentId", mentorAssignmentId);

    return this
      .httpClient
      .delete(`${this.configService.apiUrl}/v1.0/Mentors/Assignments/` + mentorAssignmentId, { headers: headersToPass }).pipe(
        catchError(error => {
          console.log(error);
          return EMPTY;
        }),
        shareReplay()
      );
  }

  public assign(mentorAssignment) {
    let headersToPass = new HttpHeaders();
    headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient.post<MentorAssignment[]>(`${this.configService.apiUrl}/v1.0/Mentors/Assignments/`, mentorAssignment, { headers: headersToPass }).pipe(
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public changeAssign(mentorAssignment) {
    let headersToPass = new HttpHeaders();
    headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient.put<MentorAssignment>(`${this.configService.apiUrl}/v1.0/Mentors/Assignments/`, mentorAssignment, { headers: headersToPass }).pipe(
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public changeAssignList(mentorAssignment) {
    let headersToPass = new HttpHeaders();
    headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient.post<MentorAssignment>(`${this.configService.apiUrl}/v1.0/Mentors/Assignments/UpdateList`, mentorAssignment, { headers: headersToPass }).pipe(
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }


  public getAssignedMentors(profileId: number) {
    return this.httpClient.get<MentorAssignment[]>(`${this.configService.apiUrl}/v1.0/Mentors/GetMentorAssignments/${profileId}`).pipe(
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public getMentorsForCampus(campusId: number) {
    return this.httpClient.get<Mentor[]>(`${this.configService.apiUrl}/v1.0/Mentors/Campus/${campusId}`);
  }

  public getObservations(profileId: number) {
    return this.httpClient.get<MentorObservation[]>(`${this.configService.apiUrl}/v1.0/Mentors/Observations/${profileId}`).pipe(
      retry(3),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public addObservations(mentorAssignments) {
    let headersToPass = new HttpHeaders();
    headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient.post<MentorObservation>(`${this.configService.apiUrl}/v1.0/Mentors/Observations/Add/`, mentorAssignments, { headers: headersToPass }).pipe(
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public updateObservations(mentorAssignments) {
    let headersToPass = new HttpHeaders();
    headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.put<MentorObservation>(`${this.configService.apiUrl}/v1.0/Mentors/Observations/Update/`, mentorAssignments, { headers: headersToPass }).pipe(
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public uploadDocument(formData: FormData) {
    return this.httpClient.post<MentorDocument>(`${this.configService.apiUrl}/file/mentor-document`, formData);
  }
  public downloadDocument(documentId: number) {
    let requestHeaders: HttpHeaders = null;
    return this.httpClient.get<any>(`${this.configService.apiUrl}/file/mentor-document/${documentId}`, { headers: requestHeaders, responseType: 'blob' as 'json' });
  }

  public getAllDocuments(campusContactId: number, documentTypeId: number) {

    return this.httpClient.get<MentorDocument[]>(
      `${this.configService.apiUrl}/mentor-documents/${campusContactId}?documentTypeId=${documentTypeId}`);
  }

  public deleteDocument(documentId: number) {

    return this.httpClient.delete<void>(
      `${this.configService.apiUrl}/mentor-documents/${documentId}`);
  }

  public uploadObservationDocument(formData: FormData): Observable<MentorObservationDocument> {
    return this.httpClient.post<MentorObservationDocument>(`${this.configService.apiUrl}/file/mentor-observation-document`, formData);
  }

  public getMentorObservationDocument(mentorObservationId: number) {
    return this.httpClient.get<MentorObservationDocument>(
      `${this.configService.apiUrl}/mentor-observations/${mentorObservationId}/document`);
  }

  public downloadMentorObservationDocument(documentId: number) {
    let requestHeaders: HttpHeaders = null;
    return this.httpClient.get<any>(`${this.configService.apiUrl}/file/mentor-observation-document/${documentId}`, { headers: requestHeaders, responseType: 'blob' as 'json' });
  }

  public deleteMentorObservationDocument(documentId: number, user: string) {
    return this.httpClient.delete<void>(
      `${this.configService.apiUrl}/mentor-observation-documents/${documentId}?deleteUser=${encodeURIComponent(user)}`);
  }

}
