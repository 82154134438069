import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(value: number, ...args: any[]): string {
    switch (value) {
      case 1: return 'Lead';
      case 2: return 'Applicant';
      case 3: return 'Disqualified';
      case 4: return 'Not Admitted';
      case 5: return 'Offered Admission';
      case 6: return 'Admitted';
      case 7: return 'Paid';
      case 8: return 'Hired';
      case 9: return 'Complete';
      case 10: return 'Discharged';
      case 11: return 'Dropped';
      default: return null;
    }
  }
}
