import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { CoachService } from 'src/app/services/coach.service';
//import { CampusSupervisorContactNoteAddCommand } from 'src/app/shared/models/campusSupervisor-contact-note-add.model';

@Component({
    selector: 'app-campus-supervisor-contact-add',
    templateUrl: './campus-supervisor-contact-add.component.html',
    styleUrls: ['./campus-supervisor-contact-add.component.scss']
})
export class CampusSupervisorContactAddComponent implements OnInit {

    today: Date = new Date();
    mainForm: UntypedFormGroup;
    passedInData: any;
    addingInProgress: boolean = false;

    constructor(private dialogRef: MatDialogRef<CampusSupervisorContactAddComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private coachService: CoachService) {
        this.passedInData = data;
    }

    ngOnInit() {
        this.initializeForm();
    }

    initializeForm() {
        this.mainForm = this.fb.group({
            campusSupervisorName: new UntypedFormControl({ value: this.passedInData.campusSupervisorName, disabled: true }),
            communicationType: ['', Validators.required],
            note: ['', Validators.required],
            contactDate: ['', Validators.required],
            contactedBy: new UntypedFormControl({ value: this.authService.user.displayName, disabled: true })
        });
    }

    addCampusSupervisorContact() {

        if (this.mainForm.invalid) {
            return;
        }

        // let addCommand = {
        //     campusSupervisorAssignmentID: this.passedInData.campusSupervisorAssignmentID,
        //     communicationMethodID: this.mainForm.controls.communicationType.value,
        //     contactDate: this.mainForm.controls.contactDate.value,
        //     contactedBy: this.authService.user.displayName,
        //     note: this.mainForm.controls.note.value
        // } as CampusSupervisorContactNoteAddCommand;

        // this.addingInProgress = true;
        // this.coachService.addCampusSupervisorContact(addCommand).subscribe(
        //     () => { this.closeModal(true); },
        //     () => { },
        //     () => { this.addingInProgress = false; })
    }

    closeModal(changesSaved: boolean) {
        this.dialogRef.close(changesSaved);
    }

}