import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { Result } from '../shared/models/result.model';
import { catchError } from 'rxjs/operators';
import { CampusSupervisor } from '../shared/models/campus-supervisor.model';
import { CampusSupervisorAssignment } from '../shared/models/campus-supervisor-assignment.model';

@Injectable({
  providedIn: 'root'
})
export class CampusSupervisorService {
  
  constructor(private httpClient: HttpClient, private configService: ConfigService) { }
  
  public getCampusSupervisorsForCampus(campusId: number) {
    return this.httpClient.get<Result>(`${this.configService.apiUrl}/v1.0/campus/${campusId}/campus-supervisors`);
  }

  public getCampusSupervisorAssignmentsForProfile(profileId: number) {
    return this.httpClient.get<CampusSupervisorAssignment[]>(`${this.configService.apiUrl}/v1.0/campus-supervisors?profileId=${profileId}`);
  }

  public updateCampusSupervisorAssignment(campusSupervisorAssignment) {
    let headersToPass = new HttpHeaders();
    headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient.put<CampusSupervisorAssignment>(`${this.configService.apiUrl}/v1.0/campus-supervisors-assignments/${campusSupervisorAssignment.CampusSupervisorAssignmentId}`, campusSupervisorAssignment, { headers: headersToPass });
  }

  public updateCampusSupervisorAssignmentList(campusSupervisorAssignment) {
    let headersToPass = new HttpHeaders();
    headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient.post<CampusSupervisorAssignment>(`${this.configService.apiUrl}/v1.0/campus-supervisors-assignments/update-list`, campusSupervisorAssignment, { headers: headersToPass });
  }

  public addCampusSupervisorAssignment(campusSupervisorAssignment) {
    let headersToPass = new HttpHeaders();
    headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient.post<CampusSupervisorAssignment[]>(`${this.configService.apiUrl}/v1.0/campus-supervisors-assignments`, campusSupervisorAssignment, { headers: headersToPass });
  }
}
