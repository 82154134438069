import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError, retry, shareReplay } from 'rxjs/operators';
import { DistrictAddress } from '../shared/models/district-address.model';
import { State } from '../shared/models/state.model';
import { ConfigService } from './config.service';
import { IContactService, Contact, ContactType } from '../shared/models/contact.model';

@Injectable({
  providedIn: 'root'
})
export class DistrictService implements IContactService {

    headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    constructor(private httpClient: HttpClient,
        private configService: ConfigService) {     
    }      

    public getStatesFromActiveDistricts() : Observable<State[]> {
        return this.httpClient.get<State[]>(`${this.configService.apiUrl}/v1.0/District/Active/States`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public getDistrictSearchResult(stateId: number): Observable<DistrictAddress[]> {
        return this.httpClient.get<DistrictAddress[]>(`${this.configService.apiUrl}/v1.0/District/Active/State/${stateId}`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public existDistrictNameByState(districtName: string, stateId: number): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.configService.apiUrl}/v1.0/District/Active/Name/${districtName}/${stateId}`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public createDistrict(district: DistrictAddress) {
        return this.httpClient.post(`${this.configService.apiUrl}/v1.0/District/`, district).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public updateDistrict(district: DistrictAddress) {
        return this.httpClient.patch(`${this.configService.apiUrl}/v1.0/District/`, district).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public existsStateDistrictLicenseId(stateLicenseId: string, stateId: number): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.configService.apiUrl}/v1.0/District/Active/License/${stateLicenseId}/${stateId}`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    getContacts(districtID: number): Observable<Contact[]> {
        return this.httpClient.get<Contact[]>(`${this.configService.apiUrl}/v1.0/District/contact/${districtID}`);
    }
    updateContact(contact: Contact): Observable<Contact> {        
        return this.httpClient.put<Contact>(`${this.configService.apiUrl}/v1.0/District/contact`, contact);
    }
    deleteContact(contactID: number): Observable<boolean> {
        return this.httpClient.delete<boolean>(`${this.configService.apiUrl}/v1.0/District/contact/${contactID}`);
    }
    addContact(contact: Contact): Observable<Contact[]> {
        return this.httpClient.post<Contact[]>(`${this.configService.apiUrl}/v1.0/District/contact`, contact);
    }

    getContactTypes(): Observable<ContactType[]>{
        return this.httpClient.get<ContactType[]>(`${this.configService.apiUrl}/v1.0/District/contact/type`);
    }
    
    getContact(campusContactId: number): Observable<Contact> {
        return this.httpClient.get<Contact>(`${this.configService.apiUrl}/v1.0/District/contact/id/${campusContactId}`);
    }
}