import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from '../../../services/auth.service';
import { Role, RoleValue } from '../../models/role.model';
import { User } from '../../models/user';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']  
})
export class HeaderComponent implements OnInit {
    canUsePendingCertTool: boolean = false;
    canUseCallAssignment: boolean = false;
    canUsePayrollAuthorization = false;
    canUseFSObservation: boolean = true;
    canUseTranscriptUpload: boolean;
    canUseNotificationManager: boolean;
    canUsePromotionManager: boolean;
    canUseSponsorshipManager: boolean;

    constructor(private authService: AuthService,
        private msalService: MsalService,
        private router: Router) { }

    ngOnInit() {
        if(!!this.authService.user && !!this.authService.user.roles){
            this.enableNavigationAccordingToUserRoles(this.authService.user.roles);
        }
        else {
            this.authService.userLoaded.subscribe((user: User) => this.enableNavigationAccordingToUserRoles(user.roles))
        }
    }

    private enableNavigationAccordingToUserRoles(userRoles: Role[]){
        if(!userRoles){
            return;
        }
        
        const pendingCertAllowedRoles = [RoleValue.Administrator, RoleValue.CS_Advisor, RoleValue.CC_Manager];
        this.canUsePendingCertTool = this.userIsInRole(userRoles, pendingCertAllowedRoles);

        const callAssignmentAllowedRoles = [RoleValue.Administrator, RoleValue.Advisor, RoleValue.Advisor_Manager];
        this.canUseCallAssignment = this.userIsInRole(userRoles, callAssignmentAllowedRoles);
        
        const payrollAuthorizationRoles = [RoleValue.Administrator,RoleValue.CC_Advisor, RoleValue.CC_Manager, RoleValue.Finance_Admin];
        this.canUsePayrollAuthorization = this.userIsInRole(userRoles, payrollAuthorizationRoles);
        
        const fsObservationsAuthorizationRoles = [RoleValue.Finance_Admin];
        this.canUseFSObservation = this.userIsInRole(userRoles, fsObservationsAuthorizationRoles);

        const transcriptUploadAllowedRoles = [RoleValue.Transcript_Processor];
        this.canUseTranscriptUpload = this.userIsInRole(userRoles, transcriptUploadAllowedRoles);

        const notificationManagerAuthorizationRoles = [RoleValue.Notification_Creator, RoleValue.Notification_Approver];
        this.canUseNotificationManager = this.userIsInRole(userRoles, notificationManagerAuthorizationRoles);

        const promotionManagerAuthorizationRoles = [RoleValue.Promotion_Manager];
        this.canUsePromotionManager = this.userIsInRole(userRoles, promotionManagerAuthorizationRoles);

        const sponsorshipManagerAuthorizationRoles = [RoleValue.Sponsorship_Admin];
        this.canUseSponsorshipManager = this.userIsInRole(userRoles, sponsorshipManagerAuthorizationRoles);
    }

    private userIsInRole(userRoles: any, allowedRoleIds: RoleValue[]){
        const allowedStatusRoles = userRoles.filter(role => allowedRoleIds.includes(role.AuthorizationRoleID));
        return (allowedStatusRoles.length > 0);
    }

    CanUsePendingCertTool() {
        return this.canUsePendingCertTool;
    }    

    Open_ImpersonateCoach() {
        window.open(window.location.protocol + "//" + window.location.host + "/#/impersonation?t=c");
    }

    Open_ImpersonateIntern() {
        window.open(window.location.protocol + "//" + window.location.host + "/#/impersonation?t=i");

    }

    signOut() {
        this.msalService.logout();
        this.router.navigate(['']);
    }
}
