import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { RoleValue } from '../../models/role.model';
import { ContactComponentType } from '../../enums/contact-component-type.enum';
import { Contact, ContactType, DistrictContactTypeValue, IContactService } from '../../models/contact.model';
import { State } from '../../models/state.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/auth.service';
import { EmploymentService } from 'src/app/services/workhistory.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PendingUploadsStatus } from '../mentor-document/mentor-document.component';
import { DocumentType } from 'src/app/shared/enums/document-type';
import { CampusContactType } from '../../enums/campus-contact-type.enum';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CampusContactEditor } from '../../models/campus-contact-editor.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-campus-contact-editor',
    templateUrl: './campus-contact-editor.component.html',
    styleUrls: ['./campus-contact-editor.component.scss']
})
export class CampusContactEditorComponent implements OnInit {
    roles: number[];
    editRoles: number[] = [
        RoleValue.CC_Advisor,
        RoleValue.CC_Manager,
        RoleValue.Advisor_Manager,
        RoleValue.CS_Advisor,
        RoleValue.CS_Advisor_Manager,
        RoleValue.Finance_Admin,
        RoleValue.Administrator,
        RoleValue.Business_Developement // Talent Acquisition
    ];

    viewMentorDetailsRoles: number[] = [
        RoleValue.CC_Advisor,
        RoleValue.CC_Manager,
        RoleValue.Advisor_Manager,
        RoleValue.CS_Advisor,
        RoleValue.CS_Advisor_Manager,
        RoleValue.Finance_Admin,
        RoleValue.Administrator,
        RoleValue.Business_Developement // Talent Acquisition
    ];
    canEditContacts: boolean = false;
    editMode = false;
    showConfirmDelete = false;
    campusId: number;
    campusContactId: number = 0;
    campusContactName: string;
    contactComponentType: ContactComponentType;
    campusContactType: CampusContactType;
    service: IContactService;
    disabledContactType: boolean = false;
    contactForm: UntypedFormGroup = new UntypedFormGroup({
        contactTypeID: new UntypedFormControl(0, Validators.required),
        firstName: new UntypedFormControl('', Validators.required),
        lastName: new UntypedFormControl('', Validators.required),
        title: new UntypedFormControl(''),
        stateLicenseID: new UntypedFormControl(''),
        emailAddress: new UntypedFormControl('', [Validators.required, Validators.email]),
        phoneNumber: new UntypedFormControl(''),
        hasAccessToDistrictPortal: new UntypedFormControl(false),
        mentorDetails: new UntypedFormGroup({
            teachingCertificate: new UntypedFormControl('', this.documentRequiredValidator(() => this.teachingCertificateDocumentsAvailable)),
            certificateDate: new UntypedFormControl(''),
            certificateArea: new UntypedFormControl(''),
            letterOfRecommendation: new UntypedFormControl('', this.documentRequiredValidator(() => this.proofOfAccomplishmentDocumentsAvailable)),
            districtTraining: new UntypedFormControl(''),
            providedBy: new UntypedFormControl(''),
            totTrainingSent: new UntypedFormControl(''),
            address1: new UntypedFormControl(''),
            address2: new UntypedFormControl(''),
            city: new UntypedFormControl(''),
            stateId: new UntypedFormControl(''),
            postalCode: new UntypedFormControl('')
        })
    });
    contactTypes: ContactType[];
    contactList: Contact[] = [];
    updateFirstName: string = null;
    updateLastName: string = null;
    isNewContact = false;
    showSpinner: boolean;
    contactToDelete: Contact = null;
    states: State[];
    options: boolean[] = [true, false];
    showMentorDetails: boolean;
    showCertificateFields: boolean;
    showProvidedByField: boolean;
    canUserViewMentorDetails: boolean;
    showGrantAccessToDistrict: boolean = false;
    contactSelectedForEditing: Contact;
    documentType = DocumentType;
    teachingCertificateDocumentsAvailable: boolean;
    proofOfAccomplishmentDocumentsAvailable: boolean;
    districtTrainingDocumentsAvailable: boolean
    showTeacherCertificateDocuments: boolean;
    showProofOfAccomplishmentDocuments: boolean;
    showDistrictTrainingDocuments: boolean;
    pendingTeachingCertUploads: boolean;
    pendingProofOfAccomplishmentUploads: boolean;
    pendingDistrictTrainingUploads: boolean;
    loadingCampusContact: boolean;
    updatedContact: Contact;
    savedContact: Contact;
    formHeight: string;
    selectedContact: Contact;

    @Input() campusContactEditor: CampusContactEditor;

    @Output() cancelEvent = new EventEmitter();
    @Output() saveNewContact = new EventEmitter<Contact>();
    @Output() updateContact = new EventEmitter<Contact>();

    constructor(
        private snackBar: MatSnackBar,
        private authService: AuthService,
        private employmentService: EmploymentService,
        private dialog: MatDialog) { }


    // Add a private property for managing the unsubscribe mechanism
    private destroy$ = new Subject<void>();

    ngOnInit(): void {

        if (this.campusContactEditor != null) {
            this.campusId = this.campusContactEditor.campusId ?? 0;
            this.campusContactId = this.campusContactEditor.campusContactId ?? 0;
            this.campusContactName = this.campusContactEditor.campusContactName ?? '';
            this.service = this.campusContactEditor.service;
            this.contactComponentType = this.campusContactEditor.contactComponentType;
            this.campusContactType = this.campusContactEditor.campusContactType;
            this.editMode = this.campusContactEditor.editMode;
            this.disabledContactType = this.campusContactEditor.disabledContactType;
            this.formHeight = this.campusContactEditor.formHeight;
            this.contactList = this.campusContactEditor.contactList;
            this.selectedContact = this.campusContactEditor.contact;
        }

        this.loadingCampusContact = true;

        this.setContactFormObservables();

        this.authService.getRolesWithRefresh().then(roles => {
            if (roles !== null) {
                this.roles = roles.map(r => r.AuthorizationRoleID);
                this.canEditContacts = this.roles.find(role => this.editRoles.includes(role)) != undefined;
                this.canUserViewMentorDetails = this.roles.find(role => this.viewMentorDetailsRoles.includes(role)) != undefined;
                this.loadingCampusContact = false;
            }
        });

        this.employmentService.GetStates().subscribe(data => {
            this.states = data;
        });

        this.getContactTypes();

        if (this.editMode && this.campusContactId > 0) {
            this.contactForm.controls.contactTypeID.setValue(this.campusContactType);
            if (this.selectedContact) {
                this.edit(this.selectedContact);
            }
            else {
                this.getCampusContact(this.campusContactId);
            }
        }
        else if (this.editMode && this.campusContactId == 0) {
            this.addContact(this.campusContactType);
        }
    }

    getCampusContact(campusContactId: number) {
        this.service.getContact(campusContactId).subscribe((cc) => {
            this.edit(cc);
        },
            errorObject => {
                this.loadingCampusContact = false;
                if (errorObject.status == 404 || errorObject.status == 400) {
                    this.openSnackBar('Campus contact not found', null);
                }
                else {
                    this.openSnackBar('Could not retrieve campus contact', null);
                }

            })
    }

    updateTeachingCertificateValidity(eventValue: boolean) {
        this.teachingCertificateDocumentsAvailable = eventValue;
        (this.contactForm.controls.mentorDetails as UntypedFormGroup).controls.teachingCertificate.updateValueAndValidity();
    }

    updateProofOfAccomplishmentValidity(eventValue: boolean) {
        this.proofOfAccomplishmentDocumentsAvailable = eventValue;
        (this.contactForm.controls.mentorDetails as UntypedFormGroup).controls.letterOfRecommendation.updateValueAndValidity();
    }

    updateDistrictTrainingDocumentsAvailability(eventValue: boolean) {
        this.districtTrainingDocumentsAvailable = eventValue;
    }

    documentRequiredValidator(documentAvailable: () => boolean): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const optionSelected = control.value as boolean;
            return optionSelected && !documentAvailable()
                ? { atLeastOneDocumentRequired: true }
                : null;
        }
    }

    onPendingUploadsUpdated({ documentType, uploadsPending }: PendingUploadsStatus) {
        switch (documentType) {
            case DocumentType.TeachingCertificate:
                this.pendingTeachingCertUploads = uploadsPending;
                break;

            case DocumentType.ProofOfAccomplishment:
                this.pendingProofOfAccomplishmentUploads = uploadsPending;
                break;

            case DocumentType.DistrictTraining:
                this.pendingDistrictTrainingUploads = uploadsPending;
                break;

            default:
                break;
        }
    }

    onPendingUploadsComplete(documentType: DocumentType) {
        switch (documentType) {
            case DocumentType.TeachingCertificate:
                this.pendingTeachingCertUploads = false;
                break;

            case DocumentType.ProofOfAccomplishment:
                this.pendingProofOfAccomplishmentUploads = false;
                break;

            case DocumentType.DistrictTraining:
                this.pendingDistrictTrainingUploads = false;
                break;

            default:
                break;
        }

        if (!this.pendingUploadsExist()) {
            this.editMode = false;
            this.isNewContact = false;
            if (!!this.savedContact) {
                //emit save event after documents are successfully uploaded
                this.saveNewContact.emit(this.savedContact);
            }
        }
    }

    pendingUploadsExist(): boolean {
        return this.pendingTeachingCertUploads || this.pendingProofOfAccomplishmentUploads || this.pendingDistrictTrainingUploads;
    }

    // Calling methods in the ngOnDestroy lifecycle hook to clean up the subscription
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    setContactFormObservables(): void {
        this.contactForm.get('contactTypeID').valueChanges.pipe(
            takeUntil(this.destroy$) // This ensures we unsubscribe when the component is destroyed
        ).subscribe(id => {
            // Only disable if it's not already disabled to prevent infinite loop
            if (this.disabledContactType && !this.contactForm.get('contactTypeID').disabled) {
                this.contactForm.get('contactTypeID').disable({ emitEvent: false }); // Adding {emitEvent: false} to prevent the valueChanges from emitting again
            }
            if (this.contactComponentType === ContactComponentType.CampusContact && id === Number(CampusContactType.Mentor)) {
                this.showMentorDetails = true;
            } else {
                this.showMentorDetails = false;
            }
        });

        this.contactForm.get('mentorDetails.teachingCertificate').valueChanges.subscribe(data => {
            if (data) {
                this.showCertificateFields = true;
                this.showTeacherCertificateDocuments = true;
            }
            else {
                this.showCertificateFields = false;
                this.showTeacherCertificateDocuments = false;
                this.contactForm.get('mentorDetails.certificateDate').setValue(null);
                this.contactForm.get('mentorDetails.certificateArea').setValue(null);
            }
        });

        this.contactForm.get('mentorDetails.letterOfRecommendation').valueChanges.subscribe(data => {
            if (data) {
                this.showProofOfAccomplishmentDocuments = true;
            }
            else {
                this.showProofOfAccomplishmentDocuments = false;
            }
        });

        this.contactForm.get('mentorDetails.districtTraining').valueChanges.subscribe(data => {
            if (data) {
                this.showProvidedByField = true;
                this.showDistrictTrainingDocuments = true;
                this.contactForm.get('mentorDetails.totTrainingSent').setValue(null);
            }
            else {
                this.showProvidedByField = false;
                this.showDistrictTrainingDocuments = false;
                this.contactForm.get('mentorDetails.providedBy').setValue(null);
            }
        });
    }

    getContactTypes() {
        this.showSpinner = true;
        this.service.getContactTypes().subscribe((result) => {
            this.showSpinner = false;
            this.contactTypes = result;
        })
    }

    edit(contact: Contact) {

        this.updateFirstName = contact.firstName;
        this.updateLastName = contact.lastName;
        this.campusContactId = contact.id;
        this.campusId = contact.entityID;
        this.contactForm.patchValue({
            contactTypeID: contact.contactTypeID || null,
            firstName: contact.firstName || null,
            lastName: contact.lastName || null,
            title: contact.title || null,
            emailAddress: contact.emailAddress || null,
            phoneNumber: contact.phoneNumber || null,
            hasAccessToDistrictPortal: contact.hasAccessToDistrictPortal
        });

        if (contact.mentorDetails) {
            this.contactForm.patchValue({
                stateLicenseID: contact.mentorDetails.stateLicenseID || null,
                mentorDetails: {
                    teachingCertificate: contact.mentorDetails.teachingCertificate,
                    certificateDate: contact.mentorDetails.certificateDate || null,
                    certificateArea: contact.mentorDetails.certificateArea || null,
                    letterOfRecommendation: contact.mentorDetails.letterOfRecommendation,
                    districtTraining: contact.mentorDetails.districtTraining,
                    providedBy: contact.mentorDetails.providedBy || null,
                    totTrainingSent: contact.mentorDetails.totTrainingSent,
                    address1: contact.mentorDetails.address1 || null,
                    address2: contact.mentorDetails.address2 || null,
                    city: contact.mentorDetails.city || null,
                    stateId: contact.mentorDetails.stateId || null,
                    postalCode: contact.mentorDetails.postalCode || null
                }
            });
        }
        else {
            // set default values for mentor details
            this.contactForm.patchValue({
                mentorDetails: {
                    teachingCertificate: null,
                    certificateDate: null,
                    certificateArea: null,
                    letterOfRecommendation: null,
                    districtTraining: null,
                    providedBy: null,
                    totTrainingSent: null,
                    address1: null,
                    address2: null,
                    city: null,
                    stateId: null,
                    postalCode: null
                }
            });
        }


        this.editMode = true;
        this.isNewContact = false;

        this.showGrantAccessToDistrict = contact.contactTypeID === DistrictContactTypeValue.HumanResources && this.contactComponentType === ContactComponentType.DistrictContact;
        this.contactSelectedForEditing = contact;

    }

  async saveEdit() {
    const formData = this.contactForm.getRawValue();
    formData.id = this.campusContactId;
    formData.mentorDetails.campusContactId = this.campusContactId;
    formData.entityID = this.campusId;
    if (formData.mentorDetails.teachingCertificate === "") {
        formData.mentorDetails.teachingCertificate = null;
    }
    if (formData.mentorDetails.districtTraining === "") {
        formData.mentorDetails.districtTraining = null;
    }
    if (formData.mentorDetails.letterOfRecommendation === "") {
        formData.mentorDetails.letterOfRecommendation = null;
    }
    if (formData.mentorDetails.totTrainingSent === "") {
        formData.mentorDetails.totTrainingSent = null;
    }
    if (formData.mentorDetails.certificateDate === "") {
        formData.mentorDetails.certificateDate = null;
    }
    if (formData.mentorDetails.stateId === "") {
        formData.mentorDetails.stateId = null;
    }
    if (formData.hasAccessToDistrictPortal === null) {
        formData.hasAccessToDistrictPortal = false;
    }

        if (formData.hasAccessToDistrictPortal === true) {
            this.authService.getAccessToken().then(token => {
                formData.accessToken = token;
            });
        }

        // Validating data from the form field before populating the model
        if (formData.stateLicenseID != '' || formData.stateLicenseID != null) {
            formData.mentorDetails.stateLicenseID = formData.stateLicenseID;
        }

        if (this.contactForm.valid) {
            this.showSpinner = true;

            var [currPrincipal, needsConfirmation] = this.isUniquePrincipalViolation();

            if (this.isNewContact) {
                formData.createUser = this.authService.user.displayName;
                if (needsConfirmation == true) {
                    const canContinue = await this.openOverwritePrincipalConfirmDialog(currPrincipal as Contact, needsConfirmation as boolean);
                    if (!canContinue) {
                        this.showSpinner = false;
                        this.openSnackBar('New contact was not saved.', null);
                        this.editMode = false;
                        return;
                    }
                    this.deladdContactOnServer(formData, currPrincipal as Contact);
                } else {
                    this.addContactOnServer(formData);
                }
            } else {
                formData.modUser = this.authService.user.displayName;
                if (needsConfirmation == true) {
                    const canContinue = await this.openOverwritePrincipalConfirmDialog(currPrincipal as Contact, needsConfirmation as boolean);
                    if (!canContinue) {
                        this.showSpinner = false;
                        this.openSnackBar('Contact was not saved.', null);
                        this.editMode = false;
                        return;
                    }
                    this.delupdContactOnServer(formData, currPrincipal as Contact);
                } else {
                    this.updContactOnServer(formData);
                }
            }
        }

    }

    close() {
        this.cancelEvent.emit();
    }

    delupdContactOnServer(formData: Contact, currPrincipal: Contact) {
        this.contactToDelete = null;
        this.showSpinner = true;

        this.service.deleteContact(currPrincipal.id).toPromise().then((result) => {
            const contactDeleted = result;
            if (contactDeleted) {
                const contactIndex = this.contactList.findIndex(c => c.id === currPrincipal.id);
                this.contactList.splice(contactIndex, 1);
                this.openSnackBar('Contact was deleted.', null);
                this.updContactOnServer(formData);
            }
            else {
                this.openSnackBar('Error: Contact was not deleted.', null);
            }
        })
            .catch(error => {
                this.showSpinner = false;
                this.openSnackBar('Error: Contact was not deleted.', null);
            });

        this.showSpinner = false;
        this.editMode = false;
        this.isNewContact = false;
    }

    updContactOnServer(formData: Contact) {
        this.showSpinner = true;
        this.service.updateContact(formData).toPromise()
            .then((result) => {
                this.showSpinner = false;
                this.updatedContact = result;
                this.openSnackBar('Contact was updated.', null);
                this.editMode = false;
                this.isNewContact = false;

                this.updateContact.emit(this.updatedContact);
            })
            .catch(error => {
                this.showSpinner = false;
                this.openSnackBar('Error: Contact was not updated.', null);
            });
    }

    deladdContactOnServer(formData: Contact, currPrincipal: Contact) {
        this.contactToDelete = null;
        this.showSpinner = true;

        this.service.deleteContact(currPrincipal.id).toPromise().then((result) => {
            const contactDeleted = result;
            if (contactDeleted) {
                const contactIndex = this.contactList.findIndex(c => c.id === currPrincipal.id);
                this.contactList.splice(contactIndex, 1);
                this.openSnackBar('Contact was deleted.', null);
                this.addContactOnServer(formData);
            }
            else {
                this.openSnackBar('Error: Contact was not deleted.', null);
            }
        })
            .catch(error => {
                this.showSpinner = false;
                this.openSnackBar('Error: Contact was not deleted.', null);
            });

        this.showSpinner = false;
        this.editMode = false;
        this.isNewContact = false;
    }

    addContactOnServer(newContact: Contact) {
        this.service.addContact(newContact).toPromise().then((result) => {
            this.showSpinner = false;
            const oldContactList = this.contactList;
            this.contactList = result;

            const savedContact = this.contactList.find(c => c.emailAddress === newContact.emailAddress);

            if (this.pendingUploadsExist() && newContact.contactTypeID === CampusContactType.Mentor) { //mentor contact type
                // keep form open until pending uploads have completed
                this.savedContact = savedContact;
                this.campusContactId = savedContact.mentorDetails?.campusContactId; // trigger pending uploads
            }
            else {
                this.openSnackBar('New contact was saved.', null);
                this.editMode = false;
                this.isNewContact = false;
                this.saveNewContact.emit(savedContact);
            }
        })
            .catch(error => {
                this.showSpinner = false;
                this.openSnackBar('Error: New contact was not saved.', null);
            });
    }

    addContact(defaultContactTypeId: number | null = null) {
        this.teachingCertificateDocumentsAvailable = false;
        this.proofOfAccomplishmentDocumentsAvailable = false;
        this.districtTrainingDocumentsAvailable = false;
        this.contactSelectedForEditing = null;
        this.campusContactId = 0;
        this.updateFirstName = '';
        this.updateLastName = '';
        this.contactForm.setValue({
            contactTypeID: defaultContactTypeId,
            firstName: this.campusContactEditor?.firstName ?? '',
            lastName: this.campusContactEditor?.lastName ?? '',
            title: '',
            emailAddress: this.campusContactEditor?.email ?? '',
            phoneNumber: '',
            hasAccessToDistrictPortal: null,
            stateLicenseID: this.campusContactEditor?.teaId ?? '',
            mentorDetails: {
                teachingCertificate: null,
                certificateDate: null,
                certificateArea: null,
                letterOfRecommendation: null,
                districtTraining: null,
                providedBy: null,
                totTrainingSent: null,
                address1: null,
                address2: null,
                city: null,
                stateId: null,
                postalCode: null
            }
        });
        this.contactForm.markAsPristine;
        this.editMode = true;
        this.isNewContact = true;
    }

    isUniquePrincipalViolation() {
        var retval: boolean = false;
        var currPrincipal: Contact = null;

        const formData = <Contact>this.contactForm.value;
        var principalTypes = this.contactTypes.filter(it => it.description == 'Principal');
        if (principalTypes.length > 0) {
            var principalTypeId = principalTypes[0].id;
            if (formData.contactTypeID == principalTypeId) {
                var principalList = this.contactList
                    .filter(it => it.contactTypeID == principalTypeId)
                    .sort((a, b) => ((new Date(a.effectiveBegin)).getTime() - (new Date(b.effectiveBegin)).getTime()) / 1000);
                if (principalList.length > 0 && formData.id != principalList[0].id) {
                    retval = true;
                    currPrincipal = principalList[0];
                }
            }
        }

        return [currPrincipal, retval];
    }

    async openOverwritePrincipalConfirmDialog(currPrincipal: Contact, needsConfirmation: boolean): Promise<boolean> {
        var retval = true;
        if (needsConfirmation) {
            var principalName = `${currPrincipal.firstName} ${currPrincipal.lastName}`;

            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                width: '400px',
                maxWidth: '400px',
                data: {
                    title: 'Overwrite Existing Principal?',
                    message: `Overwrite existing principal ${principalName}?`
                }
            });

            retval = await dialogRef.afterClosed().toPromise().then(result => {
                return Promise.resolve(result);
            });
        } else {
            retval = false;
        }

        return Promise.resolve(retval);
    }

    openSnackBar(data: string, status: string, durationSeconds = 3) {
        this.snackBar.open(data, status, {
            duration: durationSeconds * 1000,
        });
    }

    get showAccessGrantedDate() {
        return this.contactSelectedForEditing ? this.contactSelectedForEditing.hasAccessToDistrictPortal : false;
    }

    get showWarningForRemovingAccessForDistrictPortal() {
        return this.contactSelectedForEditing ? this.contactSelectedForEditing.hasAccessToDistrictPortal && this.contactForm.controls.hasAccessToDistrictPortal.value === false : false;
    }

    get hasDistrictTraining(): boolean {
        return this.contactForm.get('mentorDetails.districtTraining').value !== null;
    }
}
