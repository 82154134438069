// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  padding: 20px;
}

mat-form-field {
  width: 100%;
  margin-bottom: 10px;
}

.narrow-field {
  width: 250px;
}

#saveButton {
  width: 130px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1lbnRvci1jb250YWN0LWFkZC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGFBQUE7QUFDSjs7QUFFQTtFQUNJLFdBQUE7RUFDQSxtQkFBQTtBQUNKOztBQUVBO0VBQ0ksWUFBQTtBQUNKOztBQUVBO0VBQ0ksWUFBQTtBQUNKIiwiZmlsZSI6Im1lbnRvci1jb250YWN0LWFkZC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jb250YWluZXIge1xuICAgIHBhZGRpbmc6IDIwcHg7XG59XG5cbm1hdC1mb3JtLWZpZWxkIHtcbiAgICB3aWR0aDogMTAwJTtcbiAgICBtYXJnaW4tYm90dG9tOiAxMHB4O1xufVxuXG4ubmFycm93LWZpZWxke1xuICAgIHdpZHRoOiAyNTBweDtcbn1cblxuI3NhdmVCdXR0b257XG4gICAgd2lkdGg6IDEzMHB4O1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/intern/mentor-contact-add/mentor-contact-add.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACI,WAAA;EACA,mBAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,YAAA;AACJ;AACA,4mBAA4mB","sourcesContent":[".container {\n    padding: 20px;\n}\n\nmat-form-field {\n    width: 100%;\n    margin-bottom: 10px;\n}\n\n.narrow-field{\n    width: 250px;\n}\n\n#saveButton{\n    width: 130px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
