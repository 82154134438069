import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { InternSearchComponent } from './intern/intern-search/intern-search.component';

const routes: Routes = [
  {
    path: '', component: InternSearchComponent, canActivate: [MsalGuard]
  },
  {
    path: 'auth', component: InternSearchComponent, canActivate: [MsalGuard]
  },
  {
    // Needed for hash routing
    path: 'code',
    component: InternSearchComponent, canActivate: [MsalGuard]
  }, 
  {
    path: 'intern', loadChildren: () => import('./intern/intern.module').then(m => m.InternModule)
  },
  {
    path: 'home', component: InternSearchComponent, canActivate: [MsalGuard]
  },
  {
    path: 'intern-search', component: InternSearchComponent, canActivate: [MsalGuard]
  },
  {
    path: 'advisor', loadChildren: () => import('./advisor-common.module').then(m => m.AdvisorCommonModule)
  }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, { 
    useHash: true, 
    // Don't perform initial navigation in iframes
    initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled'
})],
exports: [RouterModule]
})
export class AppRoutingModule { }
