import { Component, Input, SimpleChanges, inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ContractService } from "src/app/services/contract.service";
import { CertAreaDTO } from "src/app/shared/models/certArea.model";
import { DialogService } from "../../../services/dialog.service";
import { EmploymentDocumentsService } from 'src/app/shared/services/employment-documents.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmploymentDocumentTypeEnum } from 'src/app/shared/enums/employment-document-type-enum';
import { saveAs } from 'file-saver';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; 

@Component({
  selector: "app-rl-dialog",
  templateUrl: "./rl-dialog.component.html",
  styleUrls: ["./rl-dialog.component.scss"],
})
export class RlDialogComponent {

  private dialogService = inject(DialogService);

  @Input() profile: any;

  profileId: number;
  availableCertAreas: CertAreaDTO[];
  selectedYear: string = "1";
  selectedCertAreaId: number;
  formGroup: FormGroup;
  isDownloading: boolean;

  constructor(
    private contractSvc: ContractService,
    public dialogRef: MatDialogRef<RlDialogComponent>,
    private employmentDocumentsService: EmploymentDocumentsService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder
  ) {
    this.availableCertAreas = [];
    
    // Initialize the form group
    this.formGroup = this.fb.group({
      year: this.selectedYear,
      certArea: ['', Validators.required]
    });
  }

  ngOnInit() {

    this.dialogService.currentProfile.subscribe(profile => {
      this.getCertArea(profile.profileId);
      this.profileId = profile.profileId;
    });

    this.formGroup.get('year').valueChanges.subscribe(value => {
      this.selectedYear = value;
    });

    this.formGroup.get('certArea').valueChanges.subscribe(value => {
      this.selectedCertAreaId = value;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.profile && changes.item.currentValue) {
      this.profileId = this.profile.profileId;
    }
  }

  getCertArea(profileId: number) {
    this.contractSvc.GetSmkCertAreas(profileId).subscribe((data) => {
      this.availableCertAreas = data;
      if (this.availableCertAreas.length === 0) {
        this.formGroup.get('certArea').disable();
      }
    });
  }

  isDownloadDisabled(): boolean {
    return !this.availableCertAreas || this.availableCertAreas.length == 0;
  }

  onDownloadClicked() {
    this.formGroup.get('certArea').markAsTouched();
    this.formGroup.get('certArea').updateValueAndValidity();
  
    if (this.formGroup.invalid) {
      this.snackBar.open('Please select a Cert Area', '', {
        duration: 3000
      });
      return;
    }
  
    const fileName = 'Teachers_of_Tomorrow_RL_Form.pdf';
    this.isDownloading = true;
    this.employmentDocumentsService.getDocumetnByTypeAndVersion(this.profileId, EmploymentDocumentTypeEnum.FormRL, this.selectedCertAreaId, this.selectedYear)
      .subscribe(
        (blob) => {
          saveAs(blob, fileName);
          this.isDownloading = false;
        },
        (error) => {
          this.snackBar.open(`Form RL for year ${this.selectedYear} could not be downloaded. Please contact us.`, '', {
            duration: 5000
          });
          this.isDownloading = false;
        }
      );
  }
}
