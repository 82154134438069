import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, shareReplay } from 'rxjs/operators';
import { EMPTY, throwError } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class EmploymentDocumentsService {
    apiUrl = '';
  constructor(private httpclient: HttpClient,
    private configService: ConfigService) { 

        this.apiUrl = this.configService.getApiUrl();;
    }

    public getDocumetnByTypeAndVersion(profileId: number, documentTypeID: number, certArea: number, documentVersion?: string) {
        let downloadURL = `${this.apiUrl}/documents?profileId=${profileId}&documentTypeId=${documentTypeID}&certAreaId=${certArea}`;
    
        if (documentVersion) {
          downloadURL += `&document-version=${documentVersion}`;
        }
    
        const headers = new HttpHeaders({ 'Accept': 'application/pdf' });
    
        return this.httpclient.get<any>(downloadURL, { headers: headers, responseType: 'blob' as 'json' }).pipe(
          catchError(error => {
            console.log(error);
            return EMPTY;
          }),
          shareReplay()
        );
      }
}
