import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { InternService } from '../../services/intern.service';
import { Intern } from '../../shared/models/intern.model';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

const datePipe = new DatePipe('en-US');

@Component({
  selector: 'app-intern-search',
  templateUrl: './intern-search.component.html',
  styleUrls: ['./intern-search.component.scss']
})
export class InternSearchComponent implements OnInit, AfterViewInit {

  @ViewChild('quickSearch', { static: true }) quickSearch: ElementRef<HTMLInputElement>;
  @ViewChild('internTable', { static: true }) internTable: MatTable<any>;
  interns: Intern[] = [];
  displayedColumns: string[] = ['internNumber', 'lastName', 'firstName', 'emailAddress', 'college', 'campus', 'district', 'status', 'city', 'state', 'dob', 'ssn'];
  recentlyClickedInterns: Intern[] = [];
  _searchTerm: string;
  phoneNumberFromRoute: string;
  isLoading: boolean;

  public constructor(private internService: InternService,
    private router: Router,
    private route: ActivatedRoute,
    private changeDetecterRef: ChangeDetectorRef,
    private authService: AuthService,

  ) { }

  ngOnInit() {

    this.authService.reload()
      .then(
        (user) => { if (!user) { this.router.navigate(['auth']); } }
      )
      .catch(error => console.error(error));

    // fetch the phone number from query params
    this.phoneNumberFromRoute = this.route.snapshot.queryParamMap.get('phoneNumber');

    // if phone number exists, perform search on phone number and display search results
    if (this.phoneNumberFromRoute != null && this.phoneNumberFromRoute != undefined) {
      this.searchTerm = this.formatPhoneNumber(this.phoneNumberFromRoute);
      this.isLoading = true;

      this.internService.search(this.searchTerm).subscribe(interns => {
        this.onGetSuccess(interns);
        this.isLoading = false;
      });
    }

    //Setting event for automatic search
    fromEvent(this.quickSearch.nativeElement, 'input').pipe( // Listen for (input) event
      map((event: any) => {
        return event.target.value;
      }),
      debounceTime(1000), // Debounce 1 second
      distinctUntilChanged()
    ).subscribe((searchTerm: string) => {
      delete this.interns;
      this.searchTerm = searchTerm.replace(/ +(?= )/g, '').trim(); // Remove adjacent whitespace. Trim.
      if (this.searchTerm.length > 2) {
        this.internService.search(this.searchTerm).subscribe(interns => this.onGetSuccess(interns));
      }
    });

    if (localStorage.getItem('recentlyClickedInterns')) { // Fetch most recently clicked Interns
      this.recentlyClickedInterns = this.interns = JSON.parse(localStorage.getItem('recentlyClickedInterns')) as Intern[];
    }
  }

  ngAfterViewInit() {
    this.quickSearch.nativeElement.focus();
    this.changeDetecterRef.detectChanges();
  }

  formatPhoneNumber(phoneNumberString) {
    //Filter only numbers from the input
    let phoneNumber = ('' + phoneNumberString).replace(/\D/g, '');

    //Check if the input is of correct length
    let result = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (result) {
      return '(' + result[1] + ') ' + result[2] + '-' + result[3]
    };

    return null;
  };

  get searchTerm() {
    return this._searchTerm;
  }

  set searchTerm(value: string) {
    this._searchTerm = value;
  }

  onGetSuccess(interns: Intern[]) {
    for (var i = 0; i < interns.length; ++i) {
      if (interns[i].dob) {
        interns[i].dob = datePipe.transform(new Date(interns[i].dob), 'MM/dd/y');
      }
    }

    this.interns = interns;
    this.internTable.renderRows();
  }

  getSegments(value: any): Segment[] {
    let segments = [{ text: value ? String(value) : '' }];

    if (value && this.searchTerm) {
      let words = this.searchTerm.split(' ');
      for (let word of words) {
        segments = this.breakSegments(segments, word);
      }
    }

    return segments;
  }

  breakSegments(segments: Segment[], word: string) {
    let returnedSegments: Segment[] = [];
    let re = new RegExp(word, 'gi');

    for (let segment of segments) {

      if (segment.highlight) {
        returnedSegments.push(segment);
      } else {

        // Replace all occurences of word with a bell character
        let step1 = segment.text.replace(re, '␇');

        // Captured groups preserving position of non-matches
        let captures = step1.match(/([^␇]*)/g);

        captures.pop(); //Remove last element

        for (let capture of captures) {
          if (capture) {
            returnedSegments.push({ text: capture }); // TODO: Known issue - original casing of text is not preserved
          } else {
            returnedSegments.push({ text: word, highlight: true });
          }
        }
      }
    }

    return returnedSegments;
  }

  click(row: Intern) {
    // If item already exists, remove existing item from array. Push it to the front of the array.
    if (this.recentlyClickedInterns.find(r => r.id == row.id)) {
      this.recentlyClickedInterns = this.recentlyClickedInterns.filter(r => r.id != row.id);
    } else {
      if (this.recentlyClickedInterns.length == 50) { // If array has 50 items, remove the oldest item.
        this.recentlyClickedInterns.pop();
      }
    }

    this.recentlyClickedInterns.unshift(row);
    localStorage.setItem('recentlyClickedInterns', JSON.stringify(this.recentlyClickedInterns));
    this.router.navigate(['intern/', row.internId]);
  }
}

interface Segment {
  text: string;
  highlight?: boolean;
}
