import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  messages: string[];
  buttonText: any;

  constructor(
    public dialogRef: MatDialogRef<AlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {    
    this.messages = this.data.messages;
    this.buttonText = !!this.data.buttonText ? this.data.buttonText : 'OK';
  }

  onButtonClicked(): void{
    this.dialogRef.close();
  }

}
