import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Campus } from '../../models/campus.model';
import { OnlineSOE } from '../../models/online-soe.model';
import { CampusContactEditor } from '../../models/campus-contact-editor.model';
import { CampusSupervisorAssignment } from '../../models/campus-supervisor-assignment.model';
import { CampusSupervisor } from '../../models/campus-supervisor.model';
import { CampusSupervisorAssignmentEditorInputData } from '../../models/campus-supervisor-assignment-editor-input-data.model';
import { CampusSupervisorContactsComponent } from 'src/app/campus-supervisor-contacts/campus-supervisor-contacts.component';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CampusService } from 'src/app/services/campus.service';
import { CampusSupervisorService } from 'src/app/services/campus-supervisor.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelectionListChange } from '@angular/material/list';
import { ContactComponentType } from '../../enums/contact-component-type.enum';
import { CampusContactType } from '../../enums/campus-contact-type.enum';
import { Contact } from '../../models/contact.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CampusSupervisorAssignmentComponent } from 'src/app/intern/intern-details/observations/campus-supervisor/campus-supervisor-assignment.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { EmploymentStatusEnum } from '../../models/employment.model';
import { Result } from '../../models/result.model';

@Component({
  selector: 'app-campus-supervisor-assignment-editor',
  templateUrl: './campus-supervisor-assignment-editor.component.html',
  styleUrls: ['./campus-supervisor-assignment-editor.component.scss'],
  animations: [
    trigger('expandAnimation', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('collapsed <=> expanded',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CampusSupervisorAssignmentEditorComponent {

  campusSupervisorAssignmentEditorForm: UntypedFormGroup;
  campusSupervisorAssignments: CampusSupervisorAssignment[] = [];
  campusSupervisorAssignmentEditorExpanded: boolean = false;
  campusSupervisorsListColumns: string[] = ['Campus', 'Name', 'Email', 'StartDate', 'EndDate', 'EndAssignment'];
  expandedCampusSupervisorAssignmentRow: boolean = false;
  loadingCampusSupervisorAssignments: boolean = false;
  campusSupervisorAssignmentSource: any;
  campusSupervisorList: CampusSupervisor[] = [];
  selectedCampusSupervisor: CampusSupervisor;
  selectedCampus: Campus;
  campusSupervisorListLoaded = false;
  saving: boolean = false;
  campusContact = new CampusContactEditor();
  editingContact: boolean = false;
  editingAssignedCampusSupervisor: boolean = false;
  campusSupervisorAssignmentExpandIndex: number = 0;
  campusSupervisorAssignmentEditorOpen: boolean = false;
  profileId: number;
  onlineSOE: OnlineSOE;
  disableCampusSupervisorAssignment: boolean = false;
  assignCampusSupervisorButtonPosition: string;
  userRoleAllowedToModifyCampusSupervisorAssignments: boolean = false;
  hasValidEmployment: boolean = false;
  
  @Input() passedinData: CampusSupervisorAssignmentEditorInputData;
  @ViewChild(CampusSupervisorContactsComponent, { static: true }) campusSupervisorContactNoteComponent: CampusSupervisorContactsComponent;
  @Output() campusSupervisorAssignmentsLoaded = new EventEmitter<CampusSupervisorAssignment[]>();

  constructor(
    private campusSupervisorService: CampusSupervisorService,
    private campusService: CampusService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,) { }

  ngOnInit() {    
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.passedinData && changes.passedinData.currentValue) {
      this.selectedCampus = this.passedinData.campus;
      this.profileId = this.passedinData.profileId;
      this.assignCampusSupervisorButtonPosition = this.passedinData.assignCampusSupervisorButtonPosition;
      this.userRoleAllowedToModifyCampusSupervisorAssignments = this.passedinData.userRoleAllowedToModifyCampusSupervisorAssignments;
      
      let employment = this.passedinData.employment;
      this.hasValidEmployment = employment != null && 
                               (employment.EmploymentEndDate == null || employment.EmploymentEndDate > new Date()) &&
                               (employment.EmploymentStatusId === EmploymentStatusEnum.Approved || 
                                employment.EmploymentStatusId === EmploymentStatusEnum.Pending);

      if (this.hasValidEmployment) {
        this.initializeForm();
  
        if (this.selectedCampus) {
          this.setupCampusData(this.selectedCampus);
        }
        
        if (this.profileId != null && this.campusSupervisorAssignments.length === 0) {
          this.getCampusSupervisorAssignments(this.profileId);
        }
  
        if (this.campusSupervisorAssignmentEditorOpen && this.editingContact) {
          this.cancelContactEditor(0);
        }
      }
    }
  }

  initializeForm() {

    this.campusSupervisorAssignmentEditorForm = new UntypedFormGroup({
      campusSupervisor: new UntypedFormControl('', Validators.required),
      assignmentStartDate: new UntypedFormControl(new Date(), Validators.required)
    });
  }

  getCampusSupervisorAssignments(profileId: number) {
    
    this.loadingCampusSupervisorAssignments = true;

    this.campusSupervisorService.getCampusSupervisorAssignmentsForProfile(profileId).subscribe(campusSupervisors => {
    this.loadingCampusSupervisorAssignments = false;
    this.campusSupervisorAssignments = campusSupervisors;

      this.campusSupervisorAssignments?.sort((a: CampusSupervisorAssignment, b: CampusSupervisorAssignment) => {
        return +new Date(b.AssignedOn) - +new Date(a.AssignedOn);
      });

      // Iterate over assignments to set the index and validate EndDate
      for (let i = 0; i < this.campusSupervisorAssignments?.length; i++) {
        var csa = this.campusSupervisorAssignments[i];
        csa.Index = i + 1;
        // Reset the EndDate to null if it exceeds the maximum allowed date (December 31, 2199)
        csa.EndDate = new Date(csa.EndDate) > new Date(2199, 12, 31) ? null : csa.EndDate;
      }

    this.campusSupervisorAssignmentSource = new MatTableDataSource(this.campusSupervisorAssignments);

      if (this.expandedCampusSupervisorAssignmentRow) {
        this.cancelCampusSupervisorAssignment();
        this.addNewCampusSupervisorAssignment();
      }
      this.campusSupervisorAssignmentsLoaded.emit(this.campusSupervisorAssignments);
    });
  }

  addNewCampusSupervisorAssignment() {
    if (!this.editingAssignedCampusSupervisor && (this.campusSupervisorAssignments?.length === 0 || (this.campusSupervisorAssignments && this.campusSupervisorAssignments[0].Index != 0))) {
      this.campusSupervisorAssignments.unshift({
        CampusSupervisorAssignmentId: 0,
        ProfileID: 0,
        FirstName: '',
        LastName: '',
        AssignedOn: null,
        EndDate: null,
        LastUpdatedOn: null,
        LastUpdatedBy: null,
        CreatedBy: '',
        CreatedOn: null,
        IsDeleted: false,
        Index: 0,
        CampusName: this.selectedCampus?.Name,
        EmailAddress: '',
        CampusSupervisorId: 0,
        CampusSupervisorTypeId: 0
      });
    }
    this.campusSupervisorAssignmentSource = new MatTableDataSource(this.campusSupervisorAssignments);

    this.campusSupervisorAssignmentEditorForm?.reset();
    this.expandedCampusSupervisorAssignmentRow = true;
    this.campusSupervisorAssignmentEditorOpen = true;

    if (!!this.selectedCampus) {
      this.getCampusSupervisors(this.selectedCampus);
    }
  }

  setupCampusData(campus: Campus) {
    this.selectedCampusSupervisor = new CampusSupervisor();
    this.campusSupervisorListLoaded = false;
    this.campusSupervisorAssignmentEditorForm?.reset();
    if (!!campus && this.expandedCampusSupervisorAssignmentRow) {
      this.getCampusSupervisors(campus);
    }
  }

  getCampusSupervisors(campus: Campus): any {
    if (!campus) {
      return;
    }

    this.campusSupervisorService.getCampusSupervisorsForCampus(campus.Id).subscribe((result: Result) => {
        this.campusSupervisorListLoaded = true;
        this.campusSupervisorList = result.returnObj as CampusSupervisor[];
    },
    (errorObject) => {
      this.campusSupervisorListLoaded = true;
      if (errorObject.error && errorObject.error.userMsg) {
        this.openSnackBar(errorObject.error.userMsg, "Ok");
      }
      this.openSnackBar('Error: Campus Supervisors could not be loaded.', "Ok");
    });
  }

  onCampusSupervisorSelectionChanged(listChange: MatSelectionListChange) {
    let selectedIds: number[] = this.campusSupervisorAssignmentEditorForm?.controls?.campusSupervisor?.value;
    let supervisorId = selectedIds[0];

    this.selectedCampusSupervisor = this.campusSupervisorList.find(supervisor => supervisor.id == supervisorId);

    // Populate the first empty row with selected supervisor details; assign today's date to Start date
    this.campusSupervisorAssignments[0].CampusName = this.selectedCampus?.Name;
    this.campusSupervisorAssignments[0].FirstName = this.selectedCampusSupervisor.firstName;
    this.campusSupervisorAssignments[0].LastName = this.selectedCampusSupervisor.lastName;
    this.campusSupervisorAssignments[0].EmailAddress = this.selectedCampusSupervisor.emailAddress;

    this.campusSupervisorAssignmentEditorForm?.get('assignmentStartDate')?.setValue(new Date());
    this.campusSupervisorAssignments[0].AssignedOn = new Date();
  }

  cancelCampusSupervisorAssignment() {
    this.expandedCampusSupervisorAssignmentRow = false;
    if (this.campusSupervisorAssignments[0].Index == 0) {
      this.campusSupervisorAssignments.splice(0, 1);
    }
    this.campusSupervisorAssignmentSource = new MatTableDataSource(this.campusSupervisorAssignments);
    this.selectedCampusSupervisor = new CampusSupervisor();
    this.editingAssignedCampusSupervisor = false;
    this.campusSupervisorAssignmentEditorOpen = false;
  }

  shouldShowRow(index: number): boolean {
    return index === this.campusSupervisorAssignmentExpandIndex;
  }

  editCampusSupervisor(campusSupervisor: CampusSupervisor) {

    this.campusContact = new CampusContactEditor();
    this.campusContact.campusId = this.selectedCampus?.Id;
    this.campusContact.campusContactId = campusSupervisor.id,
    this.campusContact.campusContactName = campusSupervisor.displayName,
    this.campusContact.service = this.campusService;
    this.campusContact.contactComponentType = ContactComponentType.CampusContact;
    this.campusContact.campusContactType = CampusContactType.CampusSupervisor;
    this.campusContact.editMode = true;
    this.campusContact.disabledContactType = true;
    this.campusContact.formHeight = '339px';

    this.editingContact = true;
  }

  updateContact(updatedContact: Contact) {
    this.updateAssignedCampusSupervisor(updatedContact);
    this.updateCampusContact(updatedContact);

    if (this.expandedCampusSupervisorAssignmentRow) {
      if (this.campusSupervisorAssignments[0] != null && this.campusSupervisorAssignments[0].FirstName != '') {
        this.campusSupervisorAssignments[0].FirstName = updatedContact.firstName;
        this.campusSupervisorAssignments[0].LastName = updatedContact.lastName;
        this.campusSupervisorAssignments[0].EmailAddress = updatedContact.emailAddress;
      }
    }
  }

  updateCampusContact(updatedContact: Contact) {
    if (this.expandedCampusSupervisorAssignmentRow) {
      const contactIndex = this.campusSupervisorList.findIndex(supervisor => supervisor.id === updatedContact.id);
      var supervisor = this.campusSupervisorList[contactIndex];
      supervisor.displayName = updatedContact.firstName + ' ' + updatedContact.lastName;
      supervisor.firstName = updatedContact.firstName;
      supervisor.lastName = updatedContact.lastName;
      supervisor.emailAddress = updatedContact.emailAddress;
      supervisor.title = updatedContact.title;
      supervisor.phoneNumber = updatedContact.phoneNumber;

      this.campusSupervisorList.splice(contactIndex, 1, supervisor);
    }

    else {
      this.campusSupervisorAssignmentEditorExpanded = false
    }

    this.campusSupervisorAssignmentExpandIndex = 0;
    this.editingContact = false;
  }

  updateAssignedCampusSupervisor(updatedContact: Contact) {
    if (!updatedContact) {
      return;
    }

    this.campusSupervisorAssignments.forEach((supervisorAssignment) => {
      if (supervisorAssignment.CampusSupervisorId === updatedContact.id) {
        supervisorAssignment.FirstName = updatedContact.firstName;
        supervisorAssignment.LastName = updatedContact.lastName;
        supervisorAssignment.EmailAddress = updatedContact.emailAddress;
        var actualIndex = this.campusSupervisorAssignments.findIndex(sa => sa.Index === supervisorAssignment.Index);
        this.campusSupervisorAssignments.splice(actualIndex, 1, supervisorAssignment);
      }
    });

    if (!this.campusSupervisorAssignmentEditorOpen) {
      this.cancelCampusSupervisorAssignment();
    }

    this.editingContact = false;
  }

  editAssignedCampusSupervisor(supervisorAssignment: CampusSupervisorAssignment) {
    this.campusContact = new CampusContactEditor();
    this.campusContact.campusId = this.selectedCampus?.Id;
    this.campusContact.campusContactId = supervisorAssignment.CampusSupervisorId,
      this.campusContact.campusContactName = supervisorAssignment.FirstName + ' ' + supervisorAssignment.LastName,
      this.campusContact.service = this.campusService;
    this.campusContact.contactComponentType = ContactComponentType.CampusContact;
    this.campusContact.campusContactType = CampusContactType.CampusSupervisor;
    this.campusContact.editMode = true;
    this.campusContact.disabledContactType = true;
    this.campusContact.formHeight = '339px';

    this.campusSupervisorAssignmentExpandIndex = supervisorAssignment.Index;
    this.editingAssignedCampusSupervisor = true;
    this.expandedCampusSupervisorAssignmentRow = true;
    this.editingContact = true;
  }

  addNewCampusSupervisor() {
    this.campusContact = new CampusContactEditor();
    this.campusContact.campusId = this.selectedCampus?.Id;
    this.campusContact.campusContactId = 0;
    this.campusContact.campusContactName = '';
    this.campusContact.service = this.campusService;
    this.campusContact.contactComponentType = ContactComponentType.CampusContact;
    this.campusContact.campusContactType = CampusContactType.CampusSupervisor;
    this.campusContact.editMode = true;
    this.campusContact.disabledContactType = true;
    this.campusContact.formHeight = '339px';

    this.editingContact = true;
  }

  cancelContactEditor(index: number) {
    this.editingContact = false;
    if (this.editingAssignedCampusSupervisor && !this.campusSupervisorAssignmentEditorOpen) {
      this.campusSupervisorAssignmentExpandIndex = index;
      this.cancelCampusSupervisorAssignment();
    }
    this.editingAssignedCampusSupervisor = false;
    this.campusSupervisorAssignmentExpandIndex = 0;
  }

  saveNewContact(newContact: Contact, index: number | null) {
    if (!newContact || !newContact.id) {
        this.campusSupervisorAssignmentEditorForm.controls.campusSupervisor.setValue([]);
        this.selectedCampusSupervisor = new CampusSupervisor();
        this.editingContact = false;
        return;
      }
      const newCampusSupervisor: CampusSupervisor =
      {
        id: newContact.id,
        displayName: newContact.firstName + ' ' + newContact.lastName,
        firstName: newContact.firstName,
        lastName: newContact.lastName,
        title: newContact.title,
        emailAddress: newContact.emailAddress,
        phoneNumber: newContact.phoneNumber,
        entityID: newContact.entityID
      };
      this.campusSupervisorList.splice(0, 0, newCampusSupervisor);

      if(this.editingAssignedCampusSupervisor) {
        this.campusSupervisorAssignmentExpandIndex = index;
        this.cancelCampusSupervisorAssignment();
      }
      this.campusSupervisorAssignmentExpandIndex = 0;

      this.editingContact = false;
  }

  async saveCampusSupervisorAssignment() {
    //Saving Campus Supervisor Assignment
    let campusSupervisorAssignmentData = new CampusSupervisorAssignment();

    campusSupervisorAssignmentData.CampusSupervisorId = (this.campusSupervisorAssignmentEditorForm?.get('campusSupervisor')?.value)[0];
    campusSupervisorAssignmentData.ProfileID = this.profileId;
    campusSupervisorAssignmentData.LastUpdatedBy = this.authService.user.displayName;
    campusSupervisorAssignmentData.CampusSupervisorTypeId = CampusContactType.CampusSupervisor;
    campusSupervisorAssignmentData.CampusSupervisorAssignmentId = null;
    campusSupervisorAssignmentData.AssignedOn = this.campusSupervisorAssignmentEditorForm?.get('assignmentStartDate')?.value;
    campusSupervisorAssignmentData.EndDate = null;
    campusSupervisorAssignmentData.CreatedBy = this.authService.user.displayName;

    //If there are no current campus supervisor assignments, assign the new campus supervisor
    if (this.campusSupervisorAssignments.length === 1 && this.campusSupervisorAssignments[0].CampusSupervisorId === 0) {
      this.saving = true;
      this.saveNewCampusSupervisorAssignment(campusSupervisorAssignmentData);
    }
    else {
      //If there is an existing campus supervisor, end the assignment and assign the new campus supervisor
      const canContinue = await this.openOverwriteCampusSupervisorConfirmDialog();

      if (!canContinue) {
        this.openSnackBar('New campus supervisor assignment was not saved.', null);
        return;
      }

      this.saving = true;

      //End the existing campus supervisor assignment and add new assignment
      this.endExistingAssignmentAndSaveNewCampusSupervisorAssignment(campusSupervisorAssignmentData, this.campusSupervisorAssignments);
    }
  }

  endExistingAssignmentAndSaveNewCampusSupervisorAssignment(formData: CampusSupervisorAssignment, currCampusSupervisorAssignments: CampusSupervisorAssignment[]) {
    currCampusSupervisorAssignments.shift();
    var needToEnd = currCampusSupervisorAssignments.filter(it => it.EndDate == null);
    needToEnd.forEach((ma) => {
      ma.EndDate = formData.AssignedOn;
    });
    this.campusSupervisorService.updateCampusSupervisorAssignmentList(currCampusSupervisorAssignments).toPromise().then((result) => {
      const itemDeleted = result;
      if (itemDeleted) {
        currCampusSupervisorAssignments.forEach((ma) => {
          const index = this.campusSupervisorAssignments.findIndex(c => c.CampusSupervisorAssignmentId === ma.CampusSupervisorAssignmentId);
          this.campusSupervisorAssignments.splice(index, 1);
        });
        this.openSnackBar('The existing Campus Supervisor Assignments were ended.', null);
        this.saveNewCampusSupervisorAssignment(formData);
      }
      else {
        this.openSnackBar('Error: The existing Campus Supervisor Assignments were not ended.', null);
      }
    })
      .catch(error => {
        console.error(error);
        this.openSnackBar('Error: The existing Campus Supervisor Assignments were not ended.', null);
      });
  }

  saveNewCampusSupervisorAssignment(formData: CampusSupervisorAssignment) {
    this.campusSupervisorService.addCampusSupervisorAssignment(formData).toPromise().then((result) => {
      if (result) {
        this.campusSupervisorAssignments.push(formData);
        //Retrieve the whole list after save since potentially other items are affected
        this.getCampusSupervisorAssignments(this.profileId)

        //Close campus supervisor assignment edit mode
        this.expandedCampusSupervisorAssignmentRow = false;

        //Clear the selected campus supervisor
        this.selectedCampusSupervisor = new CampusSupervisor();

        //Display snackbar confirmation
        this.snackBar.open('Campus supervisor assigned succesfully', 'Okay', { duration: 5000 });

      } else {
        this.openSnackBar('New campus supervisor assignment was not saved.', null);
      }
      this.saving = false;
    })
      .catch(error => {
        this.openSnackBar('Error: New campus supervisor assignment was not saved.', null);
      });
  }

  async openOverwriteCampusSupervisorConfirmDialog(): Promise<boolean> {
    var retval = true;
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      width: '400px',
      maxWidth: '400px',
      data: {
        title: 'Confirmation',
        message: `Continuing will end existing campus supervisor assignments on the new assignment's start date`
      }
    });

    retval = await dialogRef.afterClosed().toPromise().then(result => {
      return Promise.resolve(result);
    });

    return Promise.resolve(retval);
  }

  endCampusSupervisorAssignment(formData: CampusSupervisorAssignment) {
    const dialogRef = this.matDialog.open(CampusSupervisorAssignmentComponent, {
      height: '350px',
      minHeight: '350px',
      width: '400px',
      minWidth: '400px',
      data: { entity: formData, profileId: this.profileId, service: this.campusSupervisorService, assignments: this.campusSupervisorAssignments, campusId: this.selectedCampus?.Id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.openSnackBar('Campus supervisor assignment ended.', 'Okay');
        this.getCampusSupervisorAssignments(this.profileId);
      }
    });
  }

  onStartDateSelected(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value;
    this.campusSupervisorAssignments[0].AssignedOn = selectedDate;
  }

  private openSnackBar(data: string, status: string) {
    this.snackBar.open(data, status, {
      duration: 3 * 1000,
    });
  }
}
