import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalInterceptorConfiguration, MsalGuardConfiguration } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  configuration: any;
  httpClient: HttpClient;

  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler); // We cannot inject HttpClient directry because Http interceptors rely on http client. For details see https://stackoverflow.com/a/49013534/37759
  }

  getMsalConfiguration(): IPublicClientApplication {
    const auth = {
      auth: {
        clientId: "f2f3bb18-eb80-4842-b4f0-610fa3e596b8",// this.configuration.clientId,
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: false
      },
      system: {
        loggerOptions: {
          loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message);
                return;
              case LogLevel.Info:
                //console.info(message);
                return;
              case LogLevel.Verbose:
                console.debug(message);
                return;
              case LogLevel.Warning:
                console.warn(message);
                return;
            }
          },
          piiLoggingEnabled: false
        },
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 0,
        asyncPopups: false
      }
    };
    return new PublicClientApplication(auth);
  }

  public getMsalInterceptorConfiguration(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', this.scopes);
    protectedResourceMap.set(this.apiUrl, this.scopeForAPI);

    const angularConfig: MsalInterceptorConfiguration = {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: protectedResourceMap
    };
    return (angularConfig as MsalInterceptorConfiguration);
  }

  public getMsalGuardConfiguration(): MsalGuardConfiguration {
    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: this.scopes
      },
      loginFailedRoute: '/failed'
    };
  }

  public loadConfigs(): Promise<Object> {
    return this.httpClient.get<any>(window.location.origin + '/assets/config.json')
      .pipe(
        tap(config => {
          this.configuration = config;

        })
      )
      .toPromise();
  }

  getApiUrl() {
    return this.configuration.apiUrl;
  }

  getEmailServerUrl() {
    return this.configuration.EmailServerURL;
  }

  getPublicWebServerUrl() {
    return this.configuration.PublicWebServerURL;
  }

  getAuthServerUrl() {
    return this.configuration.AuthServerURL;
  }

  getCoachPortalUrl() {
    return this.configuration.CoachPortalURL;
  }

  getInternPortalUrl() {
    return this.configuration.InternPortalURL;
  }

  getPreAdmissionUrl() {
    return this.configuration.PreAdmissionURL;
  }

  get scopes() {
    return ["user.read", "email", "mail.send"];
  }

  get scopeForAPI() {
    return ["api://6a5346a4-e708-44e5-b723-bb845f2433d8/Read"];
  }

  get apiUrl() {
    return this.configuration.apiUrl;
  }

  get environment() {
    return this.configuration.environment;
  }
}
