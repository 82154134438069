import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CoachService } from 'src/app/services/coach.service';
//import { CampusSupervisorContactNoteDTO } from 'src/app/shared/models/campus-supervisor-contact-note.model';
import { CampusSupervisorAssignment } from 'src/app/shared/models/campus-supervisor-assignment.model';
import { RoleValue } from 'src/app/shared/models/role.model';
import { CampusSupervisorContactAddComponent } from '../campus-supervisor-contact-add/campus-supervisor-contact-add.component';

@Component({
    selector: 'app-campus-supervisor-contacts',
    templateUrl: './campus-supervisor-contacts.component.html',
    styleUrls: ['./campus-supervisor-contacts.component.scss']
})
export class CampusSupervisorContactsComponent implements OnInit {
    profileId: number;
    currentCampusSupervisorAssignment: CampusSupervisorAssignment = null;
    campusSupervisorContactsDataSource: any;
    campusSupervisorContactsDisplayedColumns: string[] = ['CampusSupervisorName', 'Note', 'CommunicationMethod', 'ContactDate', 'ContactedBy'];
    campusSupervisorContactsFound: boolean = false;
    loadingCampusSupervisorContacts: boolean = false;
    userRoleAllowedToAddCampusSupervisor: boolean = false;

    constructor(
        private coachSvc: CoachService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private authService: AuthService) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.profileId = params["profileId"];
            this.getCampusSupervisorContacts(this.profileId);
            this.getCurrentCampusSupervisor(this.profileId);
        });

        this.checkIfUserHasRoleToAddContact();
    }

    checkIfUserHasRoleToAddContact() {
        if (this.authService.user) {
            let userRoles = this.authService.user.roles;
            const rolesThatCanAddCampusSupervisorNotes = [RoleValue.CS_Advisor, RoleValue.CS_Advisor_Manager, RoleValue.CC_Advisor, RoleValue.CC_Manager, RoleValue.Finance_Admin];
            this.userRoleAllowedToAddCampusSupervisor = this.userIsInRole(userRoles, rolesThatCanAddCampusSupervisorNotes);
        }
    }

    getCurrentCampusSupervisor(profileId: number) {
        // this.coachSvc.getActiveCampusSupervisorAssignment(profileId).subscribe((campusSupervisorAssignment) => {
        //     this.currentCampusSupervisorAssignment = campusSupervisorAssignment;
        // });
    }

    getCampusSupervisorContacts(profileId: number) {
        this.loadingCampusSupervisorContacts = true;
        // this.coachSvc.getCampusSupervisorContacts(profileId).subscribe((campusSupervisorContacts) => {
        //     if (campusSupervisorContacts && campusSupervisorContacts.length > 0) {
        //         this.campusSupervisorContactsFound = true;
        //     }

        //     this.campusSupervisorContactsDataSource = new MatTableDataSource<CampusSupervisorContactNoteDTO>(campusSupervisorContacts);
        // },
        //     () => { },
        //     () => { this.loadingCampusSupervisorContacts = false; });
    }

    get enableAddContactLogButton() {
        return this.loadingCampusSupervisorContacts === false
            && this.currentCampusSupervisorAssignment !== null
            && this.userRoleAllowedToAddCampusSupervisor;
    }

    displayAddCampusSupervisorContactForm() {
        let dialogConfig: MatDialogConfig = {
            disableClose: false,
            autoFocus: true,
            width: '40%',
            data: {
                campusSupervisorAssignmentID: this.currentCampusSupervisorAssignment.CampusSupervisorAssignmentId,
                campusSupervisorName: `${this.currentCampusSupervisorAssignment.FirstName} ${this.currentCampusSupervisorAssignment.LastName}`
            }
        };

        let dialogRef = this.dialog.open(CampusSupervisorContactAddComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.openSnackBar('Campus Supervisor contact note saved.', 'Okay');
                this.getCampusSupervisorContacts(this.profileId);
            }
        });
    }

    openSnackBar(data: string, status: string) {
        this.snackBar.open(data, status, {
            duration: 3 * 1500,
        });
    }

    private userIsInRole(userRoles: any, allowedRoleIds: RoleValue[]) {
        const allowedStatusRoles = userRoles.filter(role => allowedRoleIds.includes(role.AuthorizationRoleID));
        return (allowedStatusRoles.length > 0);
    }

}