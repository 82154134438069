// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loadingSpinner {
  width: 150px;
  margin: auto;
}

.card-actions {
  width: 100%;
  text-align: right;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1lbnRvci1jb250YWN0cy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLFlBQUE7RUFDQSxZQUFBO0FBQ0o7O0FBR0E7RUFDSSxXQUFBO0VBQ0EsaUJBQUE7QUFBSiIsImZpbGUiOiJtZW50b3ItY29udGFjdHMuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIubG9hZGluZ1NwaW5uZXIge1xuICAgIHdpZHRoOiAxNTBweDtcbiAgICBtYXJnaW46IGF1dG87XG59XG5cblxuLmNhcmQtYWN0aW9ucyB7XG4gICAgd2lkdGg6IDEwMCU7XG4gICAgdGV4dC1hbGlnbjogcmlnaHQ7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/intern/mentor-contacts/mentor-contacts.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,YAAA;AACJ;;AAGA;EACI,WAAA;EACA,iBAAA;AAAJ;AACA,4eAA4e","sourcesContent":[".loadingSpinner {\n    width: 150px;\n    margin: auto;\n}\n\n\n.card-actions {\n    width: 100%;\n    text-align: right;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
