import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value: number, ...args: any[]): string {
      switch (value) {
          case 2: return 'Primary Phone';
          case 3: return 'Primary Fax';
          case 9: return 'Secondary Fax';
          case 5: return 'Primary Mobile';
          case 16: return 'Secondary Mobile';
          case 8: return 'Secondary Phone';
          case 13: return 'Assistant Phone';
          case 15: return 'Recurring Phone';
          case 17: return 'Tertiary Phone';
          default: return 'ERROR';
    }
  }
}
