import { CampusContactType } from "../enums/campus-contact-type.enum";
import { ContactComponentType } from "../enums/contact-component-type.enum";
import { Contact, IContactService } from "./contact.model";

export class CampusContactEditor {    
    campusId: number;
    campusContactId: number;
    campusContactName: string;
    service: IContactService;
    contactComponentType: ContactComponentType;
    campusContactType: CampusContactType;
    editMode: boolean;
    disabledContactType: boolean;
    formHeight: string;
    contactList: Contact[];
    showGrantAccessToDistrict: boolean;

    //Intended for importing eSOE mentor data
    firstName: string;
    lastName: string;
    email: string;
    teaId: string;
    contact: Contact;
}