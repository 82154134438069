import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { FieldSupervisor } from '../shared/models/fsupervisor.model';
import { retry, catchError, shareReplay } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { FsAssignment } from '../shared/models/fsAssignments';
import { ConfigService } from './config.service';
import { MentorContactNoteDTO } from '../shared/models/mentor-contact-note.model';
import { MentorContactNoteAddCommand } from '../shared/models/mentor-contact-note-add.model';
import { MentorAssignment } from '../shared/models/mentorAssignments.model';

@Injectable({
    providedIn: 'root'
})

export class CoachService {

    headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    constructor(private httpClient: HttpClient, private configService: ConfigService) { }

    public getAllAvailable() {
        return this.httpClient.get<FieldSupervisor[]>(`${this.configService.apiUrl}/FieldSupervisors/`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }


    public getAllAvailableCertByState(stateId: number) {
        return this.httpClient.get<FieldSupervisor[]>(`${this.configService.apiUrl}/FieldSupervisors/Certify/State/` + stateId).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }


    public unassign(fsAssignmentId) {
        let headersToPass = new HttpHeaders();
        headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');
        let param = new HttpParams();
        param.append("CoachAssignmentId", fsAssignmentId);

        return this
            .httpClient
            .delete(`${this.configService.apiUrl}/FieldSupervisors/Assignments/` + fsAssignmentId, { headers: headersToPass }).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public assign(fsAssignment) {
        let headersToPass = new HttpHeaders();
        headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');

        return this.httpClient.post<FsAssignment>(`${this.configService.apiUrl}/FieldSupervisors/Assignments/`, fsAssignment, { headers: headersToPass }).pipe(
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public changeAssign(fsAssignment) {
        let headersToPass = new HttpHeaders();
        headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');

        return this.httpClient.put<FsAssignment>(`${this.configService.apiUrl}/FieldSupervisors/Assignments/`, fsAssignment, { headers: headersToPass }).pipe(
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public assignList(fsAssignment) {
        let headersToPass = new HttpHeaders();
        headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');

        return this.httpClient.post<FsAssignment[]>(`${this.configService.apiUrl}/FieldSupervisors/Assignments/List/`, fsAssignment, { headers: headersToPass }).pipe(
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public unassignByList(unnassignList) {
        let headersToPass = new HttpHeaders();
        headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');
        let param = new HttpParams();
        param.append("CoachAssignmentIdList", unnassignList);

        return this
            .httpClient
            .delete(`${this.configService.apiUrl}/FieldSupervisors/Assignments?CoachAssignmentIdList=` + unnassignList, { headers: headersToPass }).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public getAssignedCoaches(profileId: number) {
        return this.httpClient.get<FsAssignment[]>(`${this.configService.apiUrl}/FieldSupervisors/GetCoachAndMentorAssignments?profileId=${profileId}`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public getMentorContacts(profileId: number) {
        return this.httpClient.get<MentorContactNoteDTO[]>(`${this.configService.apiUrl}/FieldSupervisors/GetMentorContacts/${profileId}`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public addMentorContact(mentorContactAddCommand: MentorContactNoteAddCommand) {
        return this.httpClient.post<MentorContactNoteAddCommand>(`${this.configService.apiUrl}/FieldSupervisors/AddMentorContact`, mentorContactAddCommand).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public getActiveMentorAssignment(profileId: number) {
        return this.httpClient.get<MentorAssignment>(`${this.configService.apiUrl}/FieldSupervisors/GetActiveMentorAssignment/${profileId}`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

}
