import { Component, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as FileSaver from 'file-saver';

import { AuthService } from 'src/app/services/auth.service';
import { MentorService } from 'src/app/services/mentor.service';

import { MentorObservationDocument } from '../../../../../shared/models/mentor-observation-document';

interface PendingUpload {
  file: File;
  uploading: boolean;
}

@Component({
  selector: 'mentor-observation-documents',
  templateUrl: './mentor-observation-documents.component.html',
  styleUrls: ['./mentor-observation-documents.component.scss']
})

export class MentorObservationDocumentsComponent {
  @Input() documentTypeLabel: string;
  @Input() set mentorObservationId(value: number) {
    this._mentorObservationId = value;

    if (this._mentorObservationId > 0 && this.documentPendingUpload.file != undefined) {
      this.uploadPendingDocuments();
    }
  }
  @Output() pendingUploadCompleted: EventEmitter<boolean>= new EventEmitter<boolean>(false);
  @Output() documentUploadPending: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  
  private _mentorObservationId: number;
  
  documentPendingUpload = {} as PendingUpload;
  allowedDocumentTypes: Array<string> = [".pdf", ".doc", ".docx"];
  uploading: boolean;
  loadingDocuments: boolean;
  document: MentorObservationDocument;

  constructor(
    private snackBar: MatSnackBar, 
    private mentorService: MentorService,
    private authService: AuthService
  ) { }

  private openSnackBar(data: string, status: string) {
    this.snackBar.open(data, status, {
        duration: 5 * 1000,
    });
  }

  ngOnInit(): void {
    if (this._mentorObservationId) {
      this.loadingDocuments = true;
      this.mentorService.getMentorObservationDocument(this._mentorObservationId)
      .subscribe(result => {
        this.document = result;
        this.loadingDocuments = false;
      }, err => {
        this.openSnackBar(`Unable to load mentor observation documents `, err);
      });
    } 
  }

  onFileSelected(event: any): void {

    const file = event.target.files[0];

    if (!file) return;

    if (this._mentorObservationId > 0){
      this.uploadFile(file);
    }
    else {
      this.documentPendingUpload.file = file;
      this.documentPendingUpload.uploading = false;
      this.openSnackBar(`${file.name} will upload after saving the mentor observation.`, 'Okay');
      this.documentUploadPending.next(true);
    }

  }

  uploadFile(file: File) {
    this.uploadFilePartial(file)
      .then(() => {
        this.uploading = false;
        this.openSnackBar('File successfully uploaded.', null);
      })
      .catch(error => {
        this.uploading = false;
        this.openSnackBar(`File could not be uploaded. file:${file.name}`, null);
        console.error(error);
      });
  }

  uploadFilePartial(file: File) {
    const formData = new FormData();
    formData.append("CreateUser", this.authService.user.displayName);
    formData.append("MentorObservationID", <string><unknown>this._mentorObservationId);
    formData.append("file", file, file.name);

    this.uploading = true;
    return this.mentorService.uploadObservationDocument(formData).toPromise().then((mentorObservationDocument) => {
      this.document = mentorObservationDocument;
    });
  }

  async uploadPendingDocuments() {
    if (this.uploading) return;

    this.uploading = true;

    const pendingUploadDoc = this.documentPendingUpload;
    let failedUploadFileName = "";
    
    pendingUploadDoc.uploading = true;

    try {
      await this.uploadFilePartial(pendingUploadDoc.file);
    }
    catch {
      this.openSnackBar(`File could not be uploaded. file:${pendingUploadDoc.file.name}`, null);
      failedUploadFileName = pendingUploadDoc.file.name;
    }

    this.documentPendingUpload = {} as PendingUpload;
    
    this.uploading = false;

    if (failedUploadFileName.length > 0) {
      this.openSnackBar(`File could not be uploaded. ${failedUploadFileName}`, null);
    }

    this.pendingUploadCompleted.next(true);
    this.documentUploadPending.next(false);
  }

  onDownloadFileClicked(mentorObservationDocument: MentorObservationDocument){
    this.mentorService.downloadMentorObservationDocument(mentorObservationDocument.id).toPromise()
    .then(fileContent => {
      FileSaver.saveAs(fileContent, mentorObservationDocument.filename);	
    })
    .catch(error =>{
      this.openSnackBar('The file could not be downloaded.', null);
      console.error(error);        
    });;
  }

  downloadPendingUpload(doc: PendingUpload) {
    FileSaver.saveAs(doc.file, doc.file.name);
  }

  onDeleteButtonClicked(doc: MentorObservationDocument & { isDeleting: boolean }) {
    doc.isDeleting = true;

    this.mentorService.deleteMentorObservationDocument(doc.id, this.authService.user.displayName).toPromise()
      .then(() => {
        this.document = null;
        this.openSnackBar(`${doc.filename || 'File'} successfully deleted`, 'Okay');
      })
      .catch(() => {
        this.openSnackBar(`Unable to delete ${doc.filename || 'file'} `, 'Error');
      })
      .finally(() => doc.isDeleting = false);
  }

  removePendingUpload(doc: PendingUpload) {
    this.openSnackBar(`${doc.file.name || 'File'} successfully deleted`, 'Okay');
    this.documentPendingUpload = {} as PendingUpload;
  }
}
