import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { CoachService } from 'src/app/services/coach.service';
import { MentorContactNoteAddCommand } from 'src/app/shared/models/mentor-contact-note-add.model';

@Component({
    selector: 'app-mentor-contact-add',
    templateUrl: './mentor-contact-add.component.html',
    styleUrls: ['./mentor-contact-add.component.scss']
})
export class MentorContactAddComponent implements OnInit {

    today: Date = new Date();
    mainForm: UntypedFormGroup;
    passedInData: any;
    addingInProgress: boolean = false;

    constructor(private dialogRef: MatDialogRef<MentorContactAddComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private coachService: CoachService) {
        this.passedInData = data;
    }

    ngOnInit() {
        this.initializeForm();
    }

    initializeForm() {
        this.mainForm = this.fb.group({
            mentorName: new UntypedFormControl({ value: this.passedInData.mentorName, disabled: true }),
            communicationType: ['', Validators.required],
            note: ['', Validators.required],
            contactDate: ['', Validators.required],
            contactedBy: new UntypedFormControl({ value: this.authService.user.displayName, disabled: true })
        });
    }

    addMentorContact() {

        if (this.mainForm.invalid) {
            return;
        }

        let addCommand = {
            mentorAssignmentID: this.passedInData.mentorAssignmentID,
            communicationMethodID: this.mainForm.controls.communicationType.value,
            contactDate: this.mainForm.controls.contactDate.value,
            contactedBy: this.authService.user.displayName,
            note: this.mainForm.controls.note.value
        } as MentorContactNoteAddCommand;

        this.addingInProgress = true;
        this.coachService.addMentorContact(addCommand).subscribe(
            () => { this.closeModal(true); },
            () => { },
            () => { this.addingInProgress = false; })
    }

    closeModal(changesSaved: boolean) {
        this.dialogRef.close(changesSaved);
    }

}
