import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, shareReplay } from 'rxjs/operators';
import { ConfigService } from '../../services/config.service';
import { ActiveState } from '../../shared/models/active-states.model';

@Injectable({
    providedIn: 'root'
})
export class StatesService {

    headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    apiUrl = '';

    constructor(private httpClient: HttpClient, private configSvc: ConfigService) {
        this.apiUrl = this.configSvc.getApiUrl();
    }

    public getActiveStatesList() {
        return this.httpClient.get<ActiveState[]>(`${this.apiUrl}/state/active`).pipe(
            retry(3),
            shareReplay()
        );
    }
}
