import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY } from 'rxjs';
import { catchError, retry, shareReplay } from 'rxjs/operators';
import { EmploymentHistory, IndustryCategory, CandidateSoq, SoqCategory, BasicSkill, BasicSkillType, BasicSkillSection } from '../shared/models/employmenthistory.model';

import { ConfigService } from '../services/config.service';
import { Education, DegreeTypeDTO, DegreeFieldDTO, SubjectConcentrationDTO, EducationFieldDTO } from '../shared/models/education.model';
import { State } from '../shared/models/state.model';
import { Country } from '../shared/models/country.model';

@Injectable({
    providedIn: 'root'
})
export class EmploymentService {
    
    
    headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    apiUrl = '';

    constructor(private httpClient: HttpClient, private configSvc: ConfigService) {
        this.apiUrl = this.configSvc.getApiUrl();
    }

    public GetEmploymentHistory(id: string) {
        return this.httpClient.get<EmploymentHistory[]>(`${this.apiUrl}/employmenthistory/${id}`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);

                return EMPTY;
            }),
            shareReplay()
        );
    }

    public AddEmploymentHistory(bodyFormData: EmploymentHistory) { 
        return this.httpClient.post<EmploymentHistory>(`${this.apiUrl}/EmploymentHistory/`, bodyFormData);
    }

    public UpdateEmploymentHistory(bodyFormData: EmploymentHistory) { 
        return this.httpClient.put<EmploymentHistory>(`${this.apiUrl}/EmploymentHistory/`, bodyFormData);
    }

    public DeleteWorkHistory(workHistoryId: number) {
        return this.httpClient.delete(`${this.apiUrl}/EmploymentHistory/${workHistoryId}`);
    }

    public GetIndustryCategories() {
        return this.httpClient.get<IndustryCategory[]>(`${this.apiUrl}/IndustryCategories/`).pipe(
            retry(0),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public GetCandidateSoqByID(id: string) {
        return this.httpClient.get<CandidateSoq[]>(`${this.apiUrl}/CandidateSOQ/${id}`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);

                return EMPTY;
            }),
            shareReplay()
        );
    }

    public AddCandidateSoq(candidateSOQ: CandidateSoq) {
        return this.httpClient.post<CandidateSoq>(`${this.apiUrl}/CandidateSOQ/`, candidateSOQ);
    }

    UpdateCandidateSoq(updatedCandidateSOQ: CandidateSoq) {
        return this.httpClient.put<CandidateSoq>(`${this.apiUrl}/CandidateSOQ/`, updatedCandidateSOQ)
    }

    public GetSoqCategories() {
        return this.httpClient.get<SoqCategory[]>(`${this.apiUrl}/SoqCategories/`).pipe(
            retry(0),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public GetSoqCategoriesByID(id: string) {
        return this.httpClient.get<SoqCategory[]>(`${this.apiUrl}/SoqCategories/${id}`).pipe(
            retry(0),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public GetBasicSkillsByID(id: string) {
        return this.httpClient.get<BasicSkill[]>(`${this.apiUrl}/BasicSkill/${id}`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);

                return EMPTY;
            }),
            shareReplay()
        );
    }

    public GetBasicSkillTypes() {
        return this.httpClient.get<BasicSkillType[]>(`${this.apiUrl}/BasicSkillType/`).pipe(
            retry(0),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public AddBasicSkill(bodyFormData: any) {
        return this.httpClient.post<any>(`${this.apiUrl}/BasicSkill/`, bodyFormData).pipe(
            retry(0),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public UpdateBasicSkill(updatedBasicSkill: BasicSkill) {
        return this.httpClient.put<BasicSkill>(`${this.apiUrl}/BasicSkill/`, updatedBasicSkill)
    }

    public GetBasicSkillSectionByTypeID(basicSkillTypeID: number) {
        return this.httpClient.get<BasicSkillSection[]>(`${this.apiUrl}/BasicSkillSection/${basicSkillTypeID}`).pipe(
            retry(0),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public GetCandidateDegreesByID(candidateId: string) {
        return this.httpClient.get<Education[]>(`${this.apiUrl}/Education/${candidateId}`).pipe(
            shareReplay()
        );
    }

    public GetCountries() {
        return this.httpClient.get<Country[]>(`${this.apiUrl}/country/`).pipe(
            retry(0),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public GetStates() {
        return this.httpClient.get<State[]>(`${this.apiUrl}/state/`).pipe(
            retry(0),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public GetDegreeTypes() {
        return this.httpClient.get<DegreeTypeDTO[]>(`${this.apiUrl}/GetDegreeTypes/`).pipe(
            retry(0),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public GetDegreeFields() {
        return this.httpClient.get<DegreeFieldDTO[]>(`${this.apiUrl}/GetDegreeFields/`).pipe(
            retry(0),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public GetCertAreas(candidateID: number) {
        return this.httpClient.get<any[]>(`${this.apiUrl}/GetCertAreasForEducation/${candidateID}`).pipe(
            retry(0),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public GetEducationWorkHistoryTests() {
        return this.httpClient.get<any[]>(`${this.apiUrl}/EmploymentHistoryTests/`).pipe(
            retry(0),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public DoesEducationExist(EducationId: number) {
        return this.httpClient.get<any>(`${this.apiUrl}/EducationExists/${EducationId}`).pipe(
            retry(0),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public AddDegree(education: Education) {
        return this.httpClient.post<Education>(`${this.apiUrl}/Education/`, education).pipe(
            retry(0),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public UpdateDegree(education: any) {
        return this.httpClient.put<any>(`${this.apiUrl}/EducationUpdate/`, education);
    }

    public DeleteDegree(id: number) {
        return this.httpClient.delete<any>(`${this.apiUrl}/EducationDelete/${id}`).pipe(
            retry(0),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public AddSubjectConcentration(subjectConcentration: SubjectConcentrationDTO){
        return this.httpClient.post<SubjectConcentrationDTO>(`${this.apiUrl}/SubjectConcentration`, subjectConcentration);
    }
    public UpdateSubjectConcentration(subjectConcentration: SubjectConcentrationDTO){
        return this.httpClient.put<SubjectConcentrationDTO>(`${this.apiUrl}/SubjectConcentration`, subjectConcentration);
    }
    

    AddEducationField(educationField: EducationFieldDTO) {
        return this.httpClient.post<EducationFieldDTO>(`${this.apiUrl}/EducationField`, educationField);
    }    
    UpdateEducationField(educationField: EducationFieldDTO) {
        return this.httpClient.put<EducationFieldDTO>(`${this.apiUrl}/EducationField`, educationField);
    }
    
}
