import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusPipe } from './status.pipe';
import { StatePipe } from './state.pipe';
import { PhoneNumberPipe } from './phone-number-pipe';

@NgModule({
  declarations: [
    StatusPipe,
    StatePipe,
    PhoneNumberPipe],
  imports: [
    CommonModule
  ],
  exports: [
    StatusPipe,
    StatePipe,
    PhoneNumberPipe
  ]
})
export class PipesModule { }
