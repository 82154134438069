import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OnInit, Inject, Component } from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  acceptButtonText: string;
  declineButtonText: string;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
            @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {    
    this.acceptButtonText = this.data.acceptButtonText ?  this.data.acceptButtonText  : 'Okay';
    this.declineButtonText = this.data.declineButtonText ? this.data.declineButtonText: 'Cancel';
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void{
    this.dialogRef.close(true);
  }
 
}
