import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'state'
})
export class StatePipe implements PipeTransform {

  transform(value: number, ...args: any[]): string {
    switch (value) {
      case 0: return 'State All';
      case 1: return 'AL';
      case 2: return 'AK';
      case 3: return 'AZ';
      case 4: return 'AR';
      case 5: return 'CA';
      case 6: return 'CO';
      case 7: return 'CT';
      case 8: return 'DE';
      case 9: return 'DC';
      case 10: return 'FL';
      case 11: return 'GA';
      case 12: return 'HI';
      case 13: return 'ID';
      case 14: return 'IL';
      case 15: return 'IN';
      case 16: return 'IA';
      case 17: return 'KS';
      case 18: return 'KY';
      case 19: return 'LA';
      case 20: return 'ME';
      case 21: return 'MD';
      case 22: return 'MA';
      case 23: return 'MI';
      case 24: return 'MN';
      case 25: return 'MS';
      case 26: return 'MO';
      case 27: return 'MT';
      case 28: return 'NE';
      case 29: return 'NV';
      case 30: return 'NH';
      case 31: return 'NJ';
      case 32: return 'NM';
      case 33: return 'NY';
      case 34: return 'NC';
      case 35: return 'ND';
      case 36: return 'OH';
      case 37: return 'OK';
      case 38: return 'OR';
      case 39: return 'PA';
      case 40: return 'RI';
      case 41: return 'SC';
      case 42: return 'SD';
      case 43: return 'TN';
      case 44: return 'TX';
      case 45: return 'UT';
      case 46: return 'VT';
      case 47: return 'VA';
      case 48: return 'WA';
      case 49: return 'WV';
      case 50: return 'WI';
      case 51: return 'WY';
      case 52: return 'AS';
      case 53: return 'GU';
      case 54: return 'MP';
      case 55: return 'PPR';
      case 56: return 'UM';
      case 57: return 'VI';
      case 58: return 'AA';
      case 59: return 'AP';
      case 60: return 'AE';
      default: return null;
    }
  }
}
