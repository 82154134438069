import { Observable } from 'rxjs';
import { MentorDetails } from './mentor-details.model';

export interface IContactService {
    getContact(campusContactId: number): Observable<Contact>;
    getContacts(districtID: number): Observable<Contact[]>;
    getContactTypes(): Observable<ContactType[]>;
    updateContact(contact: Contact): Observable<Contact>;
    deleteContact(contactID: number): Observable<boolean>;
    addContact(contact: Contact): Observable<Contact[]>;
}

export class Contact {
    id: number;
    entityID: number;
    firstName: string;
    lastName: string;
    title: string;
    phoneNumber: string;
    emailAddress: string;
    contactTypeID: number;
    beginDate: Date;
    endDate: Date;
    modUser: string;
    createUser: string;
    effectiveBegin?: Date;
    effectiveEnd?: Date;
    mentorDetails?: MentorDetails;
    hasAccessToDistrictPortal: boolean;
    districtPortalAccessDate: Date;
    accessToken: string;
}

export class ContactType {
    id: number;
    description: string;
}

export enum DistrictContactTypeValue {
    Payroll = 1,
    Superintendent = 2,
    HumanResources = 3,
    MentorManager = 4,
    ClinicalTeaching = 5
}
