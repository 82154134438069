import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './components/modal/modal.component';
import { PipesModule } from './pipes/pipes.module';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { CertAreaAssignmentComponent } from './components/cert-area-assignment/cert-area-assignment.component';
import { MaterialModule } from '../material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CertificateFormComponent } from './components/certificate-form/certificate-form.component';
import { FlexModule, FlexLayoutModule } from '@angular/flex-layout';
import { AlertComponent } from './components/alert/alert.component';
import { MentorObservationDocumentsComponent } from '../intern/intern-details/observations/mentor/mentor-observation-documents/mentor-observation-documents.component';
import { DialerComponent } from './components/dialer/dialer.component';
import { DistrictCampusDropdownsComponent } from './components/district-campus-dropdowns/district-campus-dropdowns.component';
import { MissingVerificationFormComponent } from './components/missing-verification-form/missing-verification-form.component';
import { NotesContactsComponent } from '../intern/intern-details/notes-contacts/notes-contacts.component';
import { AgGridModule } from 'ag-grid-angular';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DataTablesModule } from 'angular-datatables';
import { HttpClientModule } from '@angular/common/http';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { GeneralInfoComponent } from '../intern/intern-details/general-info/general-info.component';
import { CampusContactEditorComponent } from './components/campus-contact-editor/campus-contact-editor.component';
import { MentorDocumentComponent } from './components/mentor-document/mentor-document.component';
import { MentorAssignmentEditorComponent } from './components/mentor-assignment-editor/mentor-assignment-editor.component';
import { ReasonsDialogComponent } from './components/reasons-dialog/reasons-dialog.component';
import { RlOptionComponent } from './components/rl-option/rl-option.component';
import { RlDialogComponent } from './components/rl-dialog/rl-dialog.component';
import { CampusSupervisorAssignmentEditorComponent } from './components/campus-supervisor-assignment-editor/campus-supervisor-assignment-editor.component';

@NgModule({
    declarations: [
        ModalComponent,
        ConfirmDialogComponent,
        CertAreaAssignmentComponent,
        CertificateFormComponent,
        AlertComponent,
        MentorObservationDocumentsComponent,
        DialerComponent,
        DistrictCampusDropdownsComponent,
        MissingVerificationFormComponent,
        NotesContactsComponent,
        GeneralInfoComponent,
        CampusContactEditorComponent,
        MentorDocumentComponent,
        MentorAssignmentEditorComponent,
        CampusSupervisorAssignmentEditorComponent,
        ReasonsDialogComponent,
        RlOptionComponent,
        RlDialogComponent
    ],
    imports: [
        CommonModule,
        FlexModule,
        MaterialModule,
        PipesModule,
        ReactiveFormsModule,
        AgGridModule, //.withComponents([]),
        AngularEditorModule,
        DataTablesModule,
        FlexLayoutModule,
        FormsModule,
        HttpClientModule,
        MaterialModule,
        NgxExtendedPdfViewerModule
    ],
    exports: [
        PipesModule,
        ModalComponent,
        DialerComponent,
        CertAreaAssignmentComponent,
        CertificateFormComponent,
        MentorObservationDocumentsComponent,
        DistrictCampusDropdownsComponent,
        MissingVerificationFormComponent,
        GeneralInfoComponent,
        NotesContactsComponent,
        ConfirmDialogComponent,
        CampusContactEditorComponent,
        MentorDocumentComponent,
        MentorAssignmentEditorComponent,
        CampusSupervisorAssignmentEditorComponent,
        RlOptionComponent,
        RlDialogComponent
    ]
})
export class SharedModule { }
