import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DialerDomainData } from '../shared/models/dialer/dialerDomainData';
import { ExternalCallDestinationInfo } from '../shared/models/dialer/externalCallDestinationInfo';
import { CampaignsConfigInfo } from '../shared/models/dialer/CampaignsConfigInfo';

@Injectable({
  providedIn: 'root'
})
export class DialerService {
  base_auth_api_url = 'https://app.five9.com'
  base_agent_api_url = '/appsvcs/rs/svc';
  apiURL = `https://app.five9.com/appsvcs/rs/svc/auth/metadata`;

  constructor(private httpClient: HttpClient) {
  }

  public async getDomainData() {
    const dialerResponse = await this.httpClient.get(this.apiURL, { withCredentials: true }).toPromise();
    return dialerResponse as any;
  }

  public async callToIntern(dialerData: DialerDomainData, phoneNumber: string) {

    let base_api_url = `https://${dialerData.metadata.dataCenters[0].apiUrls[0].host}:${dialerData.metadata.dataCenters[0].apiUrls[0].port}`;
    let base_agents_api_url = `${base_api_url}${this.base_agent_api_url}`;

    let agentCampaignsInfo: CampaignsConfigInfo;

    //Creating payload to request call
    let calldestinationInfo: ExternalCallDestinationInfo = {
      number: phoneNumber,
      checkMultipleContacts: true,
      skipDNCCheck: true,
    };

    //Retrieve campaign data
    agentCampaignsInfo = await this.httpClient
      .get<CampaignsConfigInfo>(`${base_agents_api_url}/agents/${dialerData.userId}/campaigns_config`, { withCredentials: true })
      .toPromise();    

    //In case agent is assigned to a default campaign use this to include in the payload request
    if (agentCampaignsInfo && agentCampaignsInfo.defaultCampaignId) {
      calldestinationInfo.campaignId = agentCampaignsInfo.defaultCampaignId;
    }

    return await this.httpClient
      .post<ExternalCallDestinationInfo>(`${base_agents_api_url}/agents/${dialerData.userId}/interactions/make_external_call`,
        calldestinationInfo, { withCredentials: true }).toPromise();
  }

  public isAgentLoginActive(dialerData: DialerDomainData): Observable<any> {

    let base_api_url = `https://${dialerData.metadata.dataCenters[0].apiUrls[0].host}:${dialerData.metadata.dataCenters[0].apiUrls[0].port}`;
    let base_agents_api_url = `${base_api_url}${this.base_agent_api_url}`;

    return this.httpClient.get(`${base_agents_api_url}/agents/${dialerData.userId}/login_state`, { withCredentials: true });
  }
}
