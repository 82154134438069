import { MissingVerificationReasonDTO } from "./missing-verification-reason-dto.model";

export class EmploymentNoteDTO {
  Id: number;
  EmploymentId: number;
  Note: string;
}

export class EmploymentAssignmentDTO {
  Id: number;
  EmploymentId: number;
  GradeIdbegin: number;
  GradeIdend: number;
  JobTitle: string;
  JobDescription: string;
  EffectiveBegin?: Date;
  EffectiveEnd?: Date;
  ModUser?: any;
  ModDate?: any;
  CreateUser: string;
  CreateDate?: any;
  Hide: boolean;
}

export class EmploymentCommunicationLogDistrictEmailDTO
{
  EmploymentId: number;
  CommunicationLogId: number;
  DistrictEmail: string;
}

export class Employment {
  Id: number;
  ProfileId: number;
  EmploymentStatusId: number;
  CampusId: number;
  EmploymentVerified: boolean;
  EmploymentBeginDate: Date;
  EmploymentEndDate: Date;
  CompletionStatusId?: any;
  CompletionStatusDescription: string;
  ModUser: string;
  ModDate: Date;
  CreatedUser: string;
  CreatedDate: Date;
  Hide: boolean;
  ProductContractId?: any;
  SalesforceId?: any;
  CertAppDate?: any;
  InternTeachingId?: any;
  GradeIdbegin: number;
  GradeIdend?: any;
  InvoiceStartDate: Date;
  ExpectedEndDate: Date;
  EmploymentTypeId?: any;
  LastUser?: any;
  LastDate?: any;
  SubjectsTaught?: any;
  ExpectedStartDate?: any;
  ApprovalDate?: any;
  ApprovalUser?: any;
  LateHire?: any;
  SoeverificationUser?: any;
  SoeverificationDate?: any;
  CampusName: string;
  EmploymentNoteDTOs: EmploymentNoteDTO[];
  EmploymentAssignmentDTOs: EmploymentAssignmentDTO[];
  SoeBeginDate?: Date;
  SoeEndDate?: Date;
  isSOERequired: boolean;
  DistrictId: number;
  IsActive: boolean;
  DistrictName: string;
  DistrictEmail: string;
  IsBillable: boolean;
  SMK: boolean;
  SPED: boolean;
  DistrictStateLicenseId: string;
  MissingVerificationReasonNote: string;
  MissingVerificationReasonDTOs: MissingVerificationReasonDTO[];
  LateHireVerified: boolean;
}

export enum EmploymentStatusEnum {
  Pending = 3,
  Rejected = 4,
  Approved = 5
}

export class EmploymentProcessing {
  approvalStatusId: number | null;
  campusId: number | null;
  certTypeId: number | null;
  completionStatusId: number | null;
  createNewCertificate: boolean | null;
  districtId: number | null;
  employmentBeginDate: Date | null;
  employmentTypeId: number | null;
  gradeIdbegin: number | null;
  gradeIdend: number | null;
  licenseApplicationDate: Date | null;
  needsSPEDVerfification: boolean | null;
  needsSubjectAreaVerfification: boolean | null;
  profileId: number | null;
  soebeginDate: Date | null;
  subjectsTaught: string | null;
  createUser: string | null;
  createDate: Date | null;
  applicationDate: Date | null;
  employmentId: number | null;
  smk: boolean | null;
  sped: boolean | null;
  certAreaIds: number[] | null;
  jobTitle: string | null;
  jobDescription: string | null;
  certStartDate: Date | null;
  certEndDate: Date | null;
  approvalUser: string | null;
  missingVerificationReasons: number[] | null;
  missingVerificationNote: string;
  lateHireVerified: boolean;
}
