import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MentorAssignment } from '../../models/mentorAssignments.model';
import { Mentor } from '../../models/mentor.model';
import { CampusContactEditor } from '../../models/campus-contact-editor.model';
import { MentorContactsComponent } from 'src/app/intern/mentor-contacts/mentor-contacts.component';
import { MentorService } from 'src/app/services/mentor.service';
import { MatTableDataSource } from '@angular/material/table';
import { CampusWithDistrictId } from '../../models/campusWithDistrictId.model';
import { MatSelectionListChange } from '@angular/material/list';
import { CampusService } from 'src/app/services/campus.service';
import { ContactComponentType } from '../../enums/contact-component-type.enum';
import { CampusContactType } from '../../enums/campus-contact-type.enum';
import { Contact } from '../../models/contact.model';
import { OnlineSOE } from '../../models/online-soe.model';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MentorAssignmentEditorInputData } from '../../models/mentor-assignment-editor-input-data.model';
import { MentorAssignmentComponent } from 'src/app/intern/intern-details/observations/mentor/mentor-assignment.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Campus } from '../../models/campus.model';

@Component({
  selector: 'app-mentor-assignment-editor',
  templateUrl: './mentor-assignment-editor.component.html',
  styleUrls: ['./mentor-assignment-editor.component.scss'],
  animations: [
    trigger('expandAnimation', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('collapsed <=> expanded', 
      animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MentorAssignmentEditorComponent implements OnInit {
 
  mentorAssignmentEditorForm: UntypedFormGroup;
  mentorAssignments: MentorAssignment[] = [];
  mentorAssignmentEditorExpanded: boolean = false;
  mentorsListColumns: string[] = ['Campus', 'Name', 'Email', 'MentorFee', 'StartDate', 'EndDate', 'EndAssignment'];
  expandedMentorAssignmentRow: boolean = false;
  loadingMentorAssignments: boolean = false;
  mentorAssignmentSource: any;
  campusMentorList: Mentor[] = [];
  selectedMentor: Mentor;
  selectedCampus: Campus;
  campusMentorListLoaded = false;
  saving: boolean = false;
  campusContact = new CampusContactEditor();
  editingContact: boolean = false;
  editingAssignedMentor: boolean = false;
  mentorAssignmentExpandIndex: number = 0;
  mentorAssignmentEditorOpen: boolean = false;
  profileId: number;
  onlineSOE: OnlineSOE;  
  disableMentorAssignment: boolean = false;
  assignMentorButtonPosition: string;
  userRoleAllowedToModifyMentorAssignments: boolean = false;

  @Input() passedinData: MentorAssignmentEditorInputData;
  @ViewChild(MentorContactsComponent, { static: true }) mentorContactNoteComponent: MentorContactsComponent;
  @Output() mentorAssignmentsLoaded = new EventEmitter<MentorAssignment[]>();

  constructor(
    private mentorService: MentorService,
    private campusService: CampusService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.passedinData && changes.passedinData.currentValue){
      this.selectedCampus = this.passedinData.campus;
      this.profileId = this.passedinData.profileId;
      this.onlineSOE = this.passedinData.onlineSOE;
      this.disableMentorAssignment = !this.passedinData.hasEmployment
      this.assignMentorButtonPosition = this.passedinData.assignMentorButtonPosition;
      this.userRoleAllowedToModifyMentorAssignments = this.passedinData.userRoleAllowedToModifyMentorAssignments;

      this.initializeForm();
      if (this.selectedCampus){
        this.setupCampusData(this.selectedCampus);
      }
      if (this.profileId != null && this.mentorAssignments.length === 0) {
        this.getMentorAssignments(this.profileId);
      }
      if (this.mentorAssignmentEditorOpen && this.editingContact) {
        this.cancelContactEditor(0);
      }
    }
  }

  initializeForm() {

    this.mentorAssignmentEditorForm = new UntypedFormGroup({
      mentor: new UntypedFormControl('', Validators.required),
      assignmentStartDate: new UntypedFormControl(new Date(), Validators.required)
    });
  }

  getMentorAssignments(profileId: number) {
    this.loadingMentorAssignments = true;
    this.mentorService.getAssignedMentors(profileId).subscribe(mentors => {
      this.loadingMentorAssignments = false;
      this.mentorAssignments = mentors;

      this.mentorAssignments.sort((a: MentorAssignment, b: MentorAssignment) => {
        return +new Date(b.AssignedOn) - +new Date(a.AssignedOn);
      });

      for (let i = 0; i < this.mentorAssignments.length; i++) {
        var ma = this.mentorAssignments[i];
        ma.Index = i + 1; //Starting index at 1 so when adding a new mentor assignemnt, a new item with index 0 will be added to the top of the table and the editor will expand under it.
        ma.EndDate = new Date(ma.EndDate) > new Date(2199, 12, 31) ? null : ma.EndDate;
        ma.MentorFee = ma.DistrictMentorFee;
      }

      this.mentorAssignmentSource = new MatTableDataSource(this.mentorAssignments);

      if (this.expandedMentorAssignmentRow){
        this.cancelMentorAssignment();
        this.addNewMentorAssignment();
      }
      this.mentorAssignmentsLoaded.emit(this.mentorAssignments);
    });
  }

  addNewMentorAssignment() {
    // add placeholder mentor assignment
    if (!this.editingAssignedMentor && (this.mentorAssignments.length === 0 || this.mentorAssignments[0].Index != 0)) {
      this.mentorAssignments.unshift({
        MentorAssignmentId: 0,
        ProfileID: 0,
        FirstName: '',
        LastName: '',
        DistrictMentorFee: 0,
        MentorId: 0,
        MentorTypeId: 0,
        AssignedOn: null,
        EndDate: null,
        LastUpdatedOn: null,
        LastUpdatedBy: null,
        CreatedBy: '',
        CreatedOn: null,
        IsDeleted: false,
        Index: 0,
        CampusName: this.selectedCampus.Name,
        EmailAddress: '',
        MentorFee: 0
      });
    }
    this.mentorAssignmentSource = new MatTableDataSource(this.mentorAssignments);

    this.mentorAssignmentEditorForm.reset();
    this.expandedMentorAssignmentRow = true;
    this.mentorAssignmentEditorOpen = true;

    if (!!this.selectedCampus) {
      this.getCampusMentors(this.selectedCampus);
    }
  }

  setupCampusData(campus: Campus) {
      this.selectedMentor = new Mentor();
      this.campusMentorListLoaded = false;
      this.mentorAssignmentEditorForm.reset();
      if (!!campus && this.expandedMentorAssignmentRow) {
          this.getCampusMentors(campus);
      }
  }
  
  getCampusMentors(campus: Campus): any {
    if (!campus) {
      return;
    }
    this.mentorService.getMentorsForCampus(campus.Id).subscribe(mentors => {
      this.campusMentorListLoaded = true;
      this.campusMentorList = mentors;
    });
  }

  onMentorSelectionChanged(listChange: MatSelectionListChange) {
    let selectedIds: number[] = this.mentorAssignmentEditorForm.controls.mentor.value;
    let mentorId = selectedIds[0];

    this.selectedMentor = this.campusMentorList.find(mentor => mentor.MentorId == mentorId);

    // Populate the first empty row with selected mentor details; assign today's date to Start date
    this.mentorAssignments[0].CampusName = this.selectedCampus.Name;
    this.mentorAssignments[0].FirstName = this.selectedMentor.FirstName;
    this.mentorAssignments[0].LastName = this.selectedMentor.LastName;
    this.mentorAssignments[0].EmailAddress = this.selectedMentor.PrimaryEmail;

    this.mentorAssignmentEditorForm.get('assignmentStartDate').setValue(new Date());
    this.mentorAssignments[0].AssignedOn = new Date();
  }

  cancelMentorAssignment() {
    this.expandedMentorAssignmentRow = false;
    if(this.mentorAssignments[0].Index == 0) {
      this.mentorAssignments.splice(0, 1);
    }
    this.mentorAssignmentSource = new MatTableDataSource(this.mentorAssignments);
    this.selectedMentor = new Mentor();
    this.editingAssignedMentor = false;
    this.mentorAssignmentEditorOpen = false;
  }

  shouldShowRow(index: number): boolean {
    return index === this.mentorAssignmentExpandIndex;
  }

  editMentor(mentor: Mentor) {

    this.campusContact = new CampusContactEditor();
    this.campusContact.campusId = this.selectedCampus.Id;
    this.campusContact.campusContactId = mentor.MentorId,
    this.campusContact.campusContactName = mentor.DisplayName,
    this.campusContact.service = this.campusService;
    this.campusContact.contactComponentType = ContactComponentType.CampusContact;
    this.campusContact.campusContactType = CampusContactType.Mentor;
    this.campusContact.editMode = true;
    this.campusContact.disabledContactType = true;
    this.campusContact.formHeight = '339px';

    this.editingContact = true;
  }

  updateContact(updatedContact: Contact) {
    this.updateAssignedMentor(updatedContact); 
    this.updateCampusContact(updatedContact);
    
    if(this.expandedMentorAssignmentRow){
      if(this.mentorAssignments[0] != null && this.mentorAssignments[0].FirstName != ''){
        this.mentorAssignments[0].FirstName = updatedContact.firstName;
        this.mentorAssignments[0].LastName = updatedContact.lastName;
        this.mentorAssignments[0].EmailAddress = updatedContact.emailAddress;
      }
    }
  }

  updateCampusContact(updatedContact: Contact){
    if(this.expandedMentorAssignmentRow){
      const contactIndex = this.campusMentorList.findIndex(mentor => mentor.MentorId === updatedContact.id);
      var mentor = this.campusMentorList[contactIndex];
      mentor.DisplayName = updatedContact.firstName + ' ' + updatedContact.lastName;
      mentor.FirstName = updatedContact.firstName;
      mentor.LastName = updatedContact.lastName;
      mentor.PrimaryEmail = updatedContact.emailAddress;
      mentor.TEAId = updatedContact.mentorDetails.stateLicenseID;
      mentor.CertArea = updatedContact.mentorDetails.certificateArea;
      mentor.Title = updatedContact.title;
      mentor.MainPhone = updatedContact.phoneNumber;

      this.campusMentorList.splice(contactIndex, 1, mentor);
    }

    else {
      this.mentorAssignmentEditorExpanded = false
    }

    this.mentorAssignmentExpandIndex = 0;
    this.editingContact = false;
  }

  updateAssignedMentor(updatedContact: Contact) {
    if (!updatedContact) {
      return;
    }
      
    this.mentorAssignments.forEach( (mentorAssignment) => {
      if (mentorAssignment.MentorId === updatedContact.id) {
        mentorAssignment.FirstName = updatedContact.firstName;
        mentorAssignment.LastName = updatedContact.lastName;
        mentorAssignment.EmailAddress = updatedContact.emailAddress;
        var actualIndex = this.mentorAssignments.findIndex(ma => ma.Index === mentorAssignment.Index);
        this.mentorAssignments.splice(actualIndex, 1, mentorAssignment);
      }
    });
    
    if (!this.mentorAssignmentEditorOpen) {
      this.cancelMentorAssignment();
    }
    
    this.editingContact = false;
  }

  editAssignedMentor(mentorAssignment: MentorAssignment) {
    this.campusContact = new CampusContactEditor();
    this.campusContact.campusId = this.selectedCampus.Id;
    this.campusContact.campusContactId = mentorAssignment.MentorId,
    this.campusContact.campusContactName = mentorAssignment.FirstName + ' ' + mentorAssignment.LastName,
    this.campusContact.service = this.campusService;
    this.campusContact.contactComponentType = ContactComponentType.CampusContact;
    this.campusContact.campusContactType = CampusContactType.Mentor;
    this.campusContact.editMode = true;
    this.campusContact.disabledContactType = true;
    this.campusContact.formHeight = '339px';

    this.mentorAssignmentExpandIndex = mentorAssignment.Index;
    this.editingAssignedMentor = true;
    this.expandedMentorAssignmentRow = true;
    this.editingContact = true;
  }

  addNewMentor() {

    this.campusContact = new CampusContactEditor();
    this.campusContact.campusId = this.selectedCampus.Id;
    this.campusContact.campusContactId = 0;
    this.campusContact.campusContactName = '';
    this.campusContact.service = this.campusService;
    this.campusContact.contactComponentType = ContactComponentType.CampusContact;
    this.campusContact.campusContactType = CampusContactType.Mentor;
    this.campusContact.editMode = true;
    this.campusContact.disabledContactType = true;
    this.campusContact.formHeight = '339px';

    this.editingContact = true;
  }

  importESOEMentor() {

    this.campusContact = new CampusContactEditor();
    this.campusContact.campusId = this.selectedCampus.Id;
    this.campusContact.campusContactId = 0;
    this.campusContact.campusContactName = '';
    this.campusContact.service = this.campusService;
    this.campusContact.contactComponentType = ContactComponentType.CampusContact;
    this.campusContact.campusContactType = CampusContactType.Mentor;
    this.campusContact.editMode = true;
    this.campusContact.disabledContactType = true;
    this.campusContact.formHeight = '339px';

    this.campusContact.firstName = this.getMentorFirstName();
    this.campusContact.lastName = this.getMentorLastName();
    this.campusContact.email = this.onlineSOE.mentorEmail;
    this.campusContact.teaId = this.onlineSOE.mentorTeaId;

    this.editingContact = true;
  }

  getMentorFirstName(): string {
    var nameParts = []
    nameParts = this.onlineSOE.mentorName.split(' ');
    return nameParts[0];
  }

  getMentorLastName(): string {
    var nameParts = []
    nameParts = this.onlineSOE.mentorName.split(' ');
    return nameParts[1];
  } 
  
  cancelContactEditor(index: number){
    this.editingContact = false;
    if (this.editingAssignedMentor && !this.mentorAssignmentEditorOpen) {
      this.mentorAssignmentExpandIndex = index;
      this.cancelMentorAssignment();
    }
    this.editingAssignedMentor = false;
    this.mentorAssignmentExpandIndex = 0;
  }
  
  saveNewContact(newContact: Contact, index: number | null) {
    if (!newContact || !newContact.id) {
        this.mentorAssignmentEditorForm.controls.mentor.setValue([]);
        this.selectedMentor = new Mentor();
        this.editingContact = false;
        return;
      }
      const newMentor: Mentor =
      {
        DisplayName: newContact.firstName + ' ' + newContact.lastName,
        TypeId: newContact.contactTypeID,
        FirstName: newContact.firstName,
        LastName: newContact.lastName,
        MentorId: newContact.mentorDetails?.campusContactId,
        Title: newContact.title,
        State: '',
        PrimaryEmail: newContact.emailAddress,
        MainPhone: newContact.phoneNumber,
        DistrictMentorFee: 0,
        EffectiveBegin: null,
        EffectiveEnd: null,
        TEAId: newContact.mentorDetails?.stateLicenseID,
        CertArea: newContact.mentorDetails?.certificateArea
      };
      this.campusMentorList.splice(0, 0, newMentor);

      if(this.editingAssignedMentor) {
        this.mentorAssignmentExpandIndex = index;
        this.cancelMentorAssignment();
      }
      this.mentorAssignmentExpandIndex = 0;

      this.editingContact = false;
  }

  async saveMentorAssignment() {
    //Saving Mentor Assignment
    let mentorAssignmentData = new MentorAssignment();

    mentorAssignmentData.MentorId = (this.mentorAssignmentEditorForm.get('mentor').value)[0];
    mentorAssignmentData.ProfileID = this.profileId;
    mentorAssignmentData.LastUpdatedBy = this.authService.user.displayName;
    mentorAssignmentData.MentorTypeId = CampusContactType.Mentor;
    mentorAssignmentData.MentorAssignmentId = null;
    mentorAssignmentData.AssignedOn = this.mentorAssignmentEditorForm.get('assignmentStartDate').value;
    mentorAssignmentData.EndDate = null;
    mentorAssignmentData.CreatedBy = this.authService.user.displayName;

    //If there are no current mentor assignments, assign the new mentor
    if (this.mentorAssignments.length === 1 && this.mentorAssignments[0].MentorId === 0) {
      this.saving = true;
      this.saveNewMentorAssignment(mentorAssignmentData);
    }
    else {
      //If there is an existing mentor, end the assignment and assign the new mentor
      const canContinue = await this.openOverwriteMentorConfirmDialog();

      if (!canContinue) {
        this.openSnackBar('New mentor assignment was not saved.', null);
        return;
      }

      this.saving = true;

      //End the existing mentor assignment and add new assignment
      this.endExistingAssignmentAndSaveNewMentorAssignment(mentorAssignmentData, this.mentorAssignments);
    }
  }
  
  endExistingAssignmentAndSaveNewMentorAssignment(formData: MentorAssignment, currMentorAssignments: MentorAssignment[]) {   
    currMentorAssignments.shift();
    var needToEnd = currMentorAssignments.filter(it => it.EndDate == null);
    needToEnd.forEach((ma) => {
      ma.EndDate = formData.AssignedOn;
    });
    this.mentorService.changeAssignList(currMentorAssignments).toPromise().then((result) => {
      const itemDeleted = result;
      if (itemDeleted) {
        currMentorAssignments.forEach((ma) => {
          const index = this.mentorAssignments.findIndex(c => c.MentorAssignmentId === ma.MentorAssignmentId);
          this.mentorAssignments.splice(index, 1);
        });
        this.openSnackBar('The existing Mentor Assignments were ended.', null);
        this.saveNewMentorAssignment(formData);
      }
      else {
        this.openSnackBar('Error: The existing Mentor Assignments were not ended.', null);
      }
    })
      .catch(error => {
        console.error(error);
        this.openSnackBar('Error: The existing Mentor Assignments were not ended.', null);
      });
  }

  saveNewMentorAssignment(formData: MentorAssignment) {    
    this.mentorService.assign(formData).toPromise().then((result) => {
      if (result) {
        this.mentorAssignments.push(formData);
        //Retrieve the whole list after save since potentially other items are affected
        this.getMentorAssignments(this.profileId)

        //Close mentor assignment edit mode
        this.expandedMentorAssignmentRow = false;

        //Clear the selected mentor
        this.selectedMentor = new Mentor();

        //Display snackbar confirmation
        this.snackBar.open('Mentor assigned succesfully', 'Okay', { duration: 5000 });

      } else {
        this.openSnackBar('New mentor assignment was not saved.', null);
      }
      this.saving = false;
    })
      .catch(error => {
        this.openSnackBar('Error: New mentor assignment was not saved.', null);
      });
  }

  async openOverwriteMentorConfirmDialog(): Promise<boolean> {
    var retval = true;
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      width: '400px',
      maxWidth: '400px',
      data: {
        title: 'Confirmation',
        message: `Continuing will end existing mentor assignments on the new assignment's start date`
      }
    });

    retval = await dialogRef.afterClosed().toPromise().then(result => {
      return Promise.resolve(result);
    });

    return Promise.resolve(retval);
  }

  endMentorAssignment(formData: MentorAssignment) {
    const dialogRef = this.matDialog.open(MentorAssignmentComponent, {
      height: '350px',
      minHeight: '350px',
      width: '400px',
      minWidth: '400px',
      data: { entity: formData, profileId: this.profileId, service: this.mentorService, assignments: this.mentorAssignments }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.openSnackBar('Mentor assignment ended.', 'Okay');
        this.getMentorAssignments(this.profileId);
      }
    });
  }

  onStartDateSelected(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value;
    this.mentorAssignments[0].AssignedOn = selectedDate;
  }  

  private openSnackBar(data: string, status: string) {
    this.snackBar.open(data, status, {
      duration: 3 * 1000,
    });
  }
}
