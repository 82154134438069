import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ContractService } from 'src/app/services/contract.service';
import { CertProgram, CertProgramAreaDTO, CertProgramUpdateCommand, CertStatus } from '../../models/certProgram.model';
import { CertType } from '../../models/certType.model';
import { CertAreaDTO, CertArea } from '../../models/certArea.model';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-certificate-form',
  templateUrl: './certificate-form.component.html',
  styleUrls: ['./certificate-form.component.scss']
})
export class CertificateFormComponent implements OnInit {

  private _certificateId: number = null;
  private _extistingCertTypes: number[] = [];

  @Input()
  private certAreaInputControlPlaceholder: string;
  
  @Input()
  private profileStateId: number;

  @Input()
  set certificateId(id: number) {
    this._certificateId = id;
    if (this._certificateId != null) {
      this.getCeritificateInfo(this._certificateId);
    }
    this.getCertTypes();
  }

  @Input()
  set existingCertTypes(ids: number[])
  {
    this._extistingCertTypes = ids;
  }

  certificate: CertProgram;
  certificateForm: UntypedFormGroup;
  availableCertTypes: CertType[];
  availableCertAreas: CertAreaDTO[];
  certAreasFiltered: Observable<CertAreaDTO[]>;
  newSelectedCertArea: CertAreaDTO;
  disableAddCertArea: boolean = true;
  loadingCertificate: boolean = false;

  constructor(private authService: AuthService,
    private fb: UntypedFormBuilder,
    private contractSvc: ContractService) {
    this.certificate = new CertProgram();
    this.certificate.certProgramAreaDTOs = [];
  }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    this.certificateForm = this.fb.group({
      certType: ['', Validators.required],
      certAreaInputControl: [''],
      certApplicationDate: [null]
    });    
  }

  getCeritificateInfo(certificateId: number) {
    this.loadingCertificate = true;
    this.contractSvc.GetCertProgram(certificateId).subscribe(data => {
      this.certificate = data;     
      this.setFormValues(this.certificate);
      this.getAvailableCertAreas();
    }, () => { },
      () => {
        this.loadingCertificate = false;
      });
  }

  getCertTypes(){
    this.contractSvc.GetCertTypes().subscribe(data => {
      this.availableCertTypes = data;
    }); 
  }

  setFormValues(certProgram: CertProgram) {
    this.certificateForm.controls.certType.setValue(certProgram.certTypeId);
    this.certificateForm.controls.certApplicationDate.setValue(certProgram.applicationDate);
  }

  private getCertStatus(): number {
    let certStatusId = this.certificate.certStatusId;
    let applicationDate = this.certificateForm.controls.certApplicationDate.value;

    if (certStatusId == null ||
      certStatusId == CertStatus.NotApplied ||
      certStatusId == CertStatus.Applied) {

      if (applicationDate == null) {
        certStatusId = CertStatus.NotApplied;
      }
      else {
        certStatusId = CertStatus.Applied;
      }

    }

    return certStatusId;
  }

  saveCertificateInfo(): Observable<any> {

    //if there is already a certificate with this type, do not save
    if(this._extistingCertTypes.includes(this.certificateForm.controls.certType.value)){

      //display an error message
      this.certificateForm.controls.certType.setErrors({'nonUniqueType': true});
      
      let observer: Observable<any> = Observable.create(function(observer){
        observer.complete();
      });

      return observer;
    }

    this.certificate.certStatusId = this.getCertStatus();
    this.certificate.certTypeId = this.certificateForm.controls.certType.value;
    this.certificate.applicationDate = this.certificateForm.controls.certApplicationDate.value;

    // The following is how it's done on Cert add/update screen in contract tool. 
    // Some values are removed because they don't deserialzie on the server.
    // Todo: instead of passing CertProgram object, use a separate 'command object' that contains only the values required for update. 
    this.certificate.certType = null;
    this.certificate.certStatus = null;
    this.certificate.certProgramAreaDTOs.forEach(it => delete it.certStatus);

    var certProgramUpdateCommand = new CertProgramUpdateCommand();
    certProgramUpdateCommand.certProgram = this.certificate;
    certProgramUpdateCommand.certRecommendations = this.certificate.principalCertRecommendationDTOs.concat(this.certificate.fsCertRecommendationDTOs);
    return this.contractSvc.UpdateCertPrograms(certProgramUpdateCommand);
  }

  /*
     Begin logic for Smart search for cert area 
  */
  getAvailableCertAreas() {
    this.contractSvc.GetCertAreas(this.profileStateId).subscribe(data => {
      this.availableCertAreas = this.getUnselectedCertAreas(data);
      this.certAreasFiltered = this.certificateForm.controls.certAreaInputControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filteredCerArea(value))
      );    
    });    
  }

    private getUnselectedCertAreas(allCertAreas: CertArea[]) {
        //convert to dtos
        let allCertAreasDto: CertAreaDTO[] = [];
        allCertAreas.forEach(c => {
            let certDto = new CertAreaDTO();
            certDto.certAreaId = c.id;
            certDto.name = c.areaName;
            allCertAreasDto.push(certDto);
        })

        let assignedCertAreaIds: number[] = [];
        this.certificate.certProgramAreaDTOs.forEach(certArea => {
            assignedCertAreaIds.push(certArea.certAreaId);
        })

        return allCertAreasDto.filter(certArea => assignedCertAreaIds.includes(certArea.certAreaId) == false);
    }


  displayFnCertArea(certAreaObj: CertAreaDTO) {
    return certAreaObj ? certAreaObj.name : undefined;
  }

  _filteredCerArea(value: any): any {
    if (value === null || value === '' || typeof value !== 'string') {
      return this.availableCertAreas;
    } else {
      const filterValue = value.toString().toLowerCase();
      return this.availableCertAreas.filter(option =>
        option.name.toString().toLowerCase().includes(filterValue));
    }
  }

  certAreaSelected(certArea: CertAreaDTO) {
    this.disableAddCertArea = false;
    this.newSelectedCertArea = certArea;
  }

  addCertArea() {
    let newCertProgramDto: CertProgramAreaDTO = new CertProgramAreaDTO();
    newCertProgramDto.id = 0;
    newCertProgramDto.certAreaId = this.newSelectedCertArea.certAreaId;
    newCertProgramDto.areaName = this.newSelectedCertArea.name;
    if (this.authService != null && this.authService.user != null) {
      newCertProgramDto.createUser = this.authService.user.displayName;
    }   

    this.certificate.certProgramAreaDTOs.push(newCertProgramDto);

    let assignedCertAreaIds: number[] = [];
    this.certificate.certProgramAreaDTOs.forEach(certArea => {
      assignedCertAreaIds.push(certArea.certAreaId);
    })
    

    this.availableCertAreas = this.availableCertAreas.filter(availaleCert => assignedCertAreaIds.includes(availaleCert.certAreaId) == false);

    this.certificateForm.controls.certAreaInputControl.reset();

    this.disableAddCertArea = true;
  }

  removeCertArea(removedCertAreaDto: CertProgramAreaDTO) {

    let removedCertArea:CertAreaDTO = new CertAreaDTO();
    removedCertArea.certAreaId = removedCertAreaDto.certAreaId;
    removedCertArea.name = removedCertAreaDto.areaName;

    this.availableCertAreas.push(removedCertArea);
   
    let availableCertAreaIds: number[] = [];
    this.availableCertAreas.forEach(certArea => {
      availableCertAreaIds.push(certArea.certAreaId);
    })

    this.certificate.certProgramAreaDTOs = 
    this.certificate.certProgramAreaDTOs
    .filter(assignedCertProgramAreaDTOs => availableCertAreaIds.includes(assignedCertProgramAreaDTOs.certAreaId) == false);
  }
  
  /*
    End of logic for Smart search for cert area 
  */


}
