import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, retry, shareReplay } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';
import { AccountSource } from '../shared/models/accountsource.model';
import { Campus } from '../shared/models/campus.model';
import { CommunicationType } from '../shared/models/communicationType.model';
import { CompletionStatus } from '../shared/models/completionstatus.model';
import { Country } from '../shared/models/country.model';
import { CPEProvider } from '../shared/models/cpeprovider.model';
import { District } from '../shared/models/district.model';
import { Ethnicity } from '../shared/models/ethnicity.model';
import { FileLocation } from '../shared/models/filelocation.model';
import { Gender } from '../shared/models/gender.model';
import { PDStatus } from '../shared/models/pdstatus.model';
import { Race } from '../shared/models/race.model';
import { State } from '../shared/models/state.model';
import { Suffix } from '../shared/models/suffix.model';
import { CategoryDTO } from '../shared/models/category.model';
import { EmploymentVerificationReason } from '../shared/models/employment-verification-reason.model';

@Injectable({
  providedIn: 'root'
})
export class StaticTableService {

    headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    apiUrl = '';

    constructor(private httpClient: HttpClient, private configSvc: ConfigService) {
        this.apiUrl = this.configSvc.getApiUrl();
    }

    public getSuffixes() {

        return this.httpClient.get<Suffix[]>(`${this.apiUrl}/suffix`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);

                return EMPTY;
            }),
            shareReplay()
        );

    }

    public getGenders() {
        return this.httpClient.get<Gender[]>(`${this.apiUrl}/gender`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);

                return EMPTY;
            }),
            shareReplay()
        );
    }

    public getStates() {
        return this.httpClient.get<State[]>(`${this.apiUrl}/state`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);

                return EMPTY;
            }),
            shareReplay()
        );
    }

    public getCountries() {
        return this.httpClient.get<Country[]>(`${this.apiUrl}/country`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);

                return EMPTY;
            }),
            shareReplay()
        );
    }

    public getEthnicitys() {
        return this.httpClient.get<Ethnicity[]>(`${this.apiUrl}/ethnicity`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);

                return EMPTY;
            }),
            shareReplay()
        );
    }

    public getRaces() {
        return this.httpClient.get<Race[]>(`${this.apiUrl}/race`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);

                return EMPTY;
            }),
            shareReplay()
        );
    }

    public getAccountSources() {
        return this.httpClient.get<AccountSource[]>(`${this.apiUrl}/accountsource`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);

                return EMPTY;
            }),
            shareReplay()
        );
    }

    public getCPEProviders() {
        return this.httpClient.get<CPEProvider[]>(`${this.apiUrl}/cpeprovider`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);

                return EMPTY;
            }),
            shareReplay()
        );
    }

    public getPDStatuses() {
        return this.httpClient.get<PDStatus[]>(`${this.apiUrl}/pdstatus`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);

                return EMPTY;
            }),
            shareReplay()
        );
    }

    public getPhoneTypes() {
        return this.httpClient.get<CommunicationType[]>(`${this.apiUrl}/phonetype`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);

                return EMPTY;
            }),
            shareReplay()
        );
    }

    public getFileLocations() {
        return this.httpClient.get<FileLocation[]>(`${this.apiUrl}/filelocation`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);

                return EMPTY;
            }),
            shareReplay()
        );
    }

    public getCampuses(stateId:number) {
        return this.httpClient.get<Campus[]>(`${this.apiUrl}/campus/state/${stateId}`).pipe(            
            catchError(error => {
                console.log(error);

                return EMPTY;
            }),
            shareReplay()
        );
    }

    public getDistricts(stateId: number) {
        return this.httpClient.get<District[]>(`${this.apiUrl}/district/state/${stateId}`).pipe(            
            catchError(error => {
                console.log(error);

                return EMPTY;
            }),
            shareReplay()
        );
    }

    public getBillableDistricts(stateId: number) {
        return this.httpClient.get<District[]>(`${this.apiUrl}/district/state/${stateId}?billable=true`);
    }

    public getCompletionStatuses() {
        return this.httpClient.get<CompletionStatus[]>(`${this.apiUrl}/completionstatus`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);

                return EMPTY;
            }),
            shareReplay()
        );
    }

    public getCategories(){
        return this.httpClient.get<CategoryDTO[]>(`${this.apiUrl}/category`).pipe(
            retry(3),
            catchError(error => {
                console.log(error);

                return EMPTY;
            }),
            shareReplay()
        );
    }

    public getEmploymentVerificationReasons(){
        return this.httpClient.get<EmploymentVerificationReason[]>(`${this.apiUrl}/employment-verification-reasons`).pipe(
            catchError(error => {
                console.log(error);

                return EMPTY;
            }),
            shareReplay()
        );
    }
}