// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.certAreaItem {
  margin-top: 7px;
  width: 50%;
  display: flex;
}

.span-spacer {
  flex: 1 1 auto;
}

.half-width {
  width: 50%;
}

.full-width {
  width: 100%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNlcnRpZmljYXRlLWZvcm0uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxlQUFBO0VBQ0EsVUFBQTtFQUNBLGFBQUE7QUFDRjs7QUFHQTtFQUNFLGNBQUE7QUFBRjs7QUFJQTtFQUNFLFVBQUE7QUFERjs7QUFJQTtFQUNFLFdBQUE7QUFERiIsImZpbGUiOiJjZXJ0aWZpY2F0ZS1mb3JtLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNlcnRBcmVhSXRlbSB7XG4gIG1hcmdpbi10b3A6IDdweDtcbiAgd2lkdGg6IDUwJTtcbiAgZGlzcGxheTogZmxleFxufVxuXG5cbi5zcGFuLXNwYWNlciB7XG4gIGZsZXg6IDEgMSBhdXRvO1xufVxuXG5cbi5oYWxmLXdpZHRoIHtcbiAgd2lkdGg6IDUwJTtcbn1cblxuLmZ1bGwtd2lkdGgge1xuICB3aWR0aDogMTAwJTtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/components/certificate-form/certificate-form.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,UAAA;EACA,aAAA;AACF;;AAGA;EACE,cAAA;AAAF;;AAIA;EACE,UAAA;AADF;;AAIA;EACE,WAAA;AADF;AACA,4nBAA4nB","sourcesContent":[".certAreaItem {\n  margin-top: 7px;\n  width: 50%;\n  display: flex\n}\n\n\n.span-spacer {\n  flex: 1 1 auto;\n}\n\n\n.half-width {\n  width: 50%;\n}\n\n.full-width {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
