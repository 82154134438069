import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { StaticTableService } from 'src/app/services/statictable.service';
import { CandidateNote, CandidateNoteDTO } from 'src/app/shared/models/candidateNote';
import { CategoryDTO } from 'src/app/shared/models/category.model';
import { DisplayState } from 'src/app/shared/models/display-state';
import { InternNoteService } from '../../../services/intern-note.service';
import { Intern } from '../../../shared/models/intern.model';

@Component({
  selector: 'app-notes-contacts',
  templateUrl: './notes-contacts.component.html',
  styleUrls: ['./notes-contacts.component.scss']
})
export class NotesContactsComponent implements OnInit {

  dataIsLoaded: boolean = false;
  dataResultsCount: number = -1;
  _intern: Intern = new Intern();
  InternNotes: CandidateNoteDTO[] = [];
  showNoteForm: boolean = false;
  noteForm: UntypedFormGroup;
  addingNote: boolean = false;
  updatingNote: boolean = false;
  columnsToDisplay = ['Type', 'Note', 'CreatedBy', 'CreatedDate', 'UpdatedBy', 'UpdateDate', 'Priority'];
  noteTypes: CategoryDTO[] = [];
  newNoteFormSubmitted: boolean = false;
  defaultNoteType: number = 1; //email
  isMinimized: boolean = true;
  _profileId: number;

  @Input() parentState: DisplayState = DisplayState.Minimized;

  @Input() set profileId(value: number) {
    this._profileId = value;
    if (this._profileId && !this.dataIsLoaded) {
      this.getInternNotes(this._profileId);
    }
  }

  @Input() set defaultNoteCategory(categoryId: number) {
    if (categoryId) {
      this.defaultNoteType = categoryId;
    }
  }

  get profileId(): number {
    if (this._profileId) {
      return this._profileId;
    }
    else if (this._intern) {
      return this._intern.profileId;
    }

    return null;
  }

  @Input() set intern(value: Intern) {
    this._intern = value;
    if (this._intern && !this.dataIsLoaded) {
      this.getInternNotes(this._intern.profileId);
    }
  }

  constructor(public auth: AuthService,
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private internNoteService: InternNoteService,
    private staticSvc: StaticTableService,
    private route: ActivatedRoute,
    private router: Router) {

  }

  userID: number = -1;

  ngOnInit() {

    if (this.route.component === NotesContactsComponent) {

      this.authService.reload()
        .then(
          (user) => { if (!user) { this.router.navigate(['auth']); } }
        )
        .catch(error => console.error(error));
    }

    this.noteForm = this.fb.group({
      newNoteField: ['', [Validators.required]],
      newNotePriority: '',
      newNoteType: ['', [Validators.required]]
    });

    this.getNoteTypes();

    if (!!this._intern && this.InternNotes.length == 0){
      this.getInternNotes(this._intern.profileId);
    }
  }

  getInternNotes(profileId: number) {
    if (profileId != undefined && profileId != null && profileId > 0) {
      this.internNoteService.GetInternNotes(profileId).subscribe(data => {
        this.InternNotes = data;
        this.InternNotes.forEach(element => {
          element.modDateTimeDisplay = element.modDate != null ? element.modDate + 'Z' : ''; //To explress time in UTC (Coordinated Universal Time), use special UTC designator ("Z") at the end of date time string.
          element.createTimeDisplay = element.createDate != null ? element.createDate + 'Z' : '';
          element.isEdit = false;
        });
        this.dataIsLoaded = true;
        this.dataResultsCount = data.length;
      });
    }
  }


  resetNewNoteFields() {
    this.noteForm.controls.newNoteField.reset();
    this.noteForm.controls.newNotePriority.reset();
    this.newNoteFormSubmitted = false;
    this.showNoteForm = false;
    this.noteForm.controls.newNoteType.setValue(this.defaultNoteType, { onlySelf: true });
  }

  addNote() {
    if (this.noteForm.valid == false) {
      return;
    }

    let candidateNote: CandidateNote = {
      Note: this.noteForm.controls.newNoteField.value,
      Priority: this.noteForm.controls.newNotePriority.value,
      CategoryId: this.noteForm.controls.newNoteType.value,
      ProfileId: this.profileId,
      CreateUser: this.authService.user.displayName,
      ModUser: this.authService.user.displayName,
      AccountTypeId: 8 //refer to contact notes control
    }

    this.addingNote = true;
    this.internNoteService.CreateInternNote(candidateNote).subscribe(() => {
      this.resetNewNoteFields();
      this.getInternNotes(this.profileId);
    }, () => {
      //console.log('addNote error:', error)
    },
      () => {
        this.addingNote = false;
      });
  }

  getNoteTypes() {
    this.staticSvc.getCategories().subscribe((data) => {
      this.noteTypes = data;

      this.noteForm.controls.newNoteType.setValue(this.defaultNoteType, { onlySelf: true });

    },
      () => {
        //err
      },
      () => {
        //comp
      });
  }

  updateDescription(note: CandidateNoteDTO) {

    note.note += "\r\n" + note.noteUpdate;

    this.updatingNote = true;

    this.update(note).subscribe(() => {
      note.isEdit = false;
      note.noteUpdate = "";
    },
      () => { },
      () => {
        this.updatingNote = false;
      });
  }

  updatePriority(note: CandidateNoteDTO) {
    // internNote.priority = !internNote.priority; //todo: we need to do this here not on the view
    this.update(note).subscribe(() => {
      this.getInternNotes(this.profileId);
    });
  }

  update(note: CandidateNoteDTO) {
    note.modUser = this.authService.user.displayName;
    note.modDateTimeDisplay = new Date().toString();
    var formData = new FormData();
    formData.append("FileInformation", JSON.stringify(note));
    return this.internNoteService.SetInternNote(formData);
  }

  get newNoteFormIsInValid() {
    let newNoteField = this.noteForm.get('newNoteField');
    let requiredError = newNoteField.errors && newNoteField.errors.required;
    return requiredError && (newNoteField.touched || newNoteField.dirty || this.newNoteFormSubmitted);
  }

  addPanelOpen() {
    this.showNoteForm = true;
  }
}
