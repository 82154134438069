import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.authService.getAccessTokenForAPI())
      .pipe(
        switchMap(token => {

          let headers = request.headers;
          let requestClone = request.clone({ headers });

          if (request.url.includes('api')) {
            headers = request.headers.set('Authorization', 'Bearer ' + token);
            requestClone = request.clone({ headers });            
          }
          return next.handle(requestClone);
        })
      );
  }
}
